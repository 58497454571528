import { useState } from 'react';

const useImagePreloader = () => {
  const [loading, setLoading] = useState(true);

  const cacheImages = async (imageUrls) => {
    try {
      const promises = imageUrls.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });
      await Promise.all(promises);
    } catch (error) {
      console.error('Failed to preload images', error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, cacheImages };
};

export default useImagePreloader;
