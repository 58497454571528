const IconSlots = ({ isActive, color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_669_793)">
          <path
            d="M17.6562 8.28467H2.34375C1.05141 8.28467 0 9.33577 0 10.6277V17.6569C0 18.9489 1.05141 20 2.34375 20H17.6562C18.9486 20 20 18.9489 20 17.6569V10.6277C20 9.33573 18.9486 8.28467 17.6562 8.28467ZM18.4375 17.6569C18.4375 18.0876 18.087 18.438 17.6562 18.438H2.34375C1.91297 18.438 1.5625 18.0876 1.5625 17.6569V10.6277C1.5625 10.1971 1.91297 9.84671 2.34375 9.84671H17.6562C18.087 9.84671 18.4375 10.1971 18.4375 10.6277V17.6569Z"
            fill="#8C9099"
          />
          <path
            d="M2.96875 12.9707H4.22823L3.00886 16.6289L4.49073 17.1228L6.39545 11.4087H2.96875V12.9707Z"
            fill="#8C9099"
          />
          <path
            d="M8.4375 12.9707H9.69698L8.47761 16.6289L9.95948 17.1228L11.8642 11.4087H8.4375V12.9707Z"
            fill="#8C9099"
          />
          <path
            d="M13.9062 12.9707H15.1657L13.9464 16.6289L15.4282 17.1228L17.3329 11.4087H13.9062V12.9707Z"
            fill="#8C9099"
          />
          <path
            d="M13.6756 6.72261L16.0772 0L11.7168 2.17956L10 0.463303L8.28326 2.17956L3.92279 0L6.32443 6.72261H13.6756ZM8.59177 4.08022L10 2.67239L11.4083 4.08022L13.2978 3.13569L12.5744 5.16053H7.42556L6.7022 3.13569L8.59177 4.08022Z"
            fill="#8C9099"
          />
          <path d="M0 5.16064H3.75V6.72269H0V5.16064Z" fill="#8C9099" />
          <path d="M15.4688 5.16064H20V6.72269H15.4688V5.16064Z" fill="#8C9099" />
        </g>
        <defs>
          <clipPath id="clip0_669_793">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default IconSlots;
