const IconChecked = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 4.05705V9.94295C14 10.9655 13.6018 11.9268 12.8788 12.6498C12.7636 12.7311 11.8129 14 9.94295 14H4.05705C2.18734 14 1.23761 12.732 1.12123 12.6498C0.398207 11.9268 0 10.9655 0 9.94295V4.05705C0 3.0345 0.398207 2.07318 1.12123 1.35015C1.2364 1.26889 2.18712 0 4.05705 0H9.94295C11.8127 0 12.7624 1.26804 12.8788 1.35015C13.6018 2.07318 14 3.03453 14 4.05705ZM12.9062 4.05705C12.9062 3.32667 12.6218 2.64001 12.1054 2.12357C11.9856 2.03741 11.3204 1.09375 9.94295 1.09375H4.05705C2.68699 1.09375 2.01039 2.04031 1.89462 2.12357C1.37818 2.64001 1.09375 3.32667 1.09375 4.05705V9.94295C1.09375 10.6733 1.37818 11.36 1.89462 11.8764C2.01444 11.9626 2.67961 12.9062 4.05705 12.9062H9.94295C11.313 12.9062 11.9896 11.9597 12.1054 11.8764C12.6218 11.36 12.9062 10.6733 12.9062 9.94295V4.05705Z"
        fill="#939BB1"
      />
      <rect x="3" y="3" width="8" height="8" rx="3" fill="#F72C4D" />
    </svg>
  );
};

export default IconChecked;
