const IconSports = ({ isActive, color }) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.1429 15.7143C19.2857 14.0714 19.9286 12.1429 19.9286 10C19.9286 8.07143 19.3571 6.28571 18.4286 4.78571C18.4286 4.71429 18.3571 4.71429 18.3571 4.64286C16.5714 1.85714 13.5 0 9.92857 0C6.35714 0 3.21429 1.85714 1.5 4.71429C1.5 4.78571 1.42857 4.78571 1.42857 4.85714C0.5 6.35714 0 8.14286 0 10.0714C0 12.2143 0.642857 14.1429 1.78571 15.7857L1.85714 15.8571C3.57143 18.3571 6.57143 20 9.92857 20C13.2857 20 16.2143 18.3571 18.0714 15.8571C18.0714 15.7857 18.0714 15.7857 18.1429 15.7143ZM17.0714 14.6429L14.5 15L13.5 14L14.9286 9.85714L17.1429 8.78572L18.5714 9.64286C18.5714 9.78571 18.5714 9.92857 18.5714 10.1429C18.5 11.7143 18 13.3571 17.0714 14.6429ZM17 5.21429L16.4286 7.35714L14.1429 8.5L10.6429 6.07143V3.28571L13.0714 2.07143C14.7143 2.71429 16.0714 3.78571 17 5.21429ZM6.71429 2.07143L9.21429 3.28571V6.07143L5.71429 8.57143L3.35714 7.42857L2.78571 5.21429C3.78571 3.78571 5.14286 2.71429 6.71429 2.07143ZM1.35714 9.5L2.78571 8.64286L5 9.78571L6.42857 13.9286L5.42857 14.9286L2.78571 14.6429C1.85714 13.2857 1.35714 11.7143 1.35714 10C1.35714 9.85714 1.35714 9.64286 1.35714 9.5ZM7.71429 18.2857L6.5 15.8571L7.35714 15H12.5L13.3571 15.8571L12.1429 18.2857C11.4286 18.5 10.7143 18.5714 9.92857 18.5714C9.14286 18.5714 8.42857 18.4286 7.71429 18.2857Z"
          fill={color || '#8C9099'}
        />
      </svg>
    </>
  );
};

export default IconSports;
