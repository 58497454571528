import useAxios from 'axios-hooks';

import { API } from '../common/constants/api';

const { HOST, APIBASE, BANNER, POPUPBANNER } = API;

export function useGetBannerList(type) {
    const [{ data, loading, error }] = useAxios({
        url: `${HOST}${APIBASE}${BANNER.GET.replace(':type', type)}`, //1 = mobile 2 = desktop
    });
    
    return [{ data, loading, error }];
}

export function useGetPopupBanner(type = 1) {
    const [{ data, loading, error }] = useAxios({
        url: `${HOST}${APIBASE}${POPUPBANNER.GET.replace(':type', type)}`, //1 = mobile
    });

    return [{ data, loading, error }];
}