const IconClose3 = ({ color = '#939BB1', width = 30, height = 31 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1197_2835)">
        <path
          d="M15 31C23.2843 31 30 24.3301 30 16.1023C30 7.87452 23.2843 1.20459 15 1.20459C6.71573 1.20459 0 7.87452 0 16.1023C0 24.3301 6.71573 31 15 31Z"
          fill="#09132B"
        />
        <path
          d="M15.0002 28.8753C23.0286 28.8753 29.5369 22.4113 29.5369 14.4377C29.5369 6.46396 23.0286 0 15.0002 0C6.97171 0 0.463379 6.46396 0.463379 14.4377C0.463379 22.4113 6.97171 28.8753 15.0002 28.8753Z"
          fill="#C95600"
        />
        <path
          d="M15 28.2224C22.6654 28.2224 28.8794 22.0507 28.8794 14.4376C28.8794 6.82449 22.6654 0.652832 15 0.652832C7.33463 0.652832 1.12061 6.82449 1.12061 14.4376C1.12061 22.0507 7.33463 28.2224 15 28.2224Z"
          fill="#FFE300"
        />
        <path
          d="M14.9999 26.8498C21.902 26.8498 27.4973 21.2927 27.4973 14.4376C27.4973 7.58253 21.902 2.02539 14.9999 2.02539C8.09774 2.02539 2.50244 7.58253 2.50244 14.4376C2.50244 21.2927 8.09774 26.8498 14.9999 26.8498Z"
          fill="url(#paint0_linear_1197_2835)"
        />
        <path
          d="M20.8719 12.29L18.2809 9.71667L14.9999 12.9772L11.7189 9.71667L9.12793 12.29L12.4089 15.5505L9.12793 18.8092L11.7189 21.3825L14.9999 18.1238L18.2809 21.3825L20.8719 18.8092L17.5909 15.5505L20.8719 12.29Z"
          fill="#C95600"
        />
        <path
          d="M20.8719 11.1771L18.2809 8.60376L14.9999 11.8643L11.7189 8.60376L9.12793 11.1771L12.4089 14.4376L9.12793 17.6963L11.7189 20.2696L14.9999 17.0109L18.2809 20.2696L20.8719 17.6963L17.5909 14.4376L20.8719 11.1771Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1197_2835"
          x1="2.50244"
          y1="14.4376"
          x2="27.4973"
          y2="14.4376"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC800" />
          <stop offset="1" stopColor="#F79900" />
        </linearGradient>
        <clipPath id="clip0_1197_2835">
          <rect width="30" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconClose3;
