import useAxios from 'axios-hooks';

//common
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';

const { HOST, APIBASE, BONUS, CPF } = API;

export function useVerifyBonusCode() {
  const [{ data, loading, error }, verify] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.VERIFY_CODE}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, verify];
}

export function useGetMemberReferralBonusInfo() {
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${HOST}${APIBASE}${BONUS.REFERRAL_BONUS}`,
    method: METHODS.GET,
  });

  return [{ data, loading, error }, refetch];
}

export function useGetVIPData(isManual = false) {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.GET_VIP_DATA}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, refetch];
}

export function useGetClaimableBonus(isManual = false) {
  const [{ data, loading, error }, getBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.GET_CLAIMABLE_BONUS}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, getBonus];
}

export function useClaimBonus() {
  const [{ data, loading, error }, claimBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.CLAIM_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimBonus];
}

export function useUpdateLastEffect() {
  const [{ data, loading, error }, updateState] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.UPDATE_LAST_EFFECT}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, updateState];
}

export function useUpdateWeeklyBonus() {
  const [{ data, loading, error }, claimWBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.CLAIM_WEEK_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimWBonus];
}

export function useUpdateMonthlyBonus() {
  const [{ data, loading, error }, claimMBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.CLAIM_MONTH_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimMBonus];
}

export function useUpdateLevelBonus() {
  const [{ data, loading, error }, claimLBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.CLAIM_LEVEL_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimLBonus];
}

export function useGetDailyBonus() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.DAILY_BONUS}`,
      method: METHODS.GET,
    },
    { manual: false },
  );
  return [{ data, loading, error }, execute];
}

export function useUpdateDailyBonus() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.UPDATE_DAILY_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useGetRaffleData(isManual = false, isManualTrigger = false) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.GET_RAFFLE_DATA}?isManualTrigger=${isManualTrigger}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );
  return [{ data, loading, error }, execute];
}

export function useUpdateMemberRaffle(isManual = false) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.UPDATE_MEMBER_RAFFLE}`,
      method: METHODS.POST,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, execute];
}

export function useGetAvailableBonus(isManual = false) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.GET_AVAILABLE_BONUS}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, execute];
}

export function useVoucherCode() {
  const [{ data, loading, error }, redeem] = useAxios(
    {
      url: `${HOST}${APIBASE}${BONUS.REDEEM_VOUCHER}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, redeem];
}

//cpf banner
export function useCheckCPFBounded() {
  const [{ data, loading, error }, checkBounded] = useAxios(
    {
      url: `${HOST}${APIBASE}${CPF.ELIGIBILITY}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, checkBounded];
}

export function useCheckCPFDepositCount() {
  const [{ data, loading, error }, checkDepositCount] = useAxios(
    {
      url: `${HOST}${APIBASE}${CPF.CHECK_DEPOSIT_COUNT}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, checkDepositCount];
}

export function useCheckCPFBonusValidity() {
  const [{ data, loading, error }, checkClaimable] = useAxios(
    {
      url: `${HOST}${APIBASE}${CPF.CHECK_CLAIMABLE}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, checkClaimable];
}

export function useClaimCPFBonus() {
  const [{ data, loading, error }, claimBonus] = useAxios(
    {
      url: `${HOST}${APIBASE}${CPF.CLAIM_BONUS}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, claimBonus];
}
