const IconRakeBack = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8.82278C22 9.55234 21.4072 10.1442 20.6765 10.1442C20.4878 10.1442 20.3095 10.1029 20.1471 10.0323L17.4579 16.1826H4.54209L1.85299 10.0323C1.69057 10.1029 1.51185 10.1442 1.32314 10.1442C0.592758 10.1442 0 9.55226 0 8.82278C0 8.09329 0.592758 7.50224 1.32306 7.50224C2.05336 7.50224 2.64604 8.09361 2.64604 8.82278C2.64604 9.03738 2.59014 9.23696 2.49913 9.41642L8.21697 12.2936L10.5386 5.75416C10.0363 5.5666 9.67694 5.08741 9.67694 4.52049C9.67694 3.79093 10.2694 3.19995 11 3.19995C11.7306 3.19995 12.3231 3.79093 12.3231 4.52049C12.3231 5.08748 11.9637 5.5666 11.4614 5.75416L13.783 12.2936L19.5003 9.41642C19.4099 9.23696 19.3539 9.03738 19.3539 8.82278C19.3539 8.09361 19.9462 7.50224 20.6765 7.50224C21.4068 7.50224 21.9999 8.09361 21.9999 8.82278H22ZM4.54209 16.5602L3.3798 18.6H18.6203L17.4579 16.5602H4.54209Z"
        fill="#8C9099"
      />
    </svg>
  );
};

export default IconRakeBack;
