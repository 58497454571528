const IconMoney = ({ color }) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="21" stroke="#8B8F98" strokeWidth="2" />
      <path
        d="M32.8053 31.5845H32.7392C32.7458 31.2206 32.7524 30.8357 32.7524 30.3949C32.7524 19.8144 27.4635 16.3995 23.9398 15H20.1649C16.8858 16.3995 11.5969 19.1146 11.5969 30.3949C11.5969 30.8357 11.5969 31.2276 11.6101 31.5775L11.544 31.5915C10.8168 31.7104 10.2747 32.3752 10.2747 33.1589C10.2747 33.9427 10.863 34.6564 11.6299 34.7334L14.5322 34.9014C16.456 35.5731 19.1666 36 22.1747 36C25.1827 36 27.8933 35.5801 29.8171 34.9014L32.7194 34.7334C33.4863 34.6564 34.0747 33.9707 34.0747 33.1589C34.0747 32.3472 33.5325 31.7104 32.8053 31.5915V31.5845ZM21.6987 23.5861L23.4175 24.5238C24.3497 25.0347 24.8522 26.0773 24.6803 27.1899C24.5414 28.1066 23.9729 28.8204 23.1928 29.1213V29.5272C23.1928 30.01 22.8225 30.4019 22.3664 30.4019C21.9102 30.4019 21.54 30.01 21.54 29.5272V29.1773C20.707 28.9394 19.9996 28.3166 19.6426 27.4419C19.4575 27.001 19.6492 26.4832 20.0723 26.2872C20.4888 26.0983 20.978 26.3012 21.1565 26.7421C21.3681 27.2669 21.8705 27.5818 22.406 27.5258H22.4854C22.9217 27.4419 23.0209 27.071 23.0473 26.91C23.087 26.6301 23.0077 26.2662 22.6573 26.0773L20.9384 25.1396C19.993 24.6218 19.4905 23.5302 19.6889 22.4245C19.874 21.3889 20.588 20.6401 21.5334 20.4442V20.0733C21.5334 19.5905 21.9036 19.1986 22.3598 19.1986C22.8159 19.1986 23.1862 19.5905 23.1862 20.0733V20.6331C23.8605 20.934 24.4092 21.4868 24.7133 22.2216C24.8984 22.6624 24.7067 23.1803 24.2836 23.3692C23.8671 23.5651 23.3779 23.3552 23.1994 22.9144C22.9878 22.3965 22.4854 22.0746 21.9499 22.1306C21.454 22.1866 21.3417 22.6135 21.3152 22.7394C21.2888 22.8724 21.2491 23.3342 21.6987 23.5791V23.5861Z"
        fill={color || '#8C9099'}
      />
      <path
        d="M25.062 8.14276C24.5281 7.77894 23.6199 8.13614 22.8529 8.92331C22.4786 9.30697 21.9079 9.30697 21.5397 8.92331C20.7726 8.12953 19.8705 7.79217 19.3305 8.14276C17.5448 9.30697 19.9503 13.3685 20.1712 13.6H24.2213C24.4422 13.3685 26.7496 9.30697 25.062 8.14276Z"
        fill={color || '#8C9099'}
      />
    </svg>
  );
};

export default IconMoney;
