const IconPIX = ({ isActive, color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1358 13.8145C13.7811 13.8154 13.4298 13.7481 13.1022 13.6166C12.7745 13.4851 12.4769 13.2919 12.2267 13.0483L9.47017 10.3761C9.37269 10.2858 9.24302 10.2354 9.10814 10.2354C8.97327 10.2354 8.8436 10.2858 8.74612 10.3761L5.97968 13.0568C5.72946 13.3004 5.43191 13.4935 5.10423 13.6251C4.77656 13.7566 4.42524 13.8238 4.07059 13.823H3.5293L7.02153 17.2083C7.54535 17.7153 8.25531 18.0001 8.99552 18.0001C9.73573 18.0001 10.4457 17.7153 10.9695 17.2083L14.4705 13.8145H14.1358Z"
        fill="#32BCAD"
      />
      <path
        d="M4.0735 4.17536C4.42817 4.17437 4.77952 4.24154 5.10722 4.37299C5.43492 4.50444 5.73245 4.69755 5.98259 4.94115L8.74903 7.61974C8.84515 7.71257 8.97533 7.76471 9.11106 7.76471C9.24678 7.76471 9.37697 7.71257 9.47308 7.61974L12.2296 4.94905C12.4798 4.70545 12.7773 4.51233 13.105 4.38088C13.4327 4.24944 13.784 4.18226 14.1387 4.18326H14.4705L10.9695 0.791325C10.4457 0.284606 9.73573 0 8.99552 0C8.25531 0 7.54535 0.284606 7.02153 0.791325L3.5293 4.1748L4.0735 4.17536Z"
        fill="#32BCAD"
      />
      <path
        d="M17.1969 7.04465L15.1247 4.94923C15.0781 4.96886 15.0282 4.97923 14.9777 4.97978H14.0359C13.5445 4.98027 13.0733 5.17711 12.7248 5.52742L10.0255 8.25751C9.78238 8.50304 9.4528 8.64095 9.10917 8.64095C8.76554 8.64095 8.43595 8.50304 8.19279 8.25751L5.48329 5.51762C5.13556 5.16786 4.66546 4.97087 4.175 4.96941H3.02063C2.97295 4.96861 2.92582 4.95904 2.88153 4.94116L0.800239 7.04465C0.28782 7.56346 0 8.26673 0 8.99999C0 9.73324 0.28782 10.4365 0.800239 10.9553L2.88039 13.0588C2.92463 13.0407 2.97177 13.0309 3.01949 13.03H4.17785C4.66824 13.0287 5.13835 12.832 5.48614 12.4824L8.19564 9.74246C8.68533 9.24786 9.53871 9.24729 10.0284 9.74246L12.7276 12.4726C13.0759 12.8233 13.5473 13.0204 14.0388 13.0208H14.9805C15.0312 13.0212 15.0813 13.0314 15.1282 13.0507L17.1998 10.9559C17.7122 10.4371 18 9.73382 18 9.00056C18 8.26731 17.7122 7.56403 17.1998 7.04522L17.1969 7.04465Z"
        fill="#32BCAD"
      />
    </svg>
  );
};

export default IconPIX;
