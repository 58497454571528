const IconVoucher = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.16659 7.66671C7.58325 7.83337 7.99992 8.16671 8.33325 8.50004V3.33337H4.16659C2.33325 3.33337 0.833252 4.83337 0.833252 6.66671V14.1667C0.833252 16 2.33325 17.5 4.16659 17.5H8.33325V13.3334L6.33325 14.8334C6.16659 14.9167 5.99992 15 5.83325 15C5.58325 15 5.33325 14.9167 5.16659 14.6667C4.91659 14.3334 4.99992 13.75 5.33325 13.5L6.74992 12.4167C5.74992 12.3334 4.83325 11.6667 4.33325 10.75C3.91659 9.91671 4.08325 8.91671 4.74992 8.16671C5.41659 7.50004 6.33325 7.33337 7.16659 7.66671ZM15.8333 3.33337H9.99992V8.41671C10.3333 8.08337 10.7499 7.75004 11.1666 7.58337C11.9999 7.25004 12.9166 7.41671 13.5833 8.08337C14.2499 8.75004 14.3333 9.83337 13.9999 10.6667C13.5833 11.5834 12.6666 12.25 11.5833 12.3334L12.9999 13.4167C13.3333 13.6667 13.4166 14.25 13.1666 14.5834C12.9999 14.8334 12.7499 14.9167 12.4999 14.9167C12.3333 14.9167 12.1666 14.8334 11.9999 14.75L9.99992 13.3334V17.5H15.8333C17.6666 17.5 19.1666 16 19.1666 14.1667V6.66671C19.1666 4.83337 17.6666 3.33337 15.8333 3.33337Z"
        fill="#8C9099"
      />
      <path
        d="M12.4167 10.0834C12.2501 10.5834 11.5834 10.8334 10.6667 10.8334H10.4167C10.6667 10.1667 11.1667 9.58338 11.7501 9.25005C11.9167 9.16672 12.1667 9.16672 12.4167 9.33338C12.5001 9.50005 12.5834 9.83338 12.4167 10.0834Z"
        fill="#414952"
      />
      <path
        d="M7.91655 10.8333H7.66655C6.74989 10.8333 6.16655 10.5833 5.91655 10.0833C5.83322 9.83329 5.83322 9.49996 5.99989 9.33329C6.08322 9.24996 6.16655 9.16663 6.33322 9.16663C6.41655 9.16663 6.49989 9.16663 6.66655 9.24996C7.24989 9.58329 7.66655 10.1666 7.91655 10.8333Z"
        fill="#414952"
      />
    </svg>
  );
};

export default IconVoucher;
