const bra = {
  app: {
    title: 'brasa.game',
    submit_btn: 'Submeter',
    close_btn: 'Fechar',
    cancel_btn: 'Cancelar',
    back_btn: 'Voltar',
    real_play_btn: 'Jogar a Sério',
    fun_play_btn: 'Jogar por Diversão',
    go_back_homepage_btn: 'Voltar à Página Inicial',
    home_btn: 'Página Inicial',
    contactus_btn: 'Contacte-nos',
  },
  login: {
    signin: 'Entrar',
    email: 'Email',
    phone: 'Telefone',
    has_account: 'É novo na Brasa?',
    create_account: 'Criar uma conta',
    social_media: 'Entrar com',
    username: 'Nome de usuário',
    password: 'Senha',
    forgot_ps: 'Esqueceu a senha?',
    confirm_ps: 'Confirmar senha',
    successfully: 'Login realizado com sucesso',
    invalid_username_email: 'Por favor, insira no mínimo 6 caracteres',
    invalid_username: 'Por favor, insira entre 6 e 20 caracteres alfanuméricos',
    invalid_password: 'Senha inválida',
    password_not_match: 'A senha e a confirmação de senha não correspondem',
    username_not_able_changed: 'O nome de usuário não poderá ser alterado após ser enviado!',
    token_not_null: 'O token de acesso não pode ser nulo.',
    step_title1: 'Um último passo para configurar sua conta',
    step_title2: 'Antes de começar, por favor insira seu nome de usuário e senha.',
    signin_facebook: 'ENTRAR COM O FACEBOOK',
    verify_acc: 'Verificar Conta',
    email_verified: 'Seu email foi verificado!',
    invalid_verify_token: 'Token de verificação inválido!',
    contact_customer_service:
      'Por favor, entre em contato com o serviço de atendimento ao cliente.',
  },
  forgotPassword: {
    forgotPassword: 'Esqueceu a Senha',
  },

  resetPassword: {
    title: 'Redefinir Senha',
    token_not_found: 'Token de redefinição de senha não encontrado',
  },
  topbar: {
    member_center: 'Centro de Membros',
    transactions: 'Transações',
    deposit: 'Depósito',
    withdraw: 'Retirar',
    vip: 'VIP & Promoção',
    affiliate: 'Afiliado',
    logout: 'Sair',
    signIn: 'Entrar',
    signUp: 'Registrar',
    vipProgress: 'Progresso VIP',
    welcomeBonusTile: 'Slots 300% Welcome Pack até R$1000!!',
    affiliateInviteTile: 'Convide seus amigos para se divertir e ganhe R$ 15 cada pessoa!!',
    inviteBtn: 'CONVIDAR',
    joinNowBtn: 'REGISTRAR',
    voucher: 'Código de Resgate',
    vault: 'Cofre',
  },
  home: {
    bet_directly: 'Apostar Diretamente',
    more_btn: 'mais',
    title_popular: 'Popular',
    title_slots: 'Slots',
    title_casino: 'Cassino',
    table_games: 'Jogos',
    table_payout: 'Valor do Pagamento',
    all_bets: 'Todas as apostas',
  },
  sidebar: {
    bonus: 'Promoção',
    vip: 'VIP',
    live_support: 'Suporte ao Vivo',
    Sports: 'Esportes',
    Slots: 'Slots',
    'Live Casino': 'Cassino ao Vivo',
    Rummy: 'Rummy',
    'Teen Patti': 'Teen Patti',
    'Brazil Poker': 'Poker Brasileiro',
    All: 'Todos',
    Recent: 'Recente',
    Favourite: 'Favorito',
    'New Releases': 'Novos Lançamentos',
    Popular: 'Popular',
    'Feature Buy In': 'Compra de Recurso',
    Blackjack: 'Blackjack',
    Baccarat: 'Baccarat',
    Roulette: 'Roleta',
    download_app: 'BAIXAR APP',
    Providers: 'Provedores',
    LiveEvents: 'Eventos ao Vivo',
    Cricket: 'Críquete',
    Football: 'Futebol',
    Hockey: 'Hóquei',
    Badminton: 'Badminton',
    Tennis: 'Tênis',
    Wrestling: 'Luta Livre',
    Basketball: 'Basquetebol',
    Baseball: 'Beisebol',
    Boxing: 'Boxe',
    Dota2: 'Dota2',
    CSGO: 'CSGO',
    Promotion: 'Promoção',
    VIP: 'VIP',
    Affiliate: 'Afiliado',
    Original: 'Original',
    Poker: 'Poker',
  },
  footer: {
    home: 'Início',
    support: 'Suporte',
    deposits: 'Depósitos',
    menu: 'Menu',
    slots: 'Slots',
    casino: 'Cassino',
    sports: 'Esportes',
    bonus: 'Promoção',
    aviator: 'Aviador',
    about_brasa: 'Sobre a Brasa',
    about_brasa_content:
      'A Brasa é o cassino online perfeito para jogadores brasileiros que desejam jogar com dinheiro real. Oferecemos uma ampla variedade de jogos de cassino online com dinheiro real que podem ser jogados com altas ou baixas quantias de aposta, facilitando a escolha do jogo perfeito para o seu orçamento.\n\nAlém disso, oferecemos saques rápidos e depósitos fáceis usando métodos de pagamento confiáveis do Brasil, como transferências bancárias UPI e PhonePe.\n\nAo criar uma conta na Brasa, você obtém uma visão clara de seus fundos, enquanto nossas promoções regulares ajudam a impulsionar sua banca.\n\nPortanto, se você está procurando um cassino online seguro e confiável para jogar com dinheiro real, a Brasa é a escolha perfeita.',
    join_community: 'MAIS BENEFÍCIOS NO CANAL OFICIAL',
    app: 'Aplicativo',
    sportsLobby: 'Lobby de Esportes',
    slotsLobby: 'Lobby de Slots',
    casinoLobby: 'Lobby de Cassino',
    originalLobby: 'Lobby Original',
    pokerLobby: 'Lobby de Poker',
    appDownload: 'Download de Aplicativo',
    entertainment: 'Entretenimento',
    readLess: 'Leia menos',
    readMore: 'Leia mais',
    gameUnderMaintenance: 'Jogo em manutenção',
    disclaimer_content:
      'Este site oferece jogos com experiência de risco. Para ser usuário do nosso site você deve ter mais de 18 anos. Não somos responsáveis ​​pela violação das leis locais relacionadas ao i-gaming. Jogue com responsabilidade e divirta-se na Brasa.',
  },
  register: {
    signUp: 'Registar',
    name: 'Nome',
    username: 'Nome de usuário',
    password: 'Senha',
    confirm_ps: 'Confirmar senha',
    email: 'Email',
    mobile_number: 'Número de celular',
    invite_code: 'Código de referência',
    captcha: 'Captcha',
    term1: 'Eu concordo com o <link>Contrato do Usuário</link> ',
    term2: ', <link>Política de Privacidade</link> ',
    term3: ', <link>Termos de Serviço</link> e confirmo que tenho pelo menos 18 anos',
    marketingTerm: 'Concordo em receber promoções de marketing da Brasa',
    btn: 'Registar',
    has_account: 'Já tem uma conta?',
    invalid_mobile: 'Número de celular inválido',
    invalid_password: 'Senha inválida',
    send_otp: 'Enviar OTP',
    phone_number: 'Número de telefone',
    reg_username_charc_valid: 'O nome de usuário deve conter letras e números',
    reg_username_valid_warning:
      'O nome de usuário deve ter de 6 a 20 caracteres com letras e números',
    reg_username_valid: 'O nome de usuário deve ter entre 6 e 20 caracteres',
    reg_username_special_charc: 'O nome de usuário não pode conter caracteres especiais',
    ln_valid: 'Por favor, insira 6-20 caracteres alfanuméricos',
    email_valid: 'Por favor, insira um email válido',
    ps_valid: 'Por favor, insira no mínimo 6 caracteres',
    confirm_ps_invalid: 'A senha de confirmação deve ser igual à senha',
    verify_otp: 'Por favor, verifique o seu número de telefone por OTP',
    accept_terms: 'Por favor, aceite os termos',
    otp_title1: 'OTP',
    otp_title2: 'Código de Verificação',
    otp_placeholder: 'Digite o OTP de 4 dígitos enviado para o seu número de celular',
    otp_label1: 'Não é o seu número de celular?',
    otp_label2: 'Não recebeu o OTP?',
    otp_label3: 'Por favor, aguarde',
    otp_label4: 's para reenviar.',
    resend_otp: 'Reenviar OTP',
    verify_btn: 'VERIFICAR',
    mail_confirmation: 'Confirmação de Email',
    confirmation_text1:
      'Enviamos um email para {email} para verificar seu endereço de email e ativar sua conta.',
    confirmation_text2: 'O link no email expirará em 24 horas.',
    missing_email1: 'Ops! Parece que você acessou esta página sem iniciar o processo de registro.',
    missing_email2:
      'Se você está tentando se inscrever em uma conta, use o botão de registro fornecido para receber um email de verificação.',
    missing_email3:
      'Se precisar de ajuda ou tiver alguma dúvida, sinta-se à vontade para entrar em contato com nossa equipe de suporte.',
    create_account: 'Criar Conta',
    user_agreement_title1: 'Contrato do Usuário',
    user_agreement_title2: 'Termos de Serviço',
    user_agreement_content:
      'Este acordo de usuário final (o "Acordo") deve ser lido por você (o "Usuário" ou "você") na íntegra antes do uso do serviço ou produtos da BRASA. Observe que o Acordo constitui um acordo legalmente vinculativo entre você e a BRASA (doravante referida como "BRASA", "nós" ou "nós"), que é proprietária e opera o site da Internet e os jogos descritos na BRASA (o "Serviço"). Ao clicar no botão "Concordo" se e onde fornecido e/ou usar o Serviço, você consente com os termos e condições estabelecidos neste Acordo.',
    user_agreement_content1: '1. Concessão de Licença',
    user_agreement_content1_1:
      ' 1.1. Sujeito aos termos e condições aqui contidos, a BRASA concede ao Usuário um direito não exclusivo, pessoal, não transferível de usar o Serviço em seu computador pessoal ou outro dispositivo que acesse a Internet para acessar os jogos disponíveis',
    user_agreement_content1_2:
      ' 1.2. O Serviço não é para uso por (i) indivíduos com menos de 18 anos, (ii) indivíduos com menos da idade legal de maioridade em sua jurisdição e (iii) indivíduos acessando o Serviço de jurisdições nas quais é ilegal fazê-lo. A BRASA não é capaz de verificar a legalidade do Serviço em cada jurisdição, sendo responsabilidade do Usuário garantir que o uso do Serviço seja legal.',
    user_agreement_content1_3:
      '1.3. A BRASA e seus licenciantes são os únicos detentores de todos os direitos sobre o Serviço e o código, estrutura e organização, incluindo direitos autorais, segredos comerciais, propriedade intelectual e outros direitos. Você não pode, nos limites prescritos pelas leis aplicáveis: (a) copiar, distribuir, publicar, fazer engenharia reversa, descompilar, desmontar, modificar ou traduzir o site; ou (b) usar o Serviço de maneira proibida por leis ou regulamentos aplicáveis (cada um dos itens acima é um "Uso Não Autorizado"). A BRASA reserva todos e quaisquer direitos implícitos ou não expressamente concedidos ao Usuário nos termos deste e retém todos os direitos, título e interesse no Serviço. Você concorda que será o único responsável por qualquer dano, custo ou despesa decorrente ou relacionada à prática por você de qualquer Uso Não Autorizado. Você deve notificar imediatamente a BRASA ao tomar conhecimento da prática por qualquer pessoa de qualquer Uso Não Autorizado e fornecer à BRASA assistência razoável em qualquer investigação que ela conduza com base nas informações fornecidas por você a esse respeito.',
    user_agreement_content1_4:
      '1.4. O termo "BRASA", seus nomes de domínio e quaisquer outras marcas comerciais ou marcas de serviço usadas pela BRASA como parte do Serviço (as "Marcas Comerciais"), são de propriedade exclusiva da BRASA. Além disso, todo o conteúdo do site, incluindo, mas não se limitando a, imagens, fotos, gráficos, fotografias, animações, vídeos, música, áudio e texto (o "Conteúdo do Site") pertence à BRASA e é protegido por direitos autorais e/ou outros direitos de propriedade intelectual ou outros direitos. Você reconhece que, ao usar o Serviço, não obtém direitos sobre o Conteúdo do Site e/ou as Marcas Comerciais, ou qualquer parte deles. Sob nenhuma circunstância você pode usar o Conteúdo do Site e/ou as Marcas Comerciais sem o consentimento prévio por escrito da BRASA. Além disso, você concorda em não fazer nada que prejudique ou possa prejudicar os direitos, incluindo os direitos de propriedade intelectual, da BRASA',
    user_agreement_content2: '2. Sem Garantias',
    user_agreement_content2_1:
      '2.1. A BRASA renuncia a todas e quaisquer garantias, expressas ou implícitas, em relação ao serviço que é fornecido a você "como está" e não fornecemos garantia ou representação alguma quanto à sua qualidade, adequação a um determinado fim, completude ou precisão.',
    user_agreement_content2_2:
      '2.2. Independentemente dos esforços da BRASA, a BRASA não garante que o serviço será ininterrupto, pontual ou livre de erros, ou que defeitos serão corrigidos.',
    user_agreement_content3:
      '3. Autoridade/Termos de Serviço Você concorda com as regras do jogo descritas no site da BRASA. A BRASA mantém autoridade sobre a emissão, manutenção e encerramento do Serviço. A decisão da administração da BRASA, referente a qualquer uso do Serviço ou resolução de disputas, é final e não será passível de revisão ou recurso.',
    user_agreement_content4: '4. Suas Obrigações como Jogador',
    user_agreement_content4_1: '4.1. Você declara e garante o seguinte:',
    user_agreement_content4_1_1:
      '4.1.1. Você tem mais de 18 anos de idade ou a maioridade legal mínima mais alta estipulada pela jurisdição de sua residência (por exemplo, Estônia - 21 anos) e, de acordo com as leis aplicáveis a você, é legalmente permitido participar dos Jogos oferecidos no site.',
    user_agreement_content4_1_2:
      '4.1.2. Você participa dos Jogos estritamente em sua capacidade pessoal não profissional, apenas por motivos recreativos e de entretenimento.',
    user_agreement_content4_1_3:
      '4.1.3. Você participa dos Jogos em seu próprio nome e não em nome de qualquer outra pessoa.',
    user_agreement_content4_1_4:
      '4.1.4. Toda a informação que fornecer à BRASA durante o prazo de validade deste acordo é verdadeira, completa e correta, e você deverá notificar imediatamente a BRASA de qualquer alteração dessa informação.',
    user_agreement_content4_1_5:
      '4.1.5. Você é o único responsável por relatar e contabilizar quaisquer impostos aplicáveis a você, nos termos das leis relevantes, para quaisquer ganhos que receber da BRASA.',
    user_agreement_content4_1_6:
      '4.1.6. Você compreende que ao participar dos Jogos você assume o risco de perder Fundos Virtuais depositados em sua Conta de Membro.',
    user_agreement_content4_1_7:
      '4.1.7. Você não deve se envolver em atividades fraudulentas, colusivas, de manipulação ou outras atividades ilegais em relação à sua participação ou à de terceiros em qualquer um dos Jogos, nem usar quaisquer métodos ou técnicas assistidos por software ou dispositivos de hardware para sua participação em qualquer dos Jogos. A BRASA reserva-se o direito de invalidar qualquer aposta em caso de tal comportamento.',
    user_agreement_content4_1_8:
      '4.1.8. Você compreende que os Fundos Virtuais, como Bitcoin, não são considerados uma moeda ou oferta legal e, como tal, no Website, são tratados como fundos virtuais sem valor intrínseco.',
    user_agreement_content4_1_9:
      '4.1.9. Você compreende que o valor do Bitcoin pode mudar drasticamente dependendo do valor de mercado.',
    user_agreement_content4_1_10:
      '4.1.10. Você não tem permissão para usar quaisquer métodos de pagamento que pertençam a terceiros.',
    user_agreement_content4_2:
      '4.2. Você não tem permissão para transferir, vender e/ou adquirir contas de usuário.',
    user_agreement_content4_3:
      '4.3. Os jogos jogados em nosso site devem ser jogados da mesma maneira que os jogos jogados em qualquer outro ambiente. Isso significa que os jogadores devem ser corteses uns com os outros e evitar comentários rudes ou obscenos.',
    user_agreement_content4_4:
      '4.4. Algumas circunstâncias podem surgir em que uma aposta seja confirmada, ou um pagamento seja realizado por nós por engano. Em todos esses casos, a BRASA reserva-se o direito de cancelar todas as apostas aceitas que contenham tal erro.',
    user_agreement_content4_5:
      '4.5. Caso o usuário perceba possíveis erros ou incompletudes no software, concorda em abster-se de tirar proveito deles. Além disso, o usuário concorda em relatar imediatamente qualquer erro ou incompletude à BRASA. Caso o usuário deixe de cumprir as obrigações estabelecidas nesta cláusula, a BRASA tem o direito de receber compensação integral por todos os custos relacionados ao erro ou incompletude, incluindo quaisquer custos incorridos em associação com o respectivo erro/incompletude e a falha de notificação pelo usuário.',
    user_agreement_content4_6:
      '4.6. No caso de um jogo ser iniciado mas interrompido devido a uma falha do sistema, a BRASA reembolsará o valor apostado no jogo ao Usuário creditando-o na Conta do Usuário ou, se a conta não existir mais, pagando-o ao Usuário de maneira aprovada; e se o Usuário tiver um crédito acumulado no momento em que o jogo for interrompido, creditar na Conta do Usuário o valor monetário do crédito ou, se a conta não existir mais, pagá-lo ao Usuário de maneira aprovada.',
    user_agreement_content4_7:
      '4.7. A BRASA reserva-se o direito de rejeitar ou limitar apostas. O usuário não está autorizado a apostar um valor superior ao de sua conta pessoal. Os ganhos são creditados na conta pessoal do usuário.',
    user_agreement_content4_8:
      '4.8. A BRASA reserva-se o direito de reter pagamentos, se houver suspeita ou evidência de manipulação do sistema do cassino. Ações criminais serão movidas contra qualquer usuário ou pessoa(s) que tenha(m) manipulado o sistema do cassino ou tentado fazê-lo. A BRASA reserva-se o direito de encerrar e/ou alterar quaisquer jogos ou eventos oferecidos no Website.',
    user_agreement_content4_9:
      '4.9. Reservamo-nos o direito de exigir alguma verificação em caso de transações suspeitas ou fraudulentas.',
    user_agreement_content4_10:
      '4.10. A BRASA reserva-se o direito de declarar uma aposta nula parcial ou totalmente se, a critério próprio da BRASA, ficar óbvio que ocorreram quaisquer das seguintes circunstâncias:',
    user_agreement_content4_10_1:
      '4.10.1. You, or people associated with you may directly or indirectly influence the outcome of an event, to obtain an unlawful advantage,',
    user_agreement_content4_10_2:
      '4.10.2. Você e/ou pessoas associadas a você estão diretamente ou indiretamente evitando as regras da BRASA',
    user_agreement_content4_10_3:
      '4.10.3. O resultado de um evento foi afetado direta ou indiretamente por atividade criminosa.',
    user_agreement_content4_10_4:
      '4.10.4. Apostas foram feitas que não teriam sido aceitas de outra forma, mas que foram aceitas durante períodos em que o site foi afetado por problemas técnicos.',
    user_agreement_content4_10_5:
      '4.10.5. Devido a um erro, como erro de impressão, erro técnico, força maior ou outro, apostas foram oferecidas, feitas e/ou aceitas devido a este erro.',
    user_agreement_content4_10_6:
      "4.10.6. Se a taxa de depósito de um jogador for muito baixa e for sinalizada pela blockchain ou site similar como 'não há taxa suficiente para retransmitir', a BRASA reserva-se o direito de confiscar os ganhos se a BRASA, a seu critério, considerar a transação e o comportamento do jogador fraudulentos.",
    user_agreement_content4_11:
      '4.11. Você informará imediatamente à BRASA se entrar em um acordo de autoexclusão com qualquer provedor de jogos de azar.',
    user_agreement_content5: '5. Usos Proibidos',
    user_agreement_content5_1:
      '5.1. USO PESSOAL. O Serviço destina-se exclusivamente ao uso pessoal do Usuário. O Usuário só está autorizado a apostar para seu entretenimento pessoal e não pode criar várias contas, incluindo com o propósito de colusão e/ou abuso do serviço.',
    user_agreement_content5_2:
      "5.2. JURISDIÇÕES. Pessoas localizadas ou residentes em Aruba, Bonaire, Curaçao, Costa Rica, França, Países Baixos, Saba, Statia, St Martin, EUA (as 'Jurisdições Proibidas') não estão autorizadas a fazer uso do Serviço. Para evitar dúvidas, as restrições acima sobre participação em jogos com dinheiro real de Jurisdições Proibidas se aplicam igualmente a residentes e cidadãos de outras nações enquanto estiverem localizados em uma Jurisdição Proibida. Qualquer tentativa de contornar as restrições ao jogo por parte de pessoas localizadas em uma Jurisdição Proibida ou Jurisdição Restrita é uma violação deste Contrato. Uma tentativa de contorno inclui, mas não se limita a, manipular as informações usadas pela BRASA para identificar sua localização e fornecer à BRASA informações falsas ou enganosas sobre sua localização ou residência.",
    user_agreement_content6: "6. Conheça o Seu Cliente ('KYC')",
    user_agreement_content6_1:
      'A BRASA reserva o direito, a qualquer momento, de solicitar qualquer documentação KYC que considere necessária para determinar a identidade e a localização de um Usuário. A BRASA reserva o direito de restringir o serviço e o pagamento até que a identidade seja suficientemente determinada.',
    user_agreement_content7: '7. Violação',
    user_agreement_content7_1:
      '7.1. Sem prejuízo de quaisquer outros direitos, se um Usuário violar total ou parcialmente qualquer disposição contida neste documento, a BRASA se reserva o direito de tomar as medidas que considerar adequadas, incluindo a rescisão deste Contrato ou de qualquer outro acordo em vigor com o Usuário e/ou tomar medidas legais contra tal Usuário.',
    user_agreement_content7_2:
      '7.2. Você concorda em indenizar integralmente, defender e isentar a BRASA e seus acionistas, diretores, agentes e funcionários de todas as reivindicações, demandas, responsabilidades, danos, perdas, custos e despesas, incluindo honorários advocatícios e quaisquer outras taxas, de qualquer natureza, que possam surgir como resultado de: (i) sua violação deste Contrato, total ou parcialmente; (ii) violação por você de qualquer lei ou direitos de terceiros; e (iii) uso por você do Serviço.',
    user_agreement_content8: '8. Limitações e Responsabilidade',
    user_agreement_content8_1:
      '8.1. Em nenhuma circunstância, incluindo negligência, a BRASA será responsável por quaisquer danos especiais, incidentais, diretos, indiretos ou consequenciais, decorrentes do uso (ou mau uso) do Serviço, mesmo que a BRASA tivesse conhecimento prévio da possibilidade de tais danos.',
    user_agreement_content8_2:
      '8.2. Nada neste Contrato exclui ou limita a responsabilidade da BRASA por morte ou lesão pessoal decorrente de sua negligência.',
    user_agreement_content9: '9. Disputas',
    user_agreement_content9_1:
      'Se um Usuário desejar fazer uma reclamação, entre em contato com a equipe de atendimento ao cliente da BRASA em support@BRASA. Caso alguma disputa não seja resolvida para sua satisfação, você pode buscar remédios na jurisdição da lei aplicável estabelecida abaixo.',
    user_agreement_content10: '10. Alteração',
    user_agreement_content10_1:
      'A BRASA reserva o direito de atualizar ou modificar este Contrato ou qualquer parte dele a qualquer momento ou alterar o Serviço sem aviso prévio, e você estará vinculado a tal Contrato alterado mediante postagem. Portanto, incentivamos você a verificar os termos e condições contidos na versão do Contrato em vigor naquele momento. Seu uso contínuo do Serviço será considerado como sua concordância com quaisquer alterações ao Contrato.',
    user_agreement_content11: '11. Lei Aplicável',
    user_agreement_content11_1:
      'O Contrato e quaisquer assuntos relacionados serão regidos e interpretados de acordo com as leis da Costa Rica. Você concorda irrevogavelmente que, sujeito ao estabelecido abaixo, os tribunais da Costa Rica terão jurisdição exclusiva em relação a qualquer reivindicação, disputa ou diferença concernente ao Contrato e qualquer assunto decorrente dele e renuncia irrevogavelmente a qualquer direito que possa ter para se opor a uma ação sendo movida nesses tribunais, ou para afirmar que a ação foi movida em um fórum inconveniente, ou que esses tribunais não têm jurisdição. Nada nesta cláusula limitará o direito da BRASA de tomar medidas legais contra você em qualquer outro tribunal de jurisdição competente, nem a tomada de medidas legais em uma ou mais jurisdições impedirá a tomada de medidas legais em outras jurisdições, seja simultaneamente ou não, na medida permitida pela lei de tal outra jurisdição.',
    user_agreement_content11_2:
      'Se uma disposição deste Contrato for ou se tornar ilegal, inválida ou inexequível em qualquer jurisdição, isso não afetará a validade ou exequibilidade dessa disposição nesta jurisdição, nem a validade ou exequibilidade em outra jurisdição dessa ou de qualquer outra disposição deste Contrato.',
    user_agreement_content13: '13. Atribuição',
    user_agreement_content13_1:
      'A BRASA reserva o direito de atribuir este contrato, total ou parcialmente, a qualquer momento e sem aviso prévio. O Usuário não pode atribuir nenhum de seus direitos ou obrigações sob este Contrato.',
    user_agreement_content14: '14. JOGO DE VANTAGEM',
    user_agreement_content14_1:
      'Se o Casino tomar conhecimento de algum usuário que tenha aceitado o bônus ou uma promoção com o único propósito de criar um valor esperado positivo no retorno do bônus, usando práticas conhecidas com o objetivo de garantir um saque desse bônus ou de qualquer forma tentar aproveitar os bônus recebidos pela BRASA, então a BRASA aplicará imediatamente a confiscação dos ganhos e o fechamento da conta com o direito de reter quaisquer outros saques posteriores. Um exemplo de jogo de vantagem seria atrasar qualquer rodada de jogo em qualquer jogo, incluindo recursos de giros grátis e recursos de bônus, para um momento posterior quando você não tiver mais requisito de aposta e/ou realizar novos depósitos enquanto ainda tiver recursos de giros grátis ou recursos de bônus disponíveis em um jogo. No interesse do jogo justo, apostas iguais, de margem zero ou baixa ou apostas de hedge, todas serão consideradas jogos irregulares para fins de requisitos de jogo de bônus. Caso o Casino considere que ocorreu jogo irregular, o Casino reserva-se o direito de reter quaisquer saques e/ou confiscar todos os ganhos.',
    user_agreement_def_1: 'Definições;',
    user_agreement_def_2: "A BRASA é referida como 'nós' ou 'nosso'.",
    user_agreement_def_3: "O Jogador é referido como 'você' ou 'o Jogador'",
    user_agreement_def_4:
      "'O Website' significa a BRASA através de desktop, mobile ou outras plataformas utilizadas pelo Jogador",
    user_agreement_def_5: 'https://BRASA/help/terms-service',
    user_agreement_gen_1: '1. Geral',
    user_agreement_gen_1_1:
      '1.1. Este Contrato do Usuário se aplica ao uso de jogos acessíveis através da BRASA.',
    user_agreement_gen_1_2:
      '1.2. Este Contrato do Usuário entra em vigor assim que você completar o processo de registro, que inclui marcar a caixa aceitando este Contrato do Usuário e criar com sucesso uma conta. Ao usar qualquer parte do Website após a criação da conta, você concorda com este Contrato do Usuário.',
    user_agreement_gen_1_3:
      '1.3. Você deve ler este Contrato do Usuário cuidadosamente em sua totalidade antes de criar uma conta. Se você não concordar com qualquer disposição deste Contrato do Usuário, você não deve criar uma conta ou continuar a usar o Website.',
    user_agreement_gen_1_4:
      '1.4. Temos o direito de fazer emendas a este Contrato do Usuário a qualquer momento e sem aviso prévio. Se fizermos tais emendas, podemos tomar medidas apropriadas para chamar sua atenção para tais mudanças (como por e-mail ou colocando um aviso em uma posição proeminente no Website, junto com o Contrato do Usuário alterado) mas será sua única responsabilidade verificar quaisquer emendas, atualizações e/ou modificações. Seu uso contínuo dos serviços da BRASA e do Website após qualquer emenda a este Contrato do Usuário será considerado como sua aceitação e acordo em ser vinculado por tais emendas, atualizações e/ou modificações.',
    user_agreement_gen_1_5:
      '1.5. Este Contrato do Usuário pode ser publicado em vários idiomas para fins informativos e facilidade de acesso pelos jogadores. A versão em inglês é a única base legal da relação entre você e nós e no caso de qualquer discrepância em relação a uma tradução de qualquer tipo, a versão em inglês deste Contrato do Usuário prevalecerá.',
    user_agreement_gen_2: '2. Declarações Vinculativas',
    user_agreement_gen_2_1:
      '2.1. Ao concordar em ser vinculado por este Contrato do Usuário, você também concorda em ser vinculado pelas Regras da BRASA e pela Política de Privacidade que estão incorporadas por referência neste Contrato do Usuário. Em caso de qualquer inconsistência, este Contrato do Usuário prevalecerá. Você declara e garante que:',
    user_agreement_gen_2_1_1:
      '2.1.1. Você tem mais de (a) 18 ou (b) tal outra idade legal ou idade da maioria conforme determinado por quaisquer leis que sejam aplicáveis a você, qual idade for maior;',
    user_agreement_gen_2_1_2:
      '2.1.2. Você tem plena capacidade para celebrar um acordo legalmente vinculativo conosco e você não está restrito por qualquer forma de capacidade legal limitada;',
    user_agreement_gen_2_1_3:
      '2.1.3. Todas as informações que você fornecer a nós durante o período de validade deste acordo são verdadeiras, completas, corretas e que você deverá nos notificar imediatamente de qualquer alteração de tais informações;',
    user_agreement_gen_2_1_4:
      '2.1.4. Você é o único responsável por relatar e contabilizar quaisquer impostos aplicáveis a você de acordo com as leis relevantes para quaisquer ganhos que você receba de nós;',
    user_agreement_gen_2_1_5:
      '2.1.5. Você entende que ao usar nossos serviços você corre o risco de perder dinheiro depositado em sua Conta de Membro e aceita que é totalmente e exclusivamente responsável por qualquer perda desse tipo;',
    user_agreement_gen_2_1_6:
      '2.1.6. Você é permitido na jurisdição em que você está localizado para usar serviços de cassino online;',
    user_agreement_gen_2_1_7:
      '2.1.7. Em relação a depósitos e saques de fundos em e da sua Conta de Membro, você deverá usar apenas Criptomoedas que sejam válidas e legalmente pertençam a você;',
    user_agreement_gen_2_1_8:
      '2.1.8. Você entende que o valor das Criptomoedas pode mudar dramaticamente dependendo do valor de mercado;',
    user_agreement_gen_2_1_9:
      '2.1.9. O software de computador, os gráficos de computador, os Websites e a interface do usuário que disponibilizamos para você são de propriedade da BRASA ou de seus associados e são protegidos pelas leis de direitos autorais. Você só pode usar o software para seus próprios usos pessoais e recreativos, de acordo com todas as regras, Contrato do Usuário que estabelecemos e de acordo com todas as leis, regras e regulamentos aplicáveis;',
    user_agreement_gen_2_1_10:
      '2.1.10. Você entende que as Criptomoedas não são consideradas uma moeda ou dinheiro legal e, como tal, no Website, elas são tratadas como fundos virtuais sem valor intrínseco.',
    user_agreement_gen_2_1_11:
      '2.1.11. Você afirma que não é um diretor, funcionário, consultor ou agente da BRASA ou trabalhando para qualquer empresa relacionada à BRASA, ou um parente ou cônjuge de qualquer dos anteriores;',
    user_agreement_gen_2_1_12:
      '2.1.12. Você não foi diagnosticado ou classificado como um jogador compulsivo ou problemático. Não somos responsáveis se surgir tal jogo problemático enquanto usa nossos serviços, mas nos esforçaremos para informar sobre a assistência relevante disponível. Reservamo-nos o direito de implementar períodos de pausa se acreditarmos que tais ações serão benéficas.',
    user_agreement_gen_2_1_13:
      '2.1.13. Você aceita e reconhece que reservamos o direito de detectar e prevenir o uso de técnicas proibidas, incluindo, mas não se limitando a, detecção de transações fraudulentas, registro e inscrição automatizados, técnicas de jogo e captura de tela. Estas etapas podem incluir, mas não estão limitadas a, exame das propriedades do dispositivo do Jogador, detecção de geo-localização e mascaramento de IP, transações e análise de blockchain;',
    user_agreement_gen_2_1_14:
      '2.1.14. Você aceita nosso direito de encerrar e/ou alterar quaisquer jogos ou eventos oferecidos no Website, e de recusar e/ou limitar apostas.',
    user_agreement_gen_2_1_15:
      '2.1.15. Você aceita que temos o direito de proibir/bloquear várias contas e controlar livremente os ativos nessas contas.',
    user_agreement_gen_2_1_16:
      '2.1.16. Você está ciente de possíveis erros ou incompletudes no software, concorda em se abster de tirar proveito deles. Além disso, você concorda em relatar imediatamente qualquer erro ou incompletude à BRASA. Se você deixar de cumprir as obrigações declaradas nesta cláusula, a BRASA tem o direito de compensação total por todos os custos relacionados ao erro ou incompletude, incluindo quaisquer custos incorridos em associação com o erro/incompletude respectivo e a falha na notificação pelo usuário.',
    user_agreement_gen_2_1_17:
      '2.1.17. Você está ciente de que a BRASA tem o direito de realizar procedimentos de verificação de “Conheça seu Cliente” (KYC). O acesso à sua conta de usuário pode ser bloqueado ou encerrado se determinarmos que você forneceu informações falsas ou enganosas.',
    user_agreement_gen_2_2:
      '2.2. Reservamos o direito de declarar uma aposta inválida parcial ou totalmente se a BRASA, a seu critério, considerar óbvio que algumas das seguintes circunstâncias ocorreram:',
    user_agreement_gen_2_2_1:
      '2.2.1. Você, ou pessoas associadas a você, podem influenciar direta ou indiretamente o resultado de um evento, para obter uma vantagem ilegal.',
    user_agreement_gen_2_2_2:
      '2.2.2. Você e/ou pessoas associadas a você estão evitando diretamente ou indiretamente as regras da BRASA.',
    user_agreement_gen_2_2_3:
      '2.2.3. O resultado de um evento foi diretamente ou indiretamente afetado por atividade criminosa.',
    user_agreement_gen_2_2_4:
      '2.2.4. Foram feitas apostas que não teriam sido aceitas de outra forma, mas que foram aceitas durante períodos em que o site foi afetado por problemas técnicos.',
    user_agreement_gen_2_2_5:
      '2.2.5. Devido a um erro, como um engano, vulnerabilidades, erro técnico, força maior ou de outra forma, foram feitas, colocadas e/ou aceitas apostas devido a este erro.',
    user_agreement_gen_2_2_6:
      '2.2.6. Se a taxa de depósito de um jogador for muito baixa e for sinalizada pela blockchain ou por um site semelhante como "não há taxa suficiente para retransmitir", a BRASA reserva-se o direito de confiscar os ganhos se a BRASA, a seu critério, considerar a transação e o comportamento do jogador fraudulentos.',
    user_agreement_gen_3: '3. Territórios Restritos',

    user_agreement_gen_3_1:
      '3.1. Territórios Bloqueados: China, Países Baixos, Ilhas do Caribe Holandês, Hungria, Austrália, Ontário (Canadá), Curaçao, França, Estados Unidos e/ou qualquer outro país ou estado restrito por lei. Note que é estritamente proibido jogar em jogos da BRASA nos países bloqueados mencionados acima. Seus dados pessoais para o propósito de executar suas funções e fornecer-lhe a melhor assistência e serviço possível. Você consente com tais divulgações.',
    user_agreement_gen_4: '4. Regras Gerais de Apostas',
    user_agreement_gen_4_1: '4.1. Uma aposta só pode ser feita por um titular de conta registrado.',
    user_agreement_gen_4_2: '4.2. Uma aposta só pode ser feita pela internet.',
    user_agreement_gen_4_3:
      '4.3. Você só pode fazer uma aposta se tiver um saldo suficiente em sua conta na BRASA.',
    user_agreement_gen_4_4:
      '4.4. A aposta, uma vez concluída, será regida pela versão do Contrato do Usuário válida e disponível no Website no momento em que a aposta for aceita.',
    user_agreement_gen_4_5:
      '4.5. Qualquer pagamento de uma aposta vencedora é creditado em sua conta, consistindo da aposta multiplicada pelas probabilidades nas quais a aposta foi feita.',
    user_agreement_gen_4_6:
      '4.6. A BRASA reserva o direito de ajustar um pagamento de aposta creditado em uma conta da BRASA se for determinado pela BRASA, a seu exclusivo critério, que tal pagamento foi creditado devido a um erro.',
    user_agreement_gen_4_7:
      '4.7. Uma aposta, que foi feita e aceita, não pode ser alterada, retirada ou cancelada por você.',
    user_agreement_gen_4_8:
      '4.8. A lista de todas as apostas, seu status e detalhes estão disponíveis para você no Website.',
    user_agreement_gen_4_9:
      '4.9. Quando você faz uma aposta, reconhece que leu e entendeu completamente todo este Contrato do Usuário sobre a aposta conforme declarado no Website.',
    user_agreement_gen_4_10:
      '4.10. A BRASA gerencia sua conta e calcula os fundos disponíveis, os fundos pendentes, os fundos de apostas, bem como o valor dos ganhos. Salvo prova em contrário, esses valores são considerados finais e são considerados precisos.',
    user_agreement_gen_4_11: '4.11. Você é totalmente responsável pelas apostas feitas.',
    user_agreement_gen_4_12:
      '4.12. Os ganhos serão pagos em sua conta após a confirmação do resultado final.',
    user_agreement_gen_5: '5. Bônus e Promoções',
    user_agreement_gen_5_1:
      '5.1. A BRASA reserva o direito de cancelar qualquer promoção, bônus ou programa de bônus (incluindo, mas não se limitando a recompensas de recarga, bônus de indicação de amigos e programas de fidelidade) com efeito imediato se acreditarmos que o bônus foi configurado incorretamente ou está sendo abusado, e se o referido bônus tiver sido pago, reservamos o direito de recusar qualquer solicitação de saque e deduzir tal valor de sua conta. Se um bônus é considerado configurado incorretamente ou abusado será determinado exclusivamente pela BRASA.',
    user_agreement_gen_5_2:
      '5.2. Se você usar um Bônus de Depósito, nenhum Saque de seu depósito original será aceito antes que você tenha atingido os requisitos estipulados no Contrato do Usuário do Bônus de Depósito.',
    user_agreement_gen_5_3:
      '5.3. Onde qualquer termo da oferta ou promoção for violado ou houver qualquer evidência de uma série de apostas feitas por um cliente ou grupo de clientes, que devido a um bônus de depósito, pagamentos aprimorados, apostas grátis, apostas sem risco ou qualquer outra oferta promocional resulta em lucros garantidos para o cliente, independentemente do resultado, individualmente ou como parte de um grupo, a BRASA reserva o direito de recuperar o elemento de bônus dessas ofertas e, a seu critério absoluto, liquidar apostas nas probabilidades corretas, anular o bônus de aposta grátis e as apostas sem risco ou anular qualquer aposta financiada pelo bônus de depósito. Além disso, a BRASA reserva o direito de cobrar uma taxa administrativa do cliente até o valor do bônus de depósito, bônus de aposta grátis, aposta sem risco ou pagamento adicional para cobrir custos administrativos. Reservamo-nos ainda o direito de pedir a qualquer cliente que forneça documentação suficiente para que fiquemos satisfeitos a nosso critério absoluto quanto à identidade do cliente antes de creditarmos qualquer bônus, aposta grátis, aposta sem risco ou oferta em sua conta.',
    user_agreement_gen_5_4:
      '5.4. Todas as ofertas da BRASA são destinadas a jogadores recreativos e a BRASA pode, a seu exclusivo critério, limitar a elegibilidade dos clientes para participar de toda ou parte de qualquer promoção.',
    user_agreement_gen_5_5:
      '5.5. A BRASA reserva o direito de alterar, cancelar, recuperar ou recusar qualquer promoção a seu próprio critério.',
    user_agreement_gen_5_6:
      '5.6. Os bônus só podem ser recebidos uma vez por pessoa/conta, família, domicílio, endereço, endereço de e-mail, endereços IP e ambientes onde os computadores são compartilhados (universidade, fraternidade, escola, biblioteca pública, local de trabalho, etc.). O Operador reserva-se o direito de encerrar sua conta e confiscar quaisquer fundos existentes se for encontrada evidência de abuso/fraude.',
    user_agreement_gen_5_7:
      '5.7. Você reconhece e entende que existem Termos de Uso separados com relação a promoções, bônus e ofertas especiais, e que são adicionais a este Contrato de Usuário. Este Contrato de Usuário é estabelecido na página de conteúdo respectiva deste site, ou foi disponibilizado pessoalmente para você, conforme o caso. Em caso de conflito entre as disposições de tais promoções, bônus e ofertas especiais, e as disposições deste Contrato de Usuário, as disposições de tais promoções, bônus e ofertas especiais prevalecerão.',
    user_agreement_gen_5_8:
      '5.8. Podemos insistir que você aposte uma certa quantia de seu próprio depósito antes que possa apostar com quaisquer fundos gratuitos/bônus que creditamos em sua conta.',
    user_agreement_gen_5_9:
      '5.9. Você aceita que certas promoções podem estar sujeitas a restrições e/ou requisitos de Saque que precisam ser cumpridos antes que os fundos creditados sob a promoção possam ser retirados. Tais termos serão devidamente publicados e disponibilizados como parte da promoção. Se você optar por fazer um Saque antes que os requisitos de aposta aplicáveis sejam cumpridos, deduziremos o valor total do bônus, bem como quaisquer ganhos relacionados ao uso dos valores do bônus antes de aprovar qualquer Saque.',
    user_agreement_gen_6: '6. Bate-papo ao Vivo',
    user_agreement_gen_6_1:
      '6.1. Como parte de seu uso do Website, podemos fornecer a você uma facilidade de bate-papo ao vivo, que é moderada por nós e sujeita a controles. Reservamo-nos o direito de revisar o chat e manter um registro de todas as declarações feitas na facilidade. Seu uso da facilidade de bate-papo deve ser para fins recreativos e de socialização.',
    user_agreement_gen_6_2:
      '6.2. Temos o direito de remover a funcionalidade da sala de bate-papo ou encerrar imediatamente sua Conta de Membro e reembolsar o saldo de sua conta se você:',
    user_agreement_gen_6_2_a:
      '(a) fizer declarações que sejam sexualmente explícitas ou grosseiramente ofensivas, incluindo expressões de intolerância, racismo, ódio ou profanidade;',
    user_agreement_gen_6_2_b:
      '(b) fizer declarações que sejam abusivas, difamatórias ou assediadoras ou insultantes;',
    user_agreement_gen_6_2_c:
      '(c) usar a facilidade de bate-papo para anunciar, promover ou relacionar-se de outra forma com outras entidades online;',
    user_agreement_gen_6_2_d:
      '(d) fazer declarações sobre a BRASA, ou qualquer outro site da Internet conectado ao Website que sejam falsas e/ou maliciosas e/ou prejudiciais à BRASA;',
    user_agreement_gen_6_2_e:
      '(e) usar a facilidade de bate-papo para conluio, envolver-se em conduta ilegal ou incentivar condutas que consideramos gravemente inapropriadas. Qualquer chat suspeito será relatado à autoridade competente.',
    user_agreement_gen_6_3:
      '6.3. O Bate-papo ao Vivo é usado como forma de comunicação entre nós e você e não deve ser copiado ou compartilhado com fóruns ou terceiros.',
    user_agreement_gen_7: '7. Limitação de Responsabilidade',
    user_agreement_gen_7_1:
      '7.1. Você acessa o Website e participa dos Jogos por sua própria conta e risco. Os Websites e os Jogos são fornecidos sem qualquer garantia, seja expressa ou implícita.',
    user_agreement_gen_7_2:
      '7.2. Sem prejuízo da generalidade da disposição anterior, nós, nossos diretores, funcionários, parceiros, prestadores de serviços.',
    user_agreement_gen_7_3:
      '7.3. Não garantimos que o software, Jogos e os Websites sejam adequados para o seu propósito.',
    user_agreement_gen_7_4:
      '7.4. Não garantimos que o software, Jogos e os Websites estejam livres de erros.',
    user_agreement_gen_7_5:
      '7.5. Não garantimos que o software, Jogos e os Websites estarão acessíveis sem interrupções.',
    user_agreement_gen_7_6:
      '7.6. Não seremos responsáveis por qualquer perda, custo, despesa ou dano, seja direto, indireto, especial, consequente, incidental ou de outra forma, decorrente de sua utilização dos Websites ou sua participação nos Jogos.',
    user_agreement_gen_7_7:
      '7.7. Você entende e reconhece que, se houver um mau funcionamento em um Jogo ou sua interoperabilidade, quaisquer apostas feitas durante tal mau funcionamento serão anuladas. Fundos obtidos de um Jogo com mau funcionamento serão considerados nulos, assim como quaisquer rodadas de jogo subsequentes com tais fundos, independentemente dos Jogos jogados usando tais fundos.',
    user_agreement_gen_7_8:
      '7.8. Você concorda em nos indenizar integralmente e isentar de responsabilidade, nossos diretores, funcionários, parceiros e prestadores de serviços por qualquer custo, despesa, perda, dano, reivindicação e responsabilidade causados de qualquer forma que possam surgir em relação ao seu uso do Website ou participação nos Jogos.',
    user_agreement_gen_7_9:
      '7.9. Na extensão permitida pela lei, nossa responsabilidade máxima decorrente ou relacionada ao seu uso dos Websites, independentemente da causa das ações (seja em contrato, delito, violação de garantia ou de outra forma), não excederá €100.',
    user_agreement_gen_8: '8. Violações, Penalidades e Rescisão',
    user_agreement_gen_8_1:
      '8.1. Se você violar qualquer disposição deste Contrato de Usuário ou tivermos motivos razoáveis para suspeitar que você as violou, reservamo-nos o direito de não abrir, suspender ou encerrar sua Conta de Membro, ou reter o pagamento de seus ganhos e aplicar tais fundos a quaisquer danos devidos por você.',
    user_agreement_gen_8_2:
      '8.2. Você reconhece que a BRASA será a última decisora de se você violou as regras, termos ou condições da BRASA de uma maneira que resulte em sua suspensão ou proibição permanente de participação em nosso site.',
    user_agreement_gen_9: '9. Autoexclusão',
    user_agreement_gen_9_1:
      '9.1. Ao solicitar um período de autoexclusão, você concorda em seguir os termos e condições abaixo, que estarão em vigor a partir do momento em que o Suporte ao Cliente implementar o período de autoexclusão escolhido.',
    user_agreement_gen_9_2:
      '9.2. Você pode se autoexcluir por períodos de 1, 3, 6, 12 meses ou permanentemente. As solicitações de autoexclusão devem ser feitas via Suporte ao Vivo.',
    user_agreement_gen_9_3:
      '9.3. Uma vez que você se autoexcluiu, não poderá acessar sua conta ou sacar durante este período.',
    user_agreement_gen_9_4:
      '9.4. Se você excluiu sua conta enquanto tem apostas pendentes em sua conta, as apostas feitas permanecerão válidas e serão liquidadas de acordo com os resultados oficiais.',
    user_agreement_gen_9_5:
      '9.5. Uma vez que o período de autoexclusão tenha expirado, você poderá sacar os ganhos de apostas qualificadas. A BRASA não cancela ou anula quaisquer apostas feitas antes que uma autoexclusão tenha sido afetada.',
    user_agreement_gen_9_6:
      '9.6. Uma vez que você se autoexcluiu, não poderá alterar ou modificar o período por um período mais curto ou ter sua autoexclusão cancelada até que o período que você selecionou para a autoexclusão tenha passado.',
    user_agreement_gen_9_7:
      '9.7. Entre em contato com nossa equipe de atendimento ao cliente se desejar estender seu período de autoexclusão.',
    user_agreement_gen_9_8:
      '9.8. Uma vez que seu período de autoexclusão tenha expirado, a reintegração da conta pode ser feita enviando um e-mail para o suporte@BRASA.',
    user_agreement_gen_9_9: '9.9. Ao se autoexcluir, você concorda que:',
    user_agreement_gen_9_9_a: 'a) Você não criará outra conta durante este período.',
    user_agreement_gen_9_9_b:
      'b) Você não irá depositar ou tentar depositar fundos em uma conta BRASA.',
    user_agreement_gen_9_9_c: 'c) Você não irá apostar neste site durante este período.',
    user_agreement_gen_9_9_d:
      'd) Este é um ato voluntário iniciado por você mesmo, e a BlockDance B.V. não será responsável por quaisquer perdas que você possa incorrer durante o período de autoexclusão de qualquer forma.',
    user_agreement_pp: 'Política de Privacidade',
    user_agreement_pp_1:
      'Você reconhece e aceita que, se considerarmos necessário, podemos coletar e usar seus dados pessoais para permitir que você acesse e use os Websites e para permitir que você participe dos Jogos.',
    user_agreement_pp_2:
      'Por meio deste, reconhecemos que, ao coletar seus detalhes pessoais conforme declarado na disposição anterior, estamos vinculados à Lei de Proteção de Dados. Protegeremos suas informações pessoais e respeitaremos sua privacidade de acordo com as melhores práticas comerciais e leis aplicáveis.',
    user_agreement_pp_3:
      'Utilizaremos seus dados pessoais para permitir que você participe dos Jogos e realize operações relevantes para sua participação nos Jogos. Também podemos usar seus dados pessoais para informá-lo sobre alterações, novos serviços e promoções que achamos que você pode achar interessantes. Se você não deseja receber tais correspondências de marketing direto, poderá optar por não utilizar o serviço.',
    user_agreement_pp_4:
      'Seus dados pessoais não serão divulgados a terceiros, a menos que seja exigido por lei. Como os parceiros comerciais, fornecedores ou prestadores de serviços da BRASA podem ser responsáveis por certas partes do funcionamento ou operação geral do Website, os dados pessoais podem ser divulgados a eles. Os funcionários da BRASA têm acesso aos seus dados pessoais com o objetivo de executar suas funções e fornecer a você a melhor assistência e serviço possível. Você consente com tais divulgações.',
    user_agreement_pp_5:
      'Manteremos todas as informações fornecidas como dados pessoais. Você tem o direito de acessar os dados pessoais que mantemos sobre você. Nenhum dado será destruído a menos que exigido por lei, ou a menos que as informações mantidas não sejam mais necessárias para serem mantidas para o propósito da relação.',
    user_agreement_pp_6:
      'Para tornar sua visita aos Websites mais amigável, para rastrear visitas aos Websites e para melhorar o serviço, coletamos uma pequena quantidade de informações enviadas pelo seu navegador, chamada de cookie. Você pode, se desejar, desativar a coleta de cookies. Você deve observar, no entanto, que desativar os cookies pode restringir gravemente ou impedir completamente o uso dos Websites.',
    user_agreement_cp: 'Política de Cookies',
    user_agreement_cp_1: '1. O que são cookies?',
    user_agreement_cp_1_ans:
      'Um cookie é uma pequena quantidade de dados que são armazenados no seu computador. Os cookies são usados para melhorar a experiência do usuário e fornecer informações para o proprietário do site.',
    user_agreement_cp_2: '2. Por que usamos cookies na BRASA?',
    user_agreement_cp_2_ans:
      'Utilizamos cookies para melhorar a experiência do usuário, entender como nosso site é usado e personalizar o conteúdo e a publicidade.',
    user_agreement_cp_3: '3. Quais cookies usamos na BRASA?',
    user_agreement_cp_3_ans_1: 'Aqui estão os principais cookies usados na BRASA:',
    user_agreement_cp_3_ans_2:
      '_fp - armazena a impressão digital do navegador. Tempo de vida: para sempre.',
    user_agreement_cp_3_ans_3:
      '_t - armazena o horário em que o usuário visitou o site pela primeira vez na sessão de navegação atual. Necessário para estatísticas de visitas únicas. Tempo de vida: sessão de navegação.',
    user_agreement_cp_3_ans_4:
      '_r - armazena o referenciador HTTP para a sessão de navegação atual. Necessário para rastrear fontes de tráfego externas. Tempo de vida: sessão de navegação.',
    user_agreement_cp_3_ans_5:
      '_c - armazena o identificador da campanha de afiliados. Necessário para estatísticas de afiliados. Tempo de vida: para sempre.',
    user_agreement_cp_3_ans_6: 'Cookies definidos por terceiros para o domínio genérico:',
    user_agreement_cp_3_ans_7: '*.BRASA',
    user_agreement_cp_3_ans_8: 'Google analytics: _ga, _gat, _gid',
    user_agreement_cp_3_ans_9: 'Zendesk：__ zlcmid',
    user_agreement_cp_3_ans_10: 'Cloudflare：__ cfuid',
    user_agreement_cp_3_ans_11:
      'Alguns navegadores mantêm processos em segundo plano em execução, mesmo se nenhuma guia estiver aberta, devido a isso, cookies de sessão podem permanecer definidos entre as sessões. Também existem cookies definidos por scripts de terceiros para seus domínios.',
    user_agreement_cp_4: '4. Como posso gerenciar meus cookies na BRASA?',

    user_agreement_cp_4_ans:
      'Se você deseja parar de aceitar cookies, você pode fazê-lo através da opção Configurações de Privacidade em seu navegador.',
    user_agreement_cp_5: '5. Política de Proteção de Dados Pessoais',
    user_agreement_cp_5_ans:
      'A missão da BRASA é manter seus Dados seguros e para isso protegemos seus dados de várias maneiras. Fornecemos aos nossos clientes altos padrões de segurança, como criptografia de dados em movimento sobre redes públicas, criptografia de dados em banco de dados, padrões de auditoria, mitigação de Ataques Distribuídos de Negação de Serviço e um Chat Ao Vivo disponível no site.',
    user_agreement_cp_6: '6. Política de Proteção do Servidor',
    user_agreement_cp_6_ans_1: 'Todos os servidores têm criptografia completa;',
    user_agreement_cp_6_ans_2: 'Todos os backups têm criptografia;',
    user_agreement_cp_6_ans_3: 'Firewalls, Acesso VPN;',
    user_agreement_cp_6_ans_4: 'Acesso aos servidores permitido apenas sobre VPN;',
    user_agreement_cp_6_ans_5: 'Todos os serviços http/s funcionam sobre Cloudflare;',
    user_agreement_cp_6_ans_6: 'Conexões para nós sobre VPN;',
    user_agreement_cp_6_ans_7: 'Túneis de encaminhamento de porta SSH;',
    user_agreement_cp_6_ans_8: 'Serviços permitidos apenas sobre VPN;',
    user_agreement_cp_6_ans_9: 'O servidor tem firewall e permitido apenas porta SSH;',
    user_agreement_cp_6_ans_10: 'Alertas sobre serviços críticos.',
    user_agreement_cp_6_ans_11: 'Notificação de Violação de Dados',
    user_agreement_cp_6_ans_12:
      'Quando a BRASA for informada sobre violações de dados pessoais, notificaremos os usuários relevantes de acordo com os prazos estabelecidos pelo GDPR.',
    user_agreement_cp_7: '7. Transferência Internacional de Dados',
    user_agreement_cp_7_ans_1:
      'Divulgamos dados pessoais a terceiros apenas quando necessário para fornecer um serviço de alta qualidade ou para responder a solicitações legais das autoridades.',
    user_agreement_cp_7_ans_2:
      'Compartilhamos os seguintes dados com sistemas de terceiros: Zendesk Inc. - informações de nome de usuário e e-mail são transferidas se o usuário enviar uma mensagem para o chat ao vivo ou enviar um e-mail para a caixa de correio de suporte.',
    user_agreement_cp_7_ans_3:
      'Embora tentemos fazer o nosso melhor, problemas podem ocorrer de vez em quando. Nossa equipe fará tudo o que pudermos para resolver seus problemas o mais rápido possível. Para ajudá-lo mais rapidamente, você pode nos juntar clicando no botão acima para entrar no grupo do telegrama.',
    user_agreement_cp_7_ans_4: 'Se ocorrer um erro, forneça as seguintes informações:',
    user_agreement_cp_7_ans_5: 'Nome de usuário',
    user_agreement_cp_7_ans_6: 'Data e hora do problema',
    user_agreement_cp_7_ans_7: 'ID do jogo ou nome da tabela, se houver',
    user_agreement_cp_7_ans_8: 'Captura de tela do erro, se possível',

    user_agreement_cp_7_ans_9:
      'Nós realmente apreciamos sua ajuda e o relatório de erro que você forneceu, porque seu relatório de informações pode nos ajudar a melhorar.',
    user_agreement_cp_7_ans_10: 'Coleta e Uso de Seus Dados Pessoais',
    user_agreement_cp_7_ans_11: 'Tipos de Dados Coletados',
    user_agreement_cp_7_ans_12: 'Dados Pessoais',
    user_agreement_cp_7_ans_13:
      'Ao usar Nosso Serviço, podemos solicitar que você nos forneça certas informações de identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo. As informações de identificação pessoal podem incluir, entre outras:',
    user_agreement_cp_7_ans_14: 'Endereço de e-mail',
    user_agreement_cp_7_ans_15: 'Nome e sobrenome',
    user_agreement_cp_7_ans_16: 'Dados de Uso são coletados automaticamente ao usar o Serviço.',
    user_agreement_cp_7_ans_17:
      'Os Dados de Uso podem incluir informações como o endereço IP do seu dispositivo (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do nosso Serviço que você visita, o horário e a data da sua visita, o tempo gasto nessas páginas, identificadores exclusivos de dispositivo e outros dados de diagnóstico.',
    user_agreement_cp_7_ans_18:
      'Ao acessar o Serviço por ou através de um dispositivo móvel, podemos coletar determinadas informações automaticamente, incluindo, entre outras, o tipo de dispositivo móvel que você usa, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, o seu sistema operacional móvel, o tipo de navegador de Internet móvel que você usa, identificadores de dispositivo exclusivos e outros dados de diagnóstico.',
    user_agreement_cp_7_ans_19:
      'Também podemos coletar informações que o seu navegador envia sempre que você visita nosso Serviço ou quando você acessa o Serviço por ou através de um dispositivo móvel.',
    user_agreement_cp_7_ans_20: 'Informações de Serviços de Mídia Social de Terceiros',
    user_agreement_cp_7_ans_21:
      'A BRASA permite que você crie uma conta e faça login para usar o Serviço através dos seguintes Serviços de Mídia Social de Terceiros:',
    user_agreement_cp_7_ans_22: 'Google',
    user_agreement_cp_7_ans_23: 'Facebook',
    user_agreement_cp_7_ans_24: 'Telegram',
    user_agreement_cp_7_ans_25: 'Metamask',
    user_agreement_cp_7_ans_26: 'Web3.0',
    user_agreement_cp_7_ans_27:
      'Se você decidir se registrar ou conceder-nos acesso a um Serviço de Mídia Social de Terceiros, podemos coletar dados pessoais que já estão associados à sua conta de Serviço de Mídia Social de Terceiros, como seu nome, seu endereço de e-mail, suas atividades ou sua lista de contatos associada a essa conta.',
    user_agreement_cp_7_ans_28:
      'Você também pode ter a opção de compartilhar informações adicionais com a BRASA por meio da sua conta de Serviço de Mídia Social de Terceiros. Se você optar por fornecer tais informações e Dados Pessoais, durante o registro ou de outra forma, você está dando permissão à BRASA para usá-los, compartilhá-los e armazená-los de acordo com esta Política de Privacidade.',
    user_agreement_delete_personal_data: 'Excluir Dados Pessoais',
    user_agreement_dps_ans:
      'Você pode solicitar a exclusão de seus dados pessoais se a BRASA não tiver mais um motivo legal para continuar a processá-los ou armazená-los. Observe que este direito não é garantido - no sentido de que a BRASA não tem a capacidade de cumprir sua solicitação se estiver sujeita a uma obrigação legal de armazenar seus dados. Você pode solicitar a exclusão de seus dados pessoais enviando um e-mail para support@BRASA.',
    user_agreement_registration_n_login: 'Registro e Login',
    user_agreement_rnl_ans_1:
      'Você deve ter pelo menos 18 anos para se registrar. Se você deseja adicionar seu endereço de e-mail, certifique-se de que o endereço de e-mail que você inseriu está correto para que depois possa ser usado na verificação de conta KYC.',
    user_agreement_rnl_ans_2:
      'Você pode fazer login a qualquer momento. Para maior segurança, recomendamos que você adicione 2FA. Para saber mais sobre autenticador do Google.',
    user_agreement_rnl_ans_3:
      'Se você precisar alterar seu e-mail registrado, lamentamos muito, mas não somos capazes de atualizar estas informações. Se você insistir em mudar seu nome de usuário e/ou e-mail registrado, sugerimos que você feche a conta atual e registre uma nova.',
  },
  policy: {
    policy_title: 'Política de Privacidade',
    policy_term: ` <div class="policy_wrapper">
      <div>
        Esta política de privacidade ("política") ajudará você a entender como a BRASA ("nós", "nos" ou "nosso") usa e protege os dados que você nos fornece quando visita e utiliza [URL do blog] ("blog", "serviço").
      </div>
      <div>
        Reservamos o direito de alterar esta política a qualquer momento, sendo que você será prontamente atualizado. Se deseja se manter atualizado com as últimas alterações, aconselhamos que visite frequentemente esta página.
      </div>
      <br />
      <div>Dados do Usuário que Coletamos</div>
      <div>Ao visitar o blog, podemos coletar os seguintes dados:</div>
      <ul>
        <li>Seu endereço IP.</li>
        <li>Suas informações de contato e endereço de e-mail.</li>
        <li>Outras informações, como interesses e preferências.</li>
        <li>Perfil de dados sobre seu comportamento online em nosso blog.</li>
      </ul>
      <br />
      <div>Por Que Coletamos Seus Dados</div>
      <div>
        Estamos coletando seus dados por várias razões:
        <ul>
          <li>Para entender melhor suas necessidades.</li>
          <li>Para melhorar nossos serviços e produtos.</li>
          <li>
            Para enviar e-mails promocionais contendo informações que achamos que você achará interessantes.
          </li>
          <li>
            Para contatá-lo para preencher pesquisas e participar de outros tipos de pesquisas de mercado.
          </li>
          <li>
            Para personalizar nosso blog de acordo com seu comportamento online e preferências pessoais.
          </li>
        </ul>
      </div>
      <br />
      <div>Protegendo e Salvaguardando os Dados</div>
      <div>
        A BRASA está comprometida em proteger seus dados e mantê-los confidenciais. A BRASA fez todo o possível para evitar o roubo de dados, acesso não autorizado e divulgação, implementando as tecnologias e software mais recentes, que nos ajudam a proteger todas as informações que coletamos online.
      </div>
      <br />
      <div> Nossa Política de Cookies</div>
      <div>
        Uma vez que você concorda em permitir que nosso blog use cookies, você também concorda em usar os dados que ele coleta sobre seu comportamento online (analisar o tráfego na web, as páginas da web que você visita e passa mais tempo). Os dados que coletamos usando cookies são usados para personalizar nosso blog às suas necessidades. Depois de usarmos os dados para análise estatística, os dados são completamente removidos de nossos sistemas.
      </div>
      <div>
        Observe que os cookies não nos permitem controlar seu computador de forma alguma. Eles são estritamente usados para monitorar quais páginas você considera úteis e quais não, para que possamos proporcionar uma experiência melhor para você.
      </div>
      <div>
        Se deseja desativar os cookies, pode fazê-lo acessando as configurações do seu navegador de Internet. Você pode visitar <a href="https://www.internetcookies.com" target="_blank">
        https://www.internetcookies.com</a>, que contém informações completas sobre como fazer isso em uma ampla variedade de navegadores e dispositivos.
      </div>
      <br />
      <div>Links para Outros Sites</div>
      <div>
        Nosso blog contém links que levam a outros sites. Se você clicar nesses links, a BRASA não é responsável pela proteção de seus dados e privacidade. Visitar esses sites não é regido por este acordo de política de privacidade. Certifique-se de ler a documentação de política de privacidade do site para o qual você vai a partir do nosso site.
      </div>
      <br />
      <div>Restringindo a Coleta de seus Dados Pessoais</div>
      <div>
        Em algum momento, você pode desejar restringir o uso e a coleta de seus dados pessoais. Você pode fazer isso seguindo estas etapas:
      </div>
      <div>
        Ao preencher os formulários no blog, verifique se há uma caixa que você pode deixar desmarcada, se não deseja divulgar suas informações pessoais.
      </div>
      <div>
        Se já concordou em compartilhar suas informações conosco, sinta-se à vontade para nos contatar por e-mail e teremos o prazer de fazer isso por você.
      </div>
      <div>
        A BRASA não alugará, venderá ou distribuirá suas informações pessoais a terceiros, a menos que tenha sua permissão. Podemos fazer isso se a lei nos obrigar. Suas informações pessoais serão usadas quando precisarmos enviar materiais promocionais se você concordar com esta política de privacidade.
      </div>
      <br />
      <div>
        DICAS DE PROFISSIONAL: Economize seu tempo e elimine as conjecturas do jargão legal com o gerador de política de privacidade profissional confiável por milhares de empresas.
      </div>
    </div>`,
  },
  term_service: {
    title: 'Termos de Serviço',
    term: `<div class="term_wrapper">
    <div>
      O Meta desenvolve tecnologias e serviços que permitem às pessoas se conectar umas com as outras, construir comunidades e expandir negócios. Estes Termos regem o seu uso do Facebook, Messenger e outros produtos, recursos, aplicativos, serviços, tecnologias e software que oferecemos (os Produtos do Meta ou Produtos), exceto quando afirmamos expressamente que se aplicam termos separados (e não estes). Estes Produtos são fornecidos a você pela Meta Platforms, Inc.
    </div>
    <div>
      Não cobramos de você para usar o Facebook ou os outros produtos e serviços cobertos por estes Termos, a menos que afirmemos o contrário. Em vez disso, empresas, organizações e outras pessoas nos pagam para mostrar anúncios de seus produtos e serviços a você. Ao usar nossos Produtos, você concorda que podemos mostrar anúncios que achamos que podem ser relevantes para você e seus interesses. Usamos seus dados pessoais para ajudar a determinar quais anúncios personalizados mostrar a você.
    </div>
    <div>
      Não vendemos seus dados pessoais para anunciantes, e não compartilhamos informações que o identifiquem diretamente (como seu nome, endereço de e-mail ou outras informações de contato) com anunciantes, a menos que você nos dê permissão específica. Em vez disso, os anunciantes podem nos informar sobre o tipo de público que desejam ver seus anúncios, e mostramos esses anúncios para pessoas que podem estar interessadas. Fornecemos aos anunciantes relatórios sobre o desempenho de seus anúncios que os ajudam a entender como as pessoas estão interagindo com seu conteúdo. Consulte a Seção 2 abaixo para saber mais sobre como funciona a publicidade personalizada nos Produtos do Meta sob estes termos.
    </div>
    <div>
      Nossa Política de Privacidade explica como coletamos e usamos seus dados pessoais para determinar alguns dos anúncios que você vê e fornecer todos os outros serviços descritos abaixo. Você também pode acessar as páginas de configurações do Produto do Meta relevante a qualquer momento para revisar as escolhas de privacidade que você tem sobre como usamos seus dados.
    </div>
    <br />
    <div>1. Os serviços que oferecemos</div>
    <div>
      Nossa missão é dar às pessoas o poder de construir comunidade e aproximar o mundo. Para ajudar a avançar nesta missão, fornecemos os Produtos e serviços descritos abaixo para você:
    </div>
    <div>
      Oferecer uma experiência personalizada para você:
    </div>
    <div>
      Sua experiência no Facebook é diferente da de qualquer outra pessoa: desde as postagens, histórias, eventos, anúncios e outros conteúdos que você vê no Feed de Notícias do Facebook ou em nossa plataforma de vídeo até as Páginas do Facebook que você segue e outros recursos que você pode usar, como o Marketplace do Facebook e pesquisa. Por exemplo, usamos dados sobre as conexões que você faz, as escolhas e configurações que você seleciona e o que você compartilha e faz dentro e fora de nossos Produtos - para personalizar sua experiência.
    </div>
    <div>
      Conectar você com pessoas e organizações que você se importa:
    </div>
    <div>
      Ajudamos você a encontrar e se conectar com pessoas, grupos, empresas, organizações e outros que são importantes para você em todos os Produtos do Meta que você usa. Usamos dados para fazer sugestões para você e outras pessoas - por exemplo, grupos para participar, eventos para comparecer, Páginas do Facebook para seguir ou enviar uma mensagem, shows para assistir e pessoas com as quais você pode querer se tornar amigos. Laços mais fortes criam comunidades melhores, e acreditamos que nossos serviços são mais úteis quando as pessoas estão conectadas a pessoas, grupos e organizações que se importam.
    </div>
    <div>
      Capacitar você a se expressar e se comunicar sobre o que é importante para você:
    </div>
    <div>
      Existem muitas maneiras de se expressar no Facebook para se comunicar com amigos, familiares e outros sobre o que é importante para você - por exemplo, compartilhando atualizações de status, fotos, vídeos e histórias em todos os Produtos do Meta (consistente com suas configurações), enviando mensagens ou fazendo chamadas de voz ou vídeo para um amigo ou várias pessoas, criando eventos ou grupos, ou adicionando conteúdo ao seu perfil, além de mostrar insights sobre como os outros interagem com seu conteúdo. Também desenvolvemos e continuamos a explorar novas maneiras para as pessoas usarem a tecnologia, como realidade aumentada e vídeo 360 para criar e compartilhar conteúdo mais expressivo e envolvente nos Produtos do Meta.
    </div>
    <div>
      Ajudar você a descobrir conteúdo, produtos e serviços que possam interessar a você:
    </div>
    <div>
      Mostramos a você anúncios personalizados, ofertas e outros conteúdos patrocinados ou comerciais para ajudá-lo a descobrir conteúdo, produtos e serviços oferecidos pelas muitas empresas e organizações que usam o Facebook e outros Produtos do Meta. A Seção 2 abaixo explica isso com mais detalhes.
    </div>
    <div>
      Promover a segurança, a segurança e a
      <div>
      Em alguns casos, o Conselho de Supervisão pode revisar nossas decisões, sujeito aos seus termos e estatutos. Saiba mais <a href="https://www.example.com/conselho-de-supervisao">aqui</a>.
    </div>
    <div>
      Utilize e desenvolva tecnologias avançadas para fornecer serviços seguros e funcionais para todos:
    </div>
    <div>
      Utilizamos e desenvolvemos tecnologias avançadas - como inteligência artificial, sistemas de aprendizado de máquina e realidade aumentada - para que as pessoas possam usar nossos Produtos com segurança, independentemente da capacidade física ou localização geográfica. 
      Por exemplo, tecnologias como essa ajudam pessoas com deficiências visuais a entender o que ou quem está em fotos ou vídeos compartilhados no Facebook ou Instagram. 
      Também desenvolvemos tecnologia de rede e comunicação sofisticada para ajudar mais pessoas a se conectarem à internet em áreas com acesso limitado. 
      E desenvolvemos sistemas automatizados para melhorar nossa capacidade de detectar e remover atividades abusivas e perigosas que possam prejudicar nossa comunidade e a integridade de nossos Produtos.
    </div>
    <div>
      Pesquise maneiras de tornar nossos serviços melhores:
    </div>
    <div>
      Nos envolvemos em pesquisas para desenvolver, testar e melhorar nossos Produtos. Isso inclui analisar os dados que temos sobre nossos usuários e entender como as pessoas usam nossos Produtos, por exemplo, conduzindo pesquisas e testes e solucionando problemas em novos recursos. 
      Nossa Política de Privacidade explica como usamos os dados para apoiar essa pesquisa para os fins de desenvolvimento e melhoria de nossos serviços.
    </div>
    <div>
      Forneça experiências consistentes e contínuas em todos os Produtos da Meta Company:
    </div>
    <div>
      Nossos Produtos ajudam você a encontrar e se conectar com pessoas, grupos, empresas, organizações e outros que são importantes para você. 
      Projetamos nossos sistemas para que sua experiência seja consistente e contínua nos diferentes Produtos da Meta Company que você usa. 
      Por exemplo, usamos dados sobre as pessoas com quem você interage no Facebook para facilitar a conexão com elas no Instagram ou Messenger, 
      e permitimos que você se comunique com uma empresa que você segue no Facebook através do Messenger.
    </div>
    <div>
      Garantir acesso aos nossos serviços:
    </div>
    <div>
      Para operar nossos serviços globais e permitir que você se conecte com pessoas ao redor do mundo, precisamos transferir, armazenar e distribuir conteúdo e dados para nossos data centers, parceiros, provedores de serviços, fornecedores e sistemas ao redor do mundo, incluindo fora do seu país de residência. 
      O uso dessa infraestrutura global é necessário e essencial para fornecer nossos serviços. 
      Essa infraestrutura pode ser de propriedade, operada ou controlada pela Meta Platforms, Inc., Meta Platforms Ireland Limited ou suas afiliadas.
    </div>
    <br/>
    <div>
      2. Como nossos serviços são financiados
    </div>
    <div>
      Em vez de pagar para usar o Facebook e os outros produtos e serviços que oferecemos, ao usar os Produtos da Meta cobertos por estes Termos, 
      você concorda que podemos mostrar a você anúncios personalizados e outro conteúdo comercial e patrocinado que empresas e organizações nos pagam para promover nos Produtos da Meta e fora deles. 
      Usamos seus dados pessoais, como informações sobre sua atividade e interesses, para mostrar a você anúncios personalizados e conteúdo patrocinado que possa ser mais relevante para você.
    </div>
    <div>
      Proteger a privacidade das pessoas é fundamental para como projetamos nosso sistema de anúncios personalizados. 
      Isso significa que podemos mostrar a você anúncios relevantes e úteis sem informar aos anunciantes quem você é. 
      Não vendemos seus dados pessoais. Permitimos que os anunciantes nos informem sobre seu objetivo comercial, 
      e o tipo de público que desejam ver seus anúncios (por exemplo, pessoas entre 18 e 35 anos que gostam de ciclismo). 
      Em seguida, mostramos o anúncio para pessoas que achamos que podem estar interessadas.
    </div>
    <div>
      Também fornecemos aos anunciantes relatórios sobre o desempenho de seus anúncios para ajudá-los a entender como as pessoas estão interagindo com seu conteúdo nos Produtos da Meta e fora deles. 
      Por exemplo, fornecemos informações demográficas gerais e de interesse aos anunciantes para ajudá-los a entender melhor seu público, 
      como o fato de que mulheres entre 25 e 34 anos que moram em Madrid e gostam de engenharia de software viram um anúncio. 
      Não compartilhamos informações que o identifiquem diretamente (informações como seu nome ou endereço de e-mail que, por si só, podem ser usadas para contatá-lo ou identificar quem você é) 
      a menos que você nos dê permissão específica. Saiba mais sobre como os anúncios da Meta funcionam <a href="https://www.example.com/anuncios-da-meta">aqui</a>.
    </div>
    <div>
      Coletamos e usamos seus dados pessoais para fornecer os serviços descritos acima para você. Você pode aprender como coletamos e usamos seus dados em nossa Política de Privacidade. 
      Você tem controle sobre os tipos de anúncios e anunciantes que vê, e os tipos de informações que usamos para determinar quais anúncios mostramos a você. Saiba mais <a href="https://www.example.com/politica-de-privacidade">aqui</a>.
    </div>    
    <br/>
    <div>
    <div>
    3. Seus compromissos com o Facebook e nossa comunidade
  </div>
  <div>
    Fornecemos esses serviços a você e a outros para ajudar a avançar nossa missão. Em troca, precisamos que você faça os seguintes compromissos:
  </div>
  <div>
    3.1. Quem pode usar o Facebook
  </div>
  <div>
    Quando as pessoas apoiam suas opiniões e ações, nossa comunidade é mais segura e responsável. Por esse motivo, você deve:
  </div>
  <div>
    Fornecer para sua conta o mesmo nome que você usa no dia a dia.
  </div>
  <div>
    Fornecer informações precisas sobre você.
  </div>
  <div>
    Criar apenas uma conta (sua própria) e usá-la para fins pessoais.
  </div>
  <div>
    Não compartilhar sua senha, dar acesso à sua conta do Facebook a outras pessoas ou transferir sua conta para qualquer outra pessoa (sem nossa permissão).
  </div>
  <div>
    Tentamos disponibilizar o Facebook para todos, mas você não pode usar o Facebook se:
  </div>
  <div>
    Você tem menos de 13 anos.
  </div>
  <div>
    Você é um agressor sexual condenado.
  </div>
  <div>
    Já desativamos sua conta anteriormente por violações de nossos Termos ou Padrões da Comunidade, ou outros termos e políticas que se aplicam ao seu uso do Facebook. Se desativarmos sua conta por violação de nossos Termos, dos Padrões da Comunidade ou de outros termos e políticas, você concorda em não criar outra conta sem nossa permissão. 
    Receber permissão para criar uma nova conta é fornecido a nosso critério exclusivo, e não significa ou implica que a ação disciplinar foi errada ou sem motivo.
  </div>
  <div>
    Você está proibido de receber nossos produtos, serviços ou software de acordo com as leis aplicáveis.
  </div>
  <div>
    3.2. O que você pode compartilhar e fazer nos Produtos da Meta
  </div>
  <div>
    Queremos que as pessoas usem os Produtos da Meta para se expressarem e compartilharem conteúdo que seja importante para elas, mas não às custas da segurança e do bem-estar dos outros ou da integridade de nossa comunidade. 
    Portanto, você concorda em não se envolver nas condutas descritas abaixo (ou facilitar ou apoiar outras pessoas a fazê-lo):
  </div>
  <div>
    Você não pode usar nossos Produtos para fazer ou compartilhar nada:
  </div>
  <div>
    Que viole estes Termos, os Padrões da Comunidade ou outros termos e políticas que se aplicam ao seu uso de nossos Produtos.
  </div>
  <div>
    Que seja ilegal, enganoso, discriminatório ou fraudulento (ou ajude alguém a usar nossos Produtos de tal maneira).
  </div>
  <div>
    Que você não possui ou não tem os direitos necessários para compartilhar.
  </div>
  <div>
    Que infrinja ou viole os direitos de outra pessoa, incluindo seus direitos de propriedade intelectual (como infringir os direitos autorais ou marcas registradas de outra pessoa, 
      ou distribuir ou vender bens falsificados ou pirateados), a menos que uma exceção ou limitação se aplique de acordo com a lei aplicável.
  </div>
  <div>
    Você não pode carregar vírus ou código malicioso, usar os serviços para enviar spam ou fazer qualquer outra coisa que possa desabilitar, sobrecarregar, interferir, 
    ou prejudicar o funcionamento adequado, a integridade, a operação ou a aparência de nossos serviços, sistemas ou Produtos.
  </div>
  <div>
    Você não pode acessar ou coletar dados de nossos Produtos usando meios automatizados (sem nossa permissão prévia) ou tentar acessar dados aos quais não tem permissão para acessar.
  </div>
  <div>
    Você não pode procurar, solicitar ou coletar nomes de usuário ou senhas de Produto, ou apropriar-se indevidamente de tokens de acesso.
  </div>
  <div>
    Você não pode vender, licenciar ou comprar quaisquer dados obtidos de nós ou de nossos serviços, exceto conforme previsto nos Termos da Plataforma.
  </div>
  <div>
    Você não pode usar indevidamente nenhum canal de relatório, sinalização, disputa ou apelação, como fazer relatórios ou apelações fraudulentas, duplicadas ou infundadas.
  </div>
  <div>
    Podemos remover ou restringir o acesso a conteúdo que viole essas disposições. Também podemos suspender ou desativar sua conta por conduta que viole essas disposições, conforme fornecido na Seção 4.B.
  </div>
  <div>
    Se removermos conteúdo que você compartilhou em violação aos Padrões da Comunidade, informaremos e explicaremos quaisquer opções que você tenha para solicitar outra revisão, 
    a menos que você viole seriamente ou repetidamente estes Termos ou se isso puder nos expor ou expor outros a responsabilidade legal; prejudicar nossa comunidade de usuários; 
    comprometer ou interferir na integridade ou operação de quaisquer de nossos serviços, sistemas ou Produtos; onde estamos restritos devido a limitações técnicas; 
    ou onde somos proibidos de fazê-lo por motivos legais. Para obter informações sobre suspensão ou término da conta, consulte a Seção 4.B abaixo.
  </div>  
  <div>
  Para ajudar a apoiar nossa comunidade, encorajamos você a relatar conteúdo ou condutas que você acredita violar seus direitos (incluindo direitos de propriedade intelectual) ou nossos termos e políticas, se essa funcionalidade existir em sua jurisdição.
</div>
<div>
  Também podemos remover ou restringir o acesso a recursos de conteúdo, serviços ou informações se determinarmos que isso é razoavelmente necessário para evitar ou mitigar o uso indevido de nossos serviços ou impactos legais ou regulatórios adversos para a Meta.
</div>
<div>
  3.3. As permissões que você nos concede
</div>
<div>
  Precisamos de algumas permissões suas para fornecer nossos serviços:
</div>
<div>
  Permissão para usar conteúdo que você cria e compartilha: Algum conteúdo que você compartilha ou carrega, como fotos ou vídeos, pode ser protegido por leis de propriedade intelectual.
</div>
<div>
  Você mantém a propriedade dos direitos de propriedade intelectual (como direitos autorais ou marcas registradas) em qualquer conteúdo desse tipo que você cria e compartilha no Facebook e em outros Produtos da Meta que você usa. Nada nestes Termos retira os direitos que você tem sobre seu próprio conteúdo. Você tem a liberdade de compartilhar seu conteúdo com qualquer outra pessoa, onde quer que deseje.
</div>
<div>
  No entanto, para fornecer nossos serviços, precisamos que você nos conceda algumas permissões legais (conhecidas como "licença") para usar esse conteúdo. 
  Isso é exclusivamente para os fins de fornecer e melhorar nossos Produtos e serviços conforme descrito na Seção 1 acima.
</div>
<div>
  Especificamente, quando você compartilha, posta ou carrega conteúdo que é protegido por direitos de propriedade intelectual no Facebook ou em conexão com nossos Produtos, 
  você nos concede uma licença não exclusiva, transferível, sublicenciável, isenta de royalties e mundial para hospedar, usar, distribuir, modificar, executar, copiar, 
  executar publicamente ou exibir, traduzir e criar obras derivadas de seu conteúdo (em conformidade com suas configurações de privacidade e aplicativos). 
  Isso significa, por exemplo, que se você compartilhar uma foto no Facebook, você nos concede permissão para armazená-la, copiá-la 
  e compartilhá-la com outras pessoas (novamente, de acordo com suas configurações), como Produtos da Meta ou fornecedores de serviços que apoiam esses produtos e serviços. 
  Esta licença terminará quando seu conteúdo for excluído de nossos sistemas.
</div>
<div>
Você pode excluir conteúdo individual que você compartilha, publica e carrega a qualquer momento. Além disso, todo o conteúdo postado em sua conta pessoal será excluído se você excluir sua conta. Saiba mais sobre como excluir sua conta. A exclusão da conta não exclui automaticamente o conteúdo que você posta como administrador de uma página ou conteúdo que você cria coletivamente com outros usuários, como fotos em Álbuns Compartilhados, que podem continuar visíveis para outros membros do álbum.
</div>
<div>
Pode levar até 90 dias para excluir o conteúdo depois que iniciarmos o processo de exclusão da conta ou recebermos uma solicitação de exclusão de conteúdo. Se você enviar o conteúdo para a lixeira, o processo de exclusão começará automaticamente em 30 dias, a menos que você escolha excluir o conteúdo mais cedo. Enquanto o processo de exclusão desse conteúdo estiver em andamento, o conteúdo não estará mais visível para outros usuários. Depois que o conteúdo for excluído, pode levar até mais 90 dias para removê-lo dos backups e sistemas de recuperação de desastres.
</div>
<div>
O conteúdo não será excluído dentro de 90 dias do início do processo de exclusão da conta ou exclusão do conteúdo nas seguintes situações
onde seu conteúdo foi usado por outros de acordo com esta licença e eles não o excluíram (nesse caso, esta licença continuará a se aplicar até que esse conteúdo seja excluído);
quando a exclusão dentro de 90 dias não é possível devido a limitações técnicas de nossos sistemas, caso em que completaremos a exclusão assim que tecnicamente viável; ou
quando a exclusão imediata restringiria nossa capacidade de:
</div>
<div>
investigar ou identificar atividades ilegais ou violações de nossos termos e políticas (por exemplo, identificar ou investigar o uso indevido de nossos Produtos ou sistemas);
</div>
<div>
proteger a segurança, integridade e segurança de nossos Produtos, sistemas, serviços, nossos funcionários e usuários, e nos defender;
cumprir obrigações legais para a preservação de evidências, incluindo dados das Empresas Meta que fornecem produtos e serviços financeiros, a fim de cumprir quaisquer obrigações de manutenção de registros exigidas por lei; ou
cumprir uma solicitação de uma autoridade judicial ou administrativa, aplicação da lei ou uma agência governamental;
</div>
<div>
nesse caso, o conteúdo será retido por não mais tempo do que for necessário para os fins para os quais foi retido (a duração exata variará caso a caso).
Em cada um dos casos acima, esta licença continuará até que o conteúdo tenha sido completamente excluído.
Permissão para usar seu nome, foto de perfil e informações sobre suas ações com anúncios e conteúdo patrocinado ou comercial: Você nos dá permissão para usar seu nome e foto de perfil e informações sobre as ações que você tomou no Facebook ao lado ou em conexão com anúncios, ofertas e outro conteúdo patrocinado ou comercial que exibimos em nossos Produtos, sem qualquer compensação para você. Por exemplo, podemos mostrar aos seus amigos que você está interessado em um evento anunciado ou curtiu uma Página do Facebook criada por uma marca que nos pagou para exibir seus anúncios no Facebook. Anúncios e conteúdo como este só podem ser vistos por pessoas que têm sua permissão para ver as ações que você tomou nos Produtos da Meta. Você pode aprender mais sobre suas configurações e preferências de anúncios. 
Permissão para atualizar o software que você usa ou baixa: Se você baixar ou usar nosso software, você nos dá permissão para baixar e instalar atualizações para o software, quando disponíveis.
</div>
<div>
3.4. Limites no uso de nossa propriedade intelectual
</div>
<div>
Se você usar conteúdo coberto por direitos de propriedade intelectual que possuímos e disponibilizamos em nossos Produtos (por exemplo, imagens, designs, vídeos ou sons que fornecemos e que você adiciona ao conteúdo que você cria ou compartilha no Facebook), nós mantemos todos os direitos sobre esse conteúdo (mas não o seu). 
Você só pode usar nossos direitos autorais ou marcas registradas (ou quaisquer marcas semelhantes) conforme expressamente permitido por nossas Diretrizes de Uso da Marca ou com nossa permissão por escrito. Você deve obter nossa permissão por escrito (ou permissão sob uma licença de código aberto) para modificar, traduzir, criar obras derivadas, descompilar, 
ou engenharia reversa de nossos produtos ou seus componentes, ou de outra forma tentar extrair código fonte de nós, 
a menos que uma exceção ou limitação se aplique de acordo com a lei aplicável ou sua cond
    <br/>
    <div>
  4. Disposições adicionais
</div>
<div>
  4.1. Atualização dos nossos Termos
</div>
<div>
  Trabalhamos constantemente para melhorar nossos serviços e desenvolver novos recursos para tornar nossos Produtos melhores para você e nossa comunidade. Como resultado, pode ser necessário atualizar estes Termos de tempos em tempos para refletir com precisão nossos serviços e práticas, para promover uma experiência segura em nossos Produtos e serviços e/ou para cumprir a legislação aplicável. Faremos alterações apenas se as disposições já não forem mais apropriadas ou se estiverem incompletas, e apenas se as alterações forem razoáveis e levarem em consideração seus interesses ou se as alterações forem necessárias para fins de segurança ou para cumprir a legislação aplicável.
</div>
<div>
  Notificaremos você (por exemplo, por e-mail ou através de nossos Produtos) pelo menos 30 dias antes de fazermos alterações nestes Termos e daremos a você a oportunidade de revisá-los antes que entrem em vigor, a menos que as mudanças sejam exigidas por lei. Uma vez que quaisquer Termos atualizados estejam em vigor, você estará vinculado a eles se continuar a usar nossos Produtos.
  Esperamos que você continue usando nossos Produtos, mas se você não concordar com nossos Termos atualizados e não quiser mais fazer parte da comunidade Meta, você pode excluir sua conta a qualquer momento.
</div>
<div>
  4.2. Suspensão ou término da conta
</div>
<div>
  Queremos que o Facebook seja um lugar onde as pessoas se sintam bem-vindas e seguras para se expressar e compartilhar seus pensamentos e ideias. Se determinarmos, a nosso critério, que você violou clara, séria ou repetidamente nossos Termos ou Políticas, incluindo em particular os Padrões da Comunidade, podemos suspender ou desativar permanentemente seu acesso aos Produtos da Meta Company e podemos desativar ou excluir permanentemente sua conta. Também podemos desativar ou excluir sua conta se você infringir repetidamente os direitos de propriedade intelectual de outras pessoas ou quando formos obrigados a fazê-lo por motivos legais.
</div>
<div>
  Podemos desativar ou excluir sua conta se, após o registro, sua conta não for confirmada, sua conta permanecer inativa e sem uso por um longo período de tempo, ou se detectarmos que alguém pode ter usado sem sua permissão e não pudermos confirmar sua propriedade da conta. Saiba mais sobre como desativamos e excluímos contas.
</div>
<div>
  Quando tomamos tal ação, avisaremos você e explicaremos quaisquer opções que você tenha para solicitar uma revisão, a menos que fazer isso possa nos expor ou expor a outros a responsabilidade legal; prejudicar nossa comunidade de usuários; comprometer ou interferir na integridade ou operação de qualquer um de nossos serviços, sistemas ou Produtos; onde somos restritos devido a limitações técnicas; ou onde somos proibidos de fazê-lo por motivos legais.
</div>
<div>
  Você pode aprender mais sobre o que pode fazer se sua conta foi desativada e como entrar em contato conosco se achar que desativamos sua conta por engano.
</div>
<div>
  Se você excluir ou desativarmos ou excluirmos sua conta, estes Termos serão encerrados como um acordo entre você e nós, mas as seguintes disposições permanecerão em vigor: 3, 4.2-4.5.
</div>
<div>
4.3. Limites de responsabilidade
</div>
<div>
Trabalhamos arduamente para fornecer os melhores Produtos que podemos e para especificar diretrizes claras para todos que os utilizam. No entanto, nossos Produtos são fornecidos "como estão" e, na medida do permitido por lei, não garantimos que eles sejam sempre seguros, protegidos ou livres de erros, ou que funcionarão sem interrupções, atrasos ou imperfeições. Na medida permitida por lei, também RENUNCIAMOS A TODAS AS GARANTIAS, SEJAM EXPRESSAS OU IMPLÍCITAS, INCLUINDO AS GARANTIAS IMPLÍCITAS DE COMERCIALIZAÇÃO, APTIDÃO PARA UM DETERMINADO FIM, TÍTULO E NÃO VIOLAÇÃO. Não controlamos ou dirigimos o que as pessoas e outras fazem ou dizem, e não somos responsáveis por suas ações ou conduta (online ou offline) ou qualquer conteúdo que compartilhem (incluindo conteúdo ofensivo, inapropriado, obsceno, ilegal e outros conteúdos objetáveis).
</div>
<div>
Não podemos prever quando surgirão problemas com nossos Produtos. Portanto, nossa responsabilidade será limitada na medida máxima permitida pela lei aplicável. Na medida máxima permitida pela lei aplicável, em nenhuma circunstância seremos responsáveis perante você por quaisquer lucros cessantes, receitas, informações ou dados perdidos, ou danos consequentes, especiais, indiretos, exemplares, punitivos ou incidentais decorrentes destes Termos ou dos Produtos da Meta (independentemente da causa e em qualquer teoria de responsabilidade, incluindo negligência), mesmo que tenhamos sido avisados da possibilidade de tais danos.
</div>
<div>
4.4. Disputas
</div>
<div>
Tentamos fornecer regras claras para limitar ou, esperançosamente, evitar disputas entre você e nós. No entanto, se surgir uma disputa, é útil saber antecipadamente onde ela pode ser resolvida e quais leis se aplicarão.
Se você é um consumidor, as leis do país em que reside se aplicarão a qualquer reclamação, causa de ação ou disputa que você tenha contra nós que surja destes Termos ou dos Produtos da Meta, e você pode resolver sua reclamação em qualquer tribunal competente nesse país que tenha jurisdição sobre a reclamação. Em todos os outros casos, e para qualquer reclamação, causa de ação ou disputa que a Meta apresente contra você, você e a Meta concordam que qualquer reclamação, causa de ação ou disputa desse tipo deve ser resolvida exclusivamente no Tribunal Distrital dos EUA para o Distrito Norte da Califórnia ou em um tribunal estadual localizado no Condado de San Mateo. Você também concorda que se submete à jurisdição pessoal de qualquer um desses tribunais para fins de litigar qualquer reclamação desse tipo, e que as leis do Estado da Califórnia regerão estes Termos e qualquer reclamação, causa de ação ou disputa sem levar em consideração as disposições de conflitos de leis. Sem prejuízo ao acima exposto, você concorda que, a critério exclusivo da Meta, a Meta pode ingressar com qualquer reclamação, causa de ação ou disputa que tenhamos contra você em qualquer tribunal competente no país em que você reside que tenha jurisdição sobre a reclamação.
</div>
<div>
  4.5. Outros
</div>
<div>
  Estes Termos (anteriormente conhecidos como Declaração de Direitos e Responsabilidades) constituem o acordo integral entre você e a Meta Platforms, Inc. em relação ao seu uso de nossos Produtos. Eles substituem quaisquer acordos anteriores.
</div>
<div>
  Alguns dos Produtos que oferecemos também são regidos por termos complementares. Se você usar algum desses Produtos, os termos complementares estarão disponíveis e farão parte do nosso acordo com você. Por exemplo, se você acessar ou usar nossos Produtos para fins comerciais ou empresariais, como comprar anúncios, vender produtos, desenvolver aplicativos, gerenciar um grupo ou Página para sua empresa, ou usar nossos serviços de medição, você deve concordar com nossos Termos Comerciais. Se você postar ou compartilhar conteúdo contendo música, você deve cumprir nossas Diretrizes de Música. Na medida em que os termos complementares conflitarem com estes Termos, os termos complementares prevalecerão na medida do conflito.
</div>
<div>
  Se qualquer parte destes Termos for considerada inexequível, a parte inexequível será considerada alterada na medida mínima necessária para torná-la exequível, e se não puder ser tornada exequível, então será separada e a parte restante permanecerá em pleno vigor e efeito. Se não aplicarmos qualquer um destes Termos, isso não será considerado uma renúncia. Qualquer emenda ou renúncia a estes Termos deve ser feita por escrito e assinada por nós.
</div>
<div>
  Você não transferirá nenhum de seus direitos ou obrigações sob estes Termos para qualquer outra pessoa sem o nosso consentimento.
</div>
<div>
  Você pode designar uma pessoa (chamada de contato herdeiro) para gerenciar sua conta se ela for memorializada. Se você ativá-la em suas configurações, apenas o seu contato herdeiro ou uma pessoa que você tenha identificado em um testamento válido ou documento legal similar expressando consentimento claro para divulgar seu conteúdo para essa pessoa após a morte ou incapacidade poderá solicitar uma divulgação limitada de informações de sua conta após ela ser memorializada.
</div>
<div>
  Estes Termos não conferem direitos a terceiros beneficiários. Todos os nossos direitos e obrigações sob estes Termos são livremente atribuíveis por nós em conexão com uma fusão, aquisição ou venda de ativos, ou por operação da lei ou de outra forma.
</div>
<div>
  Podemos alterar o nome de usuário de sua conta em determinadas circunstâncias (por exemplo, se outra pessoa reivindicar o nome de usuário e ele parecer não relacionado ao nome que você usa no dia a dia).
</div>
<div>
  Agradecemos sempre seu feedback e outras sugestões sobre nossos produtos e serviços. Mas podemos usar feedback e outras sugestões sem restrição ou obrigação de compensá-lo, e não temos a obrigação de mantê-los confidenciais. 
  Reservamos todos os direitos não expressamente concedidos a você.
</div>
<br/>
<div>
<div>
  5. Outros termos e políticas que podem se aplicar a você
</div>
<div>
  Padrões da Comunidade: Estas diretrizes delineiam nossos padrões em relação ao conteúdo que você posta no Facebook e sua atividade no Facebook e em outros Produtos da Meta.
</div>
<div>
  Termos Comerciais: Estes termos se aplicam se você também acessar ou usar nossos Produtos para qualquer finalidade comercial ou empresarial, incluindo publicidade, operar um aplicativo em nossa Plataforma, usar nossos serviços de medição, gerenciar um grupo ou Página para uma empresa, ou vender bens ou serviços.
</div>
<div>
  Termos de Pagamento da Comunidade: Estes termos se aplicam a pagamentos feitos em ou através dos Produtos da Meta.
</div>
<div>
  Políticas de Comércio: Estas diretrizes delineiam as políticas que se aplicam quando você oferece produtos ou serviços à venda no Facebook, Instagram e WhatsApp.
</div>
<div>
  Diretrizes de Música: Estas diretrizes delineiam as políticas que se aplicam se você postar ou compartilhar conteúdo contendo música em qualquer Produto da Meta.
  Políticas de Publicidade: Estas políticas se aplicam a parceiros que anunciam em todos os Produtos da Meta e especificam quais tipos de conteúdo publicitário são permitidos pelos parceiros que anunciam em todos os Produtos da Meta.
</div>
<div>
  Termos de Anúncio de Autoatendimento: Estes termos se aplicam quando você usa interfaces de publicidade de autoatendimento para criar, enviar ou entregar publicidade ou outra atividade ou conteúdo comercial ou patrocinado.
</div>
<div>
  Política de Páginas, Grupos e Eventos do Facebook: Estas diretrizes se aplicam se você criar ou administrar uma Página, grupo ou evento do Facebook, ou se você usar o Facebook para comunicar ou administrar uma promoção.
</div>
<div>
  Política da Plataforma Meta: Estes termos se aplicam ao uso do conjunto de APIs, SDKs, ferramentas, plugins, código, tecnologia, conteúdo e serviços que permitem que outros desenvolvam funcionalidades, recuperem dados dos Produtos da Meta ou forneçam dados para nós.
</div>
<div>
  Termos de Pagamento para Desenvolvedores: Estes termos se aplicam a desenvolvedores de aplicativos que usam Pagamentos do Facebook.
</div>
<div>
  Recursos de Marca da Meta: Estas diretrizes delineiam as políticas que se aplicam ao uso de marcas registradas, logotipos e capturas de tela da Meta.
</div>
<div>
  Diretrizes de Recomendações: As Diretrizes de Recomendações do Facebook e as Diretrizes de Recomendações do Instagram delineiam nossos padrões para recomendar e não recomendar conteúdo.
</div>
<div>
  Políticas ao Vivo: Estas políticas se aplicam a todo o conteúdo transmitido ao vivo no Facebook Live.
</div>`,
  },
  datadeletion: {
    content: `<div class="dd_wrapper">
    <div>
      BRASA é apenas uma aplicação baseada na web e não armazenamos seus dados pessoais em nosso servidor. De acordo com as regras da Plataforma do Facebook, 
      temos que fornecer um URL de Retorno de Exclusão de Dados do Usuário ou 
      URL de Instruções de Exclusão de Dados. Se você deseja excluir suas atividades para o FinMonster, siga estas instruções:
    </div>
    <br />
    <div>
      1.   Vá para Configurações e Privacidade da sua Conta do Facebook. Clique em "Configurações".
    </div>
    <br />
    <div>
      2.   Em seguida, vá para "Aplicativos e Sites" e você verá todas as suas atividades de aplicativos.
    </div>
    <br />
    <div>
      3.   Selecione a opção BRASA Production
    </div>
    <br />
    <div>
      4.   Clique no botão "Remover".
    </div>`,
  },
  deposit: {
    select_payment: 'Selecione o Método de Pagamento',
    make_deposit: 'Fazer Depósito',
    make_deposit_title: 'Por favor, escolha ou insira o valor do depósito',
    enter_amount: 'Inserir valor',
    min_deposit: 'Depósito mínimo',
    max_deposit: 'Depósito máximo',
    deposit_btn: 'DEPÓSITO',
    all: 'Todos',
    all_bonus: 'Todos os Bônus',
    use_now: 'Usar Agora',
    select_bonus: 'Selecionar Bônus',
    claim_bonus: 'Reivindique seu bônus!',
    label: 'Depósito',
    select_payment_method: 'Por favor, selecione seu método de pagamento',
    valid_amount: 'Por favor, preencha um valor de depósito válido',
    out_range: 'Valor do depósito fora do intervalo de transação',
    bonus_limit: 'Limite de Bônus',
    wager_requirement: 'Requisito de Aposta',
    applied: 'Aplicada',
    voucher_left: 'Voucher Restante',
    unavailable: 'Indisponível',
  },
  transaction: {
    tran_record: 'Registros de Transações',
    deposit: 'Depósito',
    withdrawal: 'Retirada',
    betting: 'Apostas',
    records: 'Registros',
    others: 'Outros',
    bonus: 'Bônus',
    adjustment: 'Ajuste',
    pending: 'Pendente',
    approved: 'Aprovado',
    rejected: 'Rejeitado',
    add: 'Adicionar',
    deduct: 'Deduzir',
    freechip_bonus: 'Bônus de Fichas Grátis',
    reload_bonus: 'Bônus de Recarga',
    member_bonus: 'Bônus de Membro',
    bonus_rebate: 'Reembolso de Bônus',
    bonus_expired: 'Bônus Expirado',
    to: 'para',
    total: 'Total:',
    total_bet: 'Total de Apostas:',
    total_winloss: 'Total de Vitórias/Perdas:',
    bet: 'Aposta',
    winLoss: 'Vitória/Perda',
    win_status: 'VITÓRIA',
    draw_status: 'EMPATE',
    lose_status: 'DERROTA',
    bet_status: 'APOSTA',
    cancel_status: 'CANCELADA',
    void_status: 'ANULADA',
    status_pending: 'Em Processamento',
    status_success: 'Sucesso',
    status_failed: 'Rejeitado',
    bonus_status1: 'Em Andamento',
    bonus_status2: 'Finalizado',
    bonus_status3: 'Expirado',
    used: 'Utilizado',
    copied: 'Copiada',
  },
  profile: {
    alertTitle1: 'Completar Detalhes Pessoais',
    alertContent1: 'Por favor, preencha suas informações incompletas.',
    title1: 'Detalhes Pessoais',
    username: 'Nome de Usuário',
    dob: 'Data de Nascimento',
    email: 'E-mail',
    verify_btn: 'Verificar',
    set_dob_btn: 'DEFINIR DATA DE NASCIMENTO',
    your_dob_is: 'Sua data de nascimento é',
    confirm_btn: 'CONFIRMAR',
    cancel_btn: 'CANCELAR',
    confirmation: 'Confirmação',
    edit_avatar: 'Editar Avatar',
    modify_profile: 'Modificar Perfil',
    nickname: 'Apelido',
    nickname_validate: 'Não use caracteres especiais, caso contrário sua conta será bloqueada.',
    change_ps: 'Alterar Senha',
    old_ps: 'Senha Antiga',
    new_ps: 'Nova Senha',
    confirm_ps: 'Repetir Nova Senha',
    modify_btn: 'MODIFICAR',
    update_success: 'Atualização Realizada com Sucesso.',
    remove_success: 'Remoção Realizada com Sucesso.',
    remove_failed: 'Falha ao Remover.',
    email_verify: 'Verificação de E-mail',
    email_text:
      'Enviamos um e-mail com um link de verificação para o seu endereço de e-mail. Por favor, verifique.',
    confirm_birthday: 'Confirmar Data de Nascimento',
    birth_text: 'Sua data de nascimento foi atualizada com sucesso.',
    set_dob: 'Por favor, defina sua data de nascimento',
    invalid_email:
      'Este endereço de e-mail é inválido. Insira um novo endereço de e-mail e tente novamente',
    empty_email: 'Por favor, preencha um endereço de e-mail válido.',
    invalid_phone:
      'Este número de telefone é inválido. Insira um novo número de telefone e tente novamente',
    empty_phone: 'Por favor, preencha um número de telefone válido.',
    empty_veri_code: 'Por favor, preencha o código de verificação.',
    invalid_veri_code: 'Por favor, verifique se o seu número de telefone está verificado.',
    verify_label: 'Verificar',
    email_addrss: 'Endereço de E-mail',
    member_center: 'Centro de Membros',
    personal_detail: 'Detalhe Pessoal',
    change_pass: 'Alterar Senha',
    funding: 'Financiamento',
    change_pass_title1:
      'Sua senha de login será usada para saques, por favor, garanta uma senha forte.',
    update_data: 'Atualizar Dados',
    verify_title1: 'Por favor, complete a verificação da conta para desbloquear mais recursos.',
    valid_nickname: 'Por favor, insira no máximo 20 caracteres alfanuméricos',
    funding_add_card: 'Financiamento Adicionar conta',
    funding_title1:
      'Fornecemos até cinco contas que podem ser vinculadas. Após a conclusão da vinculação da conta, os saques só poderão ser feitos com esta moeda fiduciária.',
    bank_details: 'Detalhes Bancários',
    bank_notify_user: 'Por favor, forneça detalhes CONTA completos e corretos.',
    usdt_notify_user: 'Por favor, forneça detalhes USDT completos e corretos.',
    ifsc_code: 'Código IFSC',
    bank_num: 'Número do Banco',
    login_pass: 'Senha de Login',
    alert: 'ALERTA',
    add_bank_success: 'Conta Bancária Adicionada com Sucesso',
    ifsc_valid: 'IFSC inválido. Formato IFSC: ABCD0123456',
    delete_bank_success: 'Conta Bancária foi excluída',
    acc_name: 'Nome da Conta',
    acc_no_valid: 'Número de Conta Inválido: Preencha o número com 14 dígitos.',
    acc_email_no_valid: 'Invalid Account No: Fill in number only.',
    acc_phone_no_valid: 'Invalid Account No: Fill in number only.',
    delete_bank:
      'Tem certeza de que deseja remover o número da sua conta? Depois de removido, o número da conta não poderá ser usado novamente, a menos que entre em contato com o suporte.',
    delete_phone:
      'Tem certeza de que deseja remover o seu número de telefone? Depois de removido, o número de telefone não poderá ser usado novamente, a menos que entre em contato com o suporte.',
    delete_email:
      'Tem certeza de que deseja remover o seu endereço de e-mail? Depois de removido, o endereço de e-mail não poderá ser usado novamente, a menos que entre em contato com o suporte.',
    delete_verify_password:
      'Por favor, insira sua senha para garantir que você seja o titular da conta',
    acc_name_valid: 'Nome da Conta Inválido: Preencha apenas caracteres alfabéticos.',
    add: 'Adicionar',
    blackListPhone:
      'Este número de telefone foi restringido. Por favor, entre em contato com o suporte para mais informações.',
    blackListBankAccount:
      'Este número de conta foi restringido. Por favor, entre em contato com o suporte para mais informações.',
  },
  slots: {
    search_placeholder: 'Nome do Jogo ou Provedor',
    all: 'Todos',
    all_provider: 'Provedores',
    provider: 'Provedor',
    clear_all: 'Limpar Tudo',
    recommend_head: 'Recomendado para Você',
    search_head: 'Resultado da Busca',
    nomore_game: 'Sem Mais Jogos',
    more_game: 'Mais Jogos',
    search_provider_placeholder: 'Provedor',
    search_provider: 'Provedor de pesquisa',
    cancel: 'Cancelar',
    no_results: 'Sem resultados',
    about: 'Aprox.',
    results: 'resultados',
  },
  withdrawal: {
    verify: 'Verificar',
    verify_warning: 'Você precisa completar a verificação do email antes de sacar.',
    withdraw: 'Sacar',
    funding: 'Financiamento',
    funding_warning:
      'Você precisa concluir a vinculação de pelo menos uma conta bancária antes de sacar.',
    bind_now: 'Vincular Agora',
    title: 'Retirada',
    choose_method: 'Escolha um método de retirada',
    information: 'Informações de Retirada',
    choose_bank: 'Escolha um cartão bancário para sacar dinheiro',
    ifsc_code: 'Código IFSC',
    card_number: 'Número do Cartão',
    holder_name: 'Nome do Titular',
    account_number: 'Número da Conta',
    account_name: 'Nome da Conta',
    invalid_upi_account: 'Conta UPI inválida. Formato UPI',
    invalid_account_name: 'Nome da Conta Inválido: Não permitido inserir número',
    account_name_required: 'Nome da Conta Inválido: Preencha o nome da conta',
    wager_requirement: 'Requisito de Aposta',
    meet_wager_requirement:
      'Você precisa cumprir os requisitos de aposta antes de fazer uma retirada',
    current_bet_amount: 'Quantidade de Apostas Atual',
    withdraw_amount: 'Quantidade para Retirada',
    valid_amount: 'Por favor, preencha um valor válido para a retirada',
    out_range: 'Quantidade de retirada fora do intervalo de transação',
    min_withdrawal: 'Retirada Mínima',
    max_withdrawal: 'Retirada Máxima',
    text1:
      'Prezado jogador! Para evitar fraudes, você precisa apostar o valor do seu depósito pelo menos uma vez fazendo apostas com a odd equivalente a 1,01 ou superior. Exatamente o valor para o qual você fez apostas estará disponível para você sacar. Depois de fazer apostas no valor do seu depósito, você pode sacar quantas quantias desejar.',
    text2:
      'Tempo de processamento para saque de até 24 horas após clicar em "sacar", por favor, aguarde o pagamento',
    attempText:
      'Você pode solicitar até {attemptsDaily} retiradas todos os dias! {attemptsLeft} retiradas disponíveis!',
    add: 'Adicionar',
    converted_amount: 'Valor de retirada convertido',
    add_wallet_addr: 'Adicionar endereço da carteira',
    wallet_addr: 'Endereço da carteira',
  },
  affiliate: {
    title: 'Afiliado',
    overview: 'Visão Geral',
    retention_program: 'Programa de Retenção',
    referred_users: 'Usuários Referidos',
    commission: 'Comissão',
    overview_text1:
      'Recomende nossa marca e ganhe dinheiro ao se juntar ao programa de afiliados da BRASA! Cada usuário que usar seu link para se registrar na BRASA e jogar jogos lhe trará taxas de comissão.',
    overview_text2:
      'Ser um membro afiliado da BRASA significa que você ganhará uma comissão em todas as suas apostas em nosso cassino - ao contrário dos programas de afiliados tradicionais, neste programa, você ainda ganhará a mesma comissão quer ganhe ou perca.',
    invite_code_link: 'Link do Código de Convite',
    program_text1:
      'Na BRASA, a primeira prioridade sempre foi e sempre será a satisfação do jogador. Este é o núcleo de nossa estratégia de longo prazo para nossos usuários, interagindo com a plataforma como jogadores e como afiliados. Nos esforçamos para alcançar a satisfação do jogador por meio de programas e campanhas únicas que recompensam nossos jogadores. Esses programas fornecem fundos adicionais e incentivos aos nossos jogadores, o que não só proporciona uma melhor experiência de jogo, mas também contribui para aumentar seus ganhos totais de comissão como afiliado.',
    program_text2: 'Esses programas incluem:',
    rakeback: 'Rakeback',
    weekly_bonus: 'Bônus Semanal',
    monthly_bonus: 'Bônus Mensal',
    daily_reload: 'Recarga Diária / Por Hora',
    vip_rewards: 'Recompensas de Nível VIP',
    program_text3:
      'Com essa abordagem única, sua comissão é baseada na margem da casa após esses custos terem sido realizados. Dependendo da extensão do que os jogadores recebem como benefícios, a comissão da margem da casa que você recebe será aproximadamente 45% menor da margem da casa anunciada em qualquer um de nossos jogos.',
    program_text4:
      'Se você deseja uma análise detalhada sobre como esses custos são calculados, fique à vontade para entrar em contato com o suporte para ser colocado em contato com um gerente de afiliados.',
    program_text5:
      'O programa de retenção é utilizado para seus afiliados tanto no Sportsbook quanto no Casino, no entanto, os custos já estão absorvidos antes de calcular a comissão do afiliado nas apostas do Sportsbook para que possamos definir uma taxa padrão de 3% de margem assumida.',
    register: 'Registrar:',
    first_deposit_time: 'Hora do Primeiro Depósito:',
    first_deposit_amount: 'Valor do Primeiro Depósito:',
    deposit: 'Depósito:',
    total_bet_amount: 'Valor Total Apostado:',
    total_winloss: 'Total de Ganhos/Perdas:',
    ggr: 'GGR:',
    ngr: 'NGR:',
    Sports: 'Esportes',
    Casino: 'Cassino',
    Slot: 'Slots',
    Original: 'Original',
  },
  affiliateNew: {
    details: 'Detalhes',
    Register: 'Registrar',
    total: 'Total',
    Deposit: 'Depósito',
    Sports: 'Esportes',
    Casino: 'Cassino',
    Slot: 'Slots',
    Poker: 'Pôquer',
    Original: 'Original',
    commission: 'Comissão',
    GGR: 'GGR',
    NGR: 'NGR',
    'Reg Date': 'Data de Registro',
    link_title: 'Ganhe comissões, comece aqui',
    referral_link: 'Link de referência',
    share: 'Compartilhar',
    pfm_title: 'Desempenho do último mês',
    pfm_title_rebate: 'Volume de negócios de ontem',
    pfm_title_daily: 'Desempenho do mês',
    commission_title: 'Comissão do último período',
    apply_agent: 'Solicitar tornar-se nosso Agente',
    Active: 'Ativo',
    Turnover: 'Volume de Negócios Total',
    'Previous Period Negative GGR': 'GGR Negativo do Período Anterior',
    team_details: 'Detalhes da Equipe',
    level_details: 'Detalhes do Nível',
    level_details_title: 'Detalhes das Apostas do Nível',
    history: 'Histórico',
    list: 'Lista',
    Number: 'Número',
    Status: 'Status',
    Lottery: 'Loteria',
    select_level: 'Selecionar Nível',
    level1: 'UM',
    level2: 'DOIS',
    level3: 'TRÊS',
    level4: 'QUATRO',
    level1full: 'Nível 1',
    level2full: 'Nível 2',
    level3full: 'Nível 3',
    level4full: 'Nível 4',
    no_data: 'Nenhum dado encontrado',
    banner_text:
      'Oferecemos a você a maior taxa de comissão de rebate para garantir o seu desenvolvimento. Junte-se ao Kashbet agora, aproveite o programa de rebate de agente mais alto da indústria e alcance o auge de sua carreira.',
    contact: 'Entre em Contato',
    maria_alice_review:
      'Se você está procurando uma experiência confiável e emocionante em cassino online no Brasil, não precisa procurar mais. Esta plataforma Brasa tem tudo o que você poderia desejar - ótimos bônus, saques rápidos e uma fantástica variedade de jogos. Não posso recomendar o suficiente!',
    miguel_review:
      'A Brasa oferece uma taxa de comissão competitiva e fornece excelente suporte e recursos para seus agentes, tornando-a uma ótima opção para aqueles que procuram maximizar seus ganhos. Sua plataforma também é fácil de usar e tem uma ampla gama de jogos e recursos que podem atrair e reter jogadores.',
    theo_review:
      'No geral, esta plataforma de cassino online Brasa no Brasil oferece uma experiência de jogo satisfatória com uma seleção decente de jogos e suporte ao cliente confiável, tornando-a uma boa opção para jogadores que procuram uma experiência de cassino online intermediária.',
    heitor_review: `Nossa parceria com este cassino online Brasa como afiliados tem sido altamente lucrativa. As taxas de comissão são excelentes e conseguimos obter uma renda substancial através de nossas indicações. Não poderíamos estar mais felizes com os resultados!`,
    cecilia_review: `Eu estava cético no início sobre me tornar um afiliado para esta plataforma de cassino online Brasa, mas estou muito feliz por ter dado o salto. O programa Brasa é fácil de usar, os pagamentos são generosos e consegui obter uma renda substancial ao indicar jogadores para esta plataforma Brasa. Recomendo vivamente que o experimente!`,
    arthur_review: `Sobre o sistema de pagamento de comissões da plataforma de cassino online Brasa, só posso fazer elogios. Receber minha comissão já no dia seguinte é uma vantagem incrível. As recompensas por convidar amigos também são muito atraentes. No geral, é uma plataforma excelente para se atuar como agente.`,
    qna_title: 'Perguntas e Respostas',
    q1_title: `O que é o Programa de Afiliados?`,
    q1_content: `O programa de afiliados é uma parceria entre você e o operador - Brasa. Como parte do programa de marketing de afiliados, o Operador paga uma comissão a você por cada cliente indicado ativo na plataforma.`,
    q2_title: `Existe alguma taxa para se tornar um Agente da Brasa?`,
    q2_content: `Não, não há custo para participar do programa de marketing de afiliados da Brasa.`,
    q3_title: `Como posso ganhar dinheiro com o seu programa?`,
    q3_content: `Quando você recomenda um jogador, o jogador precisa se registrar usando o link de indicação fornecido por você e será associado à sua conta de agente. Sempre que esse jogador fizer uma aposta, você ganhará uma porcentagem dessa aposta efetiva do jogador. A comissão será distribuída prontamente ao meio-dia do dia seguinte.`,
    q4_title: `Como posso acompanhar meu desempenho?`,
    q4_content: `Você pode acompanhar seu desempenho fazendo login na plataforma Brasa. No painel principal de afiliados, você tem todas as informações relevantes para uma análise mais profunda.`,
    commission1:
      'O Agente pode ganhar uma comissão de agência de 55% se os membros da linha descendente gerarem lucros negativos.',
    commission1_text: `<ul>
      <li>Número de membros ativos: ≥5</li>
      <li>Comissão de renda/lucro: 55%</li>
      </ul>
      <div>Termos e Condições Significativos da Comissão:</div>
      <p>
        -Durante os primeiros três meses, se houver 5 membros ativos por mês, a comissão será de 55%. A comissão continuará sendo de 55% nos próximos três meses se a renda/lucro acumulados atingirem 300.000 rúpias. Enquanto a renda/lucro atingir 300.000 rúpias a cada 3 meses, a comissão de 55% será estendida por mais 3 meses.
      </p>
      <p>
        -OBSERVAÇÃO: quando o lucro for negativo ou os membros ativos não atenderem aos requisitos, a comissão de 55% será cancelada e você só poderá desfrutar da comissão básica.
      </p>
      <p>
        -OBSERVAÇÃO: MEMBROS ATIVOS REFEREM-SE a depósitos maiores ou iguais a 5 vezes no mês, o número de depósitos maiores ou iguais a 1.000 e o volume de apostas maior ou igual a 5000.
      </p>`,
    commission2:
      'O Agente pode ganhar comissões de até 500 rúpias por pessoa adicionando novos membros na linha descendente.',
    commission2_text: `Se você tem muitos amigos entusiastas de jogos online, você também pode escolher receber recompensa/comissão através da acumulação de novos membros. São 4 seções diferentes para taxa/rácio de comissão juntamente com membros ativos.`,
    commission3:
      'O Agente pode ganhar até 1% da aposta eficaz gerada pelos membros da linha descendente.',
    commission3_text: `Requisitos de promoção do agente`,
    commission4: 'A promoção eficaz para a Brasa lhe proporcionará salários e bônus',
    commission4_text: `<p>
      Você pode criar um grupo ou comunidade para enviar anúncios designados de nossa plataforma se tiver uma quantidade extensiva de amigos no facebook, whatsapp, yahoo, messenger, instagram etc.
      </p>
      <p>
        Nós lhe forneceremos uma taxa de promoção, este salário está apenas relacionado com o número de pessoas no grupo ou comunidade que você estabeleceu e o número de vezes que você enviar anúncios.
      </p>
      <p>Por favor, envie um e-mail para cs@brasa.game para consulta.</p>`,
    new_members: 'Novos Membros',
    active_members: 'Membros Ativos',
    bet_volume: 'Volume de Apostas',
    contact_brasa: 'Contate a Brasa',
    contact_subtitle:
      'Envie-nos suas perguntas e entraremos em contato com você dentro de uma hora.',
    turnover_tooltip: 'Incluindo até o quarto nível da linha descendente',
    amount: 'Quantidade',
    agent_level: 'Nível do Agente',
    total_bet: 'Aposta total',
    recharge: 'Depósito total',
    commission_instructions_header: 'Programas e Instruções de Agência',
    commission_instructions1:
      'O Programa de Afiliados da Brasa oferece a oportunidade de ganhar receita atraindo novos parceiros.',
    commission_instructions2:
      'Você pode ganhar até 0.9% de comissão das apostas efetivas de seus membros da linha descendente.',
    commission_instructions3:
      'O valor da aposta dele/dela (independentemente de ganhar ou perder) é elegível para uma certa porcentagem de comissão.',
    commission_instructions4:
      'Para avaliar a eficácia dos seus membros da linha descendente, estatísticas detalhadas estão disponíveis em seu painel, filtráveis por data e membros da linha descendente.',
    readLess: 'Leia menos',
    readMore: 'Leia mais',
    link_copied: 'Link copiado',
  },
  bonus: {
    available_for: 'DISPONÍVEL PARA',
    available_forever: 'DISPONÍVEL PARA SEMPRE!!',
    days: 'Dias',
    hours: 'Horas',
    mins: 'Minutos',
    secs: 'Segundos',
    get_bonus: 'Obter Bônus',
    play_now: 'Jogar Agora',
    details: 'Detalhes',
    rebate_promo: 'Promoção de Reembolso',
    game_select: 'Por favor, selecione as opções de jogo para prosseguir com seu bônus.',
    spin_now: 'Girar Agora',
    more_details: 'Mais Detalhes',
    spin_won_title: 'VOCÊ GANHOU',
    spin_btn: 'GIRAR',
    promotions: 'Promoções',
    welcome_bonus: 'Boas-Vindas',
    deposit: 'Depósito',
    vip: 'VIP',
    others: 'Outros',
    permenant: 'Permanente',
    finished_on: 'Termina em ',
    read_more: 'Leia Mais',
    voucher: {
      voucher_enter_code: 'Por favor, insira o código',
      successful_redeem: 'Código de resgate resgatado com sucesso',
      failed_redeem: 'Falha ao resgatar o código de resgate',
      redemption_code: 'Código de Resgate',
    },
    referral_link_label: 'A URL que ajuda você a ganhar dinheiro',
    copied: 'Copiada',
    promotion_expired: 'Esta promoção expirou',
    share: 'Compartilhar',
    no: 'Não',
    acc: 'Conta',
    first_deposit_amt: 'Valor do primeiro depósito',
    deposit_time: 'Tempo de depósito',
  },
  vault: {
    deposit: 'Depósito',
    withdrawal: 'Retirada',
    vault_deposit: 'Depósito do cofre',
    vault_withdrawal: 'Retirada do Cofre',
    curr_balance: 'Saldo Atual',
    vault_balance: 'Saldo do cofre',
    deposit_amt: 'Valor do depósito',
    withdrawal_amt: 'retirar montante',
    bonus_expired: 'Bônus expirado',
    password: 'Senha',
    passwordPrompt: 'Por favor insira a senha de login',
    all: 'Todos',
    vaultAccess: 'Acesso ao cofre',
    passwordPromptAccess: 'Por favor, digite sua senha de login para acessar o cofre',
    passwordPromptAlert: 'Por favor preencha sua senha',
    passwordIncorrect: 'Senha incorreta',
    validAmountAlert: 'Insira um valor válido',
    copied: 'Copiado',
    noResults: 'Sem resultados',
  },
  vip: {
    brasa: 'BRASA',
    vip: 'VIP',
    benefit: 'BENEFÍCIOS',
    info: `Seu nível VIP na Brasa é atualizado em tempo real à medida que você alcança o valor de aposta correspondente. Atualize para o VIP Diamante e desfrute do serviço VIP personalizado da Brasa.`,
    vip_event: 'Eventos Exclusivos para VIPs',
    gift: 'Presentes Personalizados',
    bonus: 'Bônus Exclusivos',
    privilege_detail: 'Detalhes dos Privilégios',
    privilege_content: `Como jogador, se você conseguir alcançar o pináculo do sucesso, será generosamente recompensado com um bônus que varia de R$15.000 a R$500.000. Além disso, você terá acesso a um nível aprimorado de serviço e a uma experiência de jogo incomparável que é verdadeiramente única. Prepare-se para embarcar em uma jornada emocionante com a Brasa e se deliciar com uma diversão sem fim!`,
    rebate_bonus: 'Bônus de Reembolso',
    bonus_info: `O Bônus de Reembolso é calculado com base em (100% - RTP) * valor da aposta * 10%, que pode ser encontrado no RTP de cada jogo.`,
    daily_bonus: 'Bônus Diário',
    join_vip:
      'Junte-se ao nosso clube VIP para receber bônus diários exclusivos para alegrar o seu dia.',
    bounce_bonus: 'Bônus de Recuperação',
    bounce_info: `Ganhe recompensas com base em suas vitórias e apostas no BRASA. Continue jogando para ter a chance de reivindicar generosos bônus de cashback no dia seguinte.`,
    lvlup_bonus: 'Bônus de Nível',
    lvlup_info:
      'Cada promoção bem-sucedida é celebrada com um presente para impulsioná-lo para o próximo nível.',
    vip_level: 'Nível VIP',
    desc1: 'Os jogadores que alcançarem o nível mais alto podem obter um bônus de',
    desc2:
      ', um serviço de qualidade premium e desfrutar de uma experiência superior como jogador.',
    privilege1: 'Bônus de Reembolso',
    privilege2: 'Bônus de Recuperação',
    privilege3: 'Bônus Diário',
    privilege4: 'Bônus Semanal',
    privilege5: 'Bônus Mensal',
    privilege6: 'Bônus de Aniversário',
    privilege7: 'Bônus de Nível',
    privilege8: 'Bônus de Inscrição VIP',
    privilege9: 'Atendimento ao Cliente Exclusivo',
    privilege10: 'Passeio VIP',
    privilege11: 'Experiência Totalmente Personalizada',
    privilege12: 'Suítes de Hotel de Luxo',
    level0: 'Ferro',
    level1: 'Bronze',
    level2: 'Prata',
    level3: 'Ouro',
    level4: 'Platina I',
    level5: 'Platina II',
    level6: 'Platina III',
    level7: 'Platina IV',
    level8: 'Platina V',
    level9: 'Platina VI',
    level10: 'Diamante',
    play_game: 'JOGAR',
    vip_progress: 'Progresso VIP',
    my_benefit: 'Meus Benefícios',
    claim: 'Alegar',
    'Rakeback Bonus': 'Bônus de Reembolso',
    'Bounce Back Bonus': 'Bônus de Recuperação',
    'Daily Bonus': 'Bônus Diário',
    'Weekly Bonus': 'Bônus Semanal',
    'Monthly Bonus': 'Bônus Mensal',
    'Birthday Bonus': 'Bônus de Aniversário',
    'Level Up Bonus': 'Bônus de Nível',
    'VIP Subscription Bonus': 'Bônus de Inscrição VIP',
    congratulations: 'Parabéns',
    bonus_reward: 'Sua recompensa será creditada em sua conta!',
    prize_unlocked: 'Prêmio Desbloqueado',
    play_now: 'Jogar Agora',
    bonus_won: 'Você acaba de ganhar um prêmio. Reivindique agora!',
  },
  maintenance: {
    come_back: `Voltaremos em breve!`,
    under_maintain: `Estamos passando por manutenção entre`,
    and: 'e',
    assistant: 'Se precisar de ajuda, entre em contato com nosso Serviço de Atendimento ao Cliente',
    here: 'aqui',
    apology: 'Pedimos desculpas por qualquer inconveniente causado.',
  },
  installation: {
    android: 'Android',
    iOS: 'iOS',
    download: 'BAIXAR',
    android_title: 'INSTALAÇÃO NO ANDROID',
    android_content1: '1. Clique no botão "BAIXAR" do seu dispositivo Android.',
    android_content2:
      '2. Quando o arquivo for baixado, clique em "Instalar". Se o dispositivo pedir confirmação adicional, confirme.',
    android_content3:
      '3. Se você tiver uma conta em nosso site, faça login; caso contrário, registre-se.',
    ios_title: 'INSTALAÇÃO NO iOS',
    ios_content1: '1. Abra esta página no navegador Safari.',
    ios_content2: '2. Toque no ícone "Compartilhar" no menu na parte inferior da tela.',
    ios_content3:
      '3. Toque em "Adicionar à Tela de Início" e, em seguida, finalize. Posteriormente, o aplicativo será instalado com sucesso no seu telefone.',
  },
  subscribe: {
    unsubscribe_successful_title: 'Desinscrição Bem-Sucedida',
    unsubscribe_successful_content1:
      'Você foi desinscrito com sucesso das comunicações por e-mail.',
    unsubscribe_successful_content2:
      'Se isso foi feito por engano, você pode se reinscrever clicando no botão abaixo.',
    resubscrible_btn: 'Reinscrever',
  },
  error: {
    notFound_title: 'Algo deu Errado',
    notFound_content:
      'Você deu azar, a página que você está procurando foi movida, removida, renomeada ou pode nunca ter existido.',
    pageError_title: 'Ocorreu um Erro',
    pageError_content:
      'Não se preocupe, não é você - somos nós. Desculpe por isso! Estamos trabalhando para encontrar uma solução o mais rápido possível.',
  },
  funding: {
    pix: 'Cartão Bancário',
    usdt: 'USDT',
    link_wallet: 'Vincule carteira eletrônica',
    bind_pix: 'Vincular conta PIX',
    type_pix: 'Tipo de conta PIX',
    from_beneficiary: 'Do beneficiário',
    acc_no: 'Número da conta',
    usdt_addr_placeholder: `Por favor insira o endereço USDT`,
    beneficiary_name_placeholder: `Por favor, insira o nome completo do beneficiário`,
    beneficiary_name_warning:
      'Certifique-se de que o nome corresponda às informações de retirada para evitar falhas.',
    email_acc: 'Conta de Email',
    pix_key: 'Chave do PIX-CPF',
    payment_password_placeholder: 'Por favor, configure sua senha de pagamento.',
    set_payment_pass: 'Definir senha de pagamento',
    confirm_pass: 'Confirme a Senha',
    funding_define: `<div>
    Olá, por favor, certifique-se de preencher corretamente as informações de saque. Selecione uma das opções abaixo para preencher:
    </div>
    <br />
    <div>Exemplo:</div>
    <div>
      1:   CPF: XXXXXXXXXXX (11 dígitos).
    </div>
    <br />
    <div>
      2:   Número do celular: +55 XX XXXXX-XXXX (11 dígitos).
    </div>
    <br />
    <div>
      3:   Email do PIX: XXXXXX@exemplo.com.
    </div>
    <br />
    <div>
      4:   Formato do CNPJ: XXXXXXXXXXXXXX (14 dígitos).
    </div>
    <div>`,
    to_send: 'ENVIAR',
    phone_num: 'Número de telefone',
    usdt_content: `Formato do endereço USDT (TRC). O endereço é composto por 34 caracteres começando com a letra T.`,
    usdt_content2:
      'Por favor, preencha o endereço USDT corretamente. Se o endereço de transferência for inserido incorretamente, a plataforma não se responsabiliza por compensação.',
    select_wallet_group: 'Selecione o grupo de carteira eletrônica',
    usdt_details: 'USDT Details',
    payment_pass: 'Senha de pagamento',
    delete_wallet: 'Tem certeza que deseja EXCLUIR?',
    delete_wallet2: 'Por favor, insira a senha de pagamento.',
    cpf_valid: 'CPF inválido: Digite 11 dígitos',
    invalid_phone_num: 'Telefone inválido: Por favor insira 11 dígitos',
    valid_password: 'Por favor preencha uma senha de pagamento válida',
  },
  cpf: {
    login_alert: 'Por favor faça login na sua conta. Obrigado!',
    unbound: 'Você ainda não vinculou o CPF.',
    qualified_text: `Você se qualificou para receber bônus, vamos começar nossa jornada para ganhar dinheiro!`,
    claimed_text: 'Você já recebeu o bônus.',
    never_show: 'Nunca mostre este pop-up de evento novamente.',
    detail: 'Detalhes',
    success_claim: 'Reivindique bônus de CPF com sucesso!',
    no_deposit: 'Você ainda não fez nenhum depósito.',
    claim: 'Alegar',
  },
};

export default bra;
