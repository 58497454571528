import axios from 'axios';
import { configure } from 'axios-hooks';
import { enqueueSnackbar } from 'notistack';
// import { v4 as uuidv4 } from 'uuid';

//common
import { STORAGE } from '../../common/constants/keys';
import { getAccessToken, getLocalStorage } from '../../common/helpers/localStorage';

//service
import { refreshTokenInterceptor } from '../accountService';
import { ROUTES } from '../../common/constants/routing';
import { API } from 'common/constants/api';

const gameList = API.GAME.SUBGAME;
const gameCategory = API.GAME.GET_GAME_CATEGORY;
var unauthorized = false;
//This is a copy from axiosInterceptors
export const setupInterceptors = (navigate, dispatch, store) => {
  configure({ cache: false });
  axios.defaults.withCredentials = true;
  // request interceptor to add token to request headers
  axios.interceptors.request.use(
    async (config) => {
      const whitelisted = ['https://api.ipify.org/?format=json'];
      const locale = getLocalStorage('locale') ?? 'pt-BR';
      const token = getAccessToken(STORAGE.AccessToken);
      config.headers = {
        ...config.headers,
        WebCode: window.WEB_CODE,
        'Accept-Language': locale,
      };
      if (!whitelisted.includes(config.url) && token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      if (config.url.includes(gameList) || config.url.includes(gameCategory)) {
        let version = getLocalStorage(STORAGE.GameListVersion);
        if (typeof version !== 'undefined') {
          version = '?version=' + getLocalStorage(STORAGE.GameListVersion);
          config.url = config.url + version;
        }
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  const LogoutHandle = () => {
    // deleteAccessToken();
    // deleteRefreshToken();
    // deleteExpiredTime();
    // userLogout(dispatch);

    // enqueueSnackbar('There is no action for too long time, system automatically log out', {
    //   variant: 'error',
    //   autoHideDuration: 2500,
    // });

    navigate(ROUTES.ACCOUNT.LOGOUT);
    return Promise.reject('There is no action for too long time, system automatically log out');
  };
  const maxRefreshCount = 5;
  let isRefreshing = false;
  // response interceptor intercepting 401 responses, refreshing token and retrying the request
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      // const processId = uuidv4();
      const config = error.config;

      if (error.code === 'ERR_CANCELED') {
        return Promise.resolve({ status: 499 });
      }
      if (error?.response?.status === 429) {
        return Promise.reject(
          'You have exceeded the maximum request limit. Please try again later.',
        );
      }
      if (error?.response?.status === 503) {
        navigate(ROUTES.MAINTENANCE.ROOT);
      }

      if (
        error?.response?.status === 401 &&
        config.headers.Authorization &&
        getAccessToken() &&
        !config._retry
      ) {
        unauthorized = true;
        config._retry = true;
        if (isRefreshing) {
          let refreshCount = 1;
          while (isRefreshing && refreshCount <= maxRefreshCount) {
            await new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve();
              }, 700);
            });
            refreshCount++;
          }

          if (refreshCount > maxRefreshCount) {
            return Promise.reject();
          }

          if (getAccessToken()) {
            return axios.request(config);
          }
        }

        isRefreshing = true;
        await refreshTokenInterceptor(dispatch, store)
          .then((resp) => {
            if (!resp) {
              LogoutHandle().catch((error) => {
                enqueueSnackbar(error, {
                  variant: 'error',
                  autoHideDuration: 2500,
                });
              });
            }
          })
          .catch((error) => {
            LogoutHandle().catch((error) => {
              enqueueSnackbar(error, {
                variant: 'error',
                autoHideDuration: 2500,
              });
            });
          });

        isRefreshing = false;
        if (getAccessToken()) {
          return axios.request(config);
        }
      } else if (
        error?.response?.status === 401 &&
        config.headers.Authorization &&
        getAccessToken() &&
        config._retry
      ) {
        LogoutHandle().catch((error) => {
          enqueueSnackbar(error, {
            variant: 'error',
            autoHideDuration: 2500,
          });
        });
      }
      if (!unauthorized) {
        const errorMsg =
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.message ||
          'Server Error';
        return Promise.reject(errorMsg);
      } else {
        unauthorized = false;
        return Promise.reject();
      }
      // const errorMsg =
      //   error?.response?.data?.message ||
      //   error?.response?.data?.error ||
      //   error?.message ||
      //   'Server Error';
      // return Promise.reject(errorMsg);
    },
  );
};
