import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { useStore } from 'store/StateProvider';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { MobileImages } from 'common/constants/image-resource';
import { Context } from 'component/shared/provider/IntlWrapper';
import useGetSidebarGame from 'services/hook/useGetSidebarGame';
import { SIDEBARENUM, SIDEBARGAMEENUM } from './SideberEnum';
import styles from './Sidebar.module.css';
import { handlePromise } from 'common/helpers/helper';
// import useFetchReferCode from 'services/hook/useFetchReferCode';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `0px solid ${theme.palette.divider}`,
  borderRadius: 4,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: '#0D1134',
  borderRadius: 4,
  marginTop: 5,
  alignItems: 'center',
}));

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  sidebarItem: {
    padding: '0px 15px !important',
    borderRadius: 4,
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    height: 38,
    '&:hover': {
      backgroundColor: theme.palette.bg.secondaryColor,
      borderRadius: 57,
    },
  },
  drawerRoot: {
    // zIndex: theme.zIndex.modal,
    '& .MuiAccordionSummary-content': {
      gap: 5,
      margin: 0,
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 15px !important',
      margin: '0px',
      height: 38,
      minHeight: 'unset',
    },

    '& .MuiAccordionSummary-root:focus': {
      height: 38,
      borderRadius: 57,
    },
    '& .MuiAccordionSummary-root:hover': {
      backgroundColor: theme.palette.bg.secondaryColor,
      height: 38,
      borderRadius: 57,
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: 47,
      display: 'flex',
      padding: 0,
      height: 38,
      marginTop: 5,
    },
    '& .MuiAccordionDetails-root:hover': {
      backgroundColor: theme.palette.bg.secondaryColor,
      height: 38,
      marginTop: 5,
      borderRadius: 57,
      display: 'flex',
    },
  },
  drawerTopSnackBar: {
    '&.MuiDrawer-root': {
      zIndex: 19,
      position: 'relative',
      overflow: 'auto',
      maxWidth: '700px',
      margin: 'auto',
    },
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 250,
      margin: 'auto',
      backgroundColor: theme.palette.bg.fourthBgColor,
      '& .MuiBox-root': {
        padding: '10px 8px 20px',
      },
      '&.MuiDrawer-paper': {
        paddingTop: '105px',
      },
    },
  },
  drawerTopSnackBarWithoutSnackBar: {
    '&.MuiDrawer-root': {
      zIndex: 19,
      position: 'relative',
      overflow: 'auto',
      maxWidth: '700px',
      margin: 'auto',
    },
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 250,
      margin: 'auto',
      backgroundColor: theme.palette.bg.fourthBgColor,
      '& .MuiBox-root': {
        padding: '10px 8px 20px',
      },
      '&.MuiDrawer-paper': {
        paddingTop: '60px',
      },
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 16px 2px',
}));

const Sidebar = (props) => {
  const { openDrawer, toggleDrawer, backButtonLayout, snackBar, isSports } = props;
  const isLoggedIn = isUserLoggedIn();
  const intl = useIntl();
  // const theme = useTheme();
  const classes = useStyle({ snackBar });
  const navigate = useNavigate();
  const langContext = useContext(Context);

  const [checkGameLength, redirectToGame] = useGetSidebarGame();
  const currentLocation = useLocation();
  const [store] = useStore();
  const { user } = store;
  // const [, , , appView] = useFetchReferCode();

  // const spinChances = user?.spinChances ? user?.spinChances : 0;

  const gotoPage = (item, subItem) => {
    toggleDrawer(false);

    // Handle affiliate redirection
    // if(subItem?.path === ROUTES.AFFILIATE.ROOT) {
    //   navigate(ROUTES.AFFILIATE[isLoggedIn ? "ROOT" : "PARTNER"]);
    //   return;
    // }

    if (!isLoggedIn && subItem?.loginCheck) {
      navigate(ROUTES.ACCOUNT.LOGIN);
    } else {
      if (subItem?.pathParam) {
        //for category game navigation
        subItem.path = subItem?.path.replace(':id', subItem?.name);
      }

      navigate({
        pathname: item.path ? item.path : subItem?.path,
        search: subItem?.searchParam ? `?${createSearchParams(subItem.searchParam)}` : null,
      });
    }
  };

  // const gotoHome = () => {
  //   toggleDrawer(false);
  //   navigate(ROUTES.HOME);
  // };

  const handleGameCatRedirect = async (item) => {
    if (item.search && item.path === 'live support') {
      toggleDrawer(false);
      window.Intercom('show');
    } else if (item.search) {
      gotoPage({}, item);
    } else {
      const [data] = await handlePromise(checkGameLength(item.name));

      if (data[0] > 0) {
        toggleDrawer(false);
        redirectToGame(data[0], data[1]);
      } else {
        gotoPage({}, item);
      }
    }
  };

  // const openCricket = () => {
  //   toggleDrawer(false);
  //   navigate({
  //     pathname: ROUTES.TSBSPORTS.ROOT,
  //     search: `?${createSearchParams('catType=cricket')}`,
  //   });
  // };

  const goToReferralBonus = () => {
    toggleDrawer(false);
    //hardcode to referral bonus id
    navigate(ROUTES.BONUS.READ.replace(':id', 14));
  };

  const goToCPFBonus = () => {
    toggleDrawer(false);
    navigate(ROUTES.USER.CPF_BONUS.ROOT);
  };
  const goToLuckyWheel = () => {
    toggleDrawer(false);
    navigate(ROUTES.USER.LUCKY_WHEEL.ROOT);
  };

  // const gotoInstallationPage = (selectedTab) => {
  //   const defaultTab = isIOS() ? ANDROID_IOS_TAB[1].value : ANDROID_IOS_TAB[0].value;
  //   const tabToNavigate = selectedTab || defaultTab;
  //   navigate(ROUTES.APP_INSTALATION.ROOT, { state: { selectedTab: tabToNavigate } });
  // };

  return (
    <div className={`${openDrawer ? 'active' : ''}`}>
      <Drawer
        anchor="left"
        open={openDrawer}
        variant="temporary"
        onClose={toggleDrawer(false)}
        //to fix sidebarBottomContainer flash when drawer open
        sx={{
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
        }}
        className={`${classes.drawerRoot} ${
          isSports || !backButtonLayout
            ? classes.drawerTopSnackBarWithoutSnackBar
            : !snackBar
            ? classes.drawerTopSnackBarWithoutSnackBar
            : classes.drawerTopSnackBar
        }`}
        id="sidebar-drawer"
        style={{
          ...(window.innerWidth > 700 && {
            transform: 'translateX(calc(-100% + 700px))',
            position: 'fixed',
            zIndex: 19,
          }),
          top: '-1px',
        }}
      >
        <Box role="presentation" sx={{ overflow: 'auto', scrollbarWidth: 'none' }}>
          <div className={styles.drawer}>
            <div className={styles.spinDiv}>
              <div
                className={styles.spin_container}
                onClick={() =>
                  currentLocation.pathname !== ROUTES.USER.LUCKY_WHEEL.ROOT
                    ? goToLuckyWheel()
                    : toggleDrawer(false)
                }
              >
                <div className={styles.spin_img}>
                  {' '}
                  <img
                    width={55}
                    height={55}
                    src={MobileImages.home.spinSidenavImg}
                    alt="Spin Sidenav"
                  />
                </div>
                <img
                  className={styles.banner_img}
                  width={561}
                  height={121}
                  src={
                    intl.locale == 'pt-BR'
                      ? MobileImages.home.sidebarBannerBrasa
                      : MobileImages.home.sidebarBanner
                  }
                  alt="Lucky Wheel"
                />
              </div>
              <img
                className={styles.banner_img}
                width={561}
                height={121}
                src={
                  intl.locale == 'pt-BR'
                    ? MobileImages.home.referralBonusBannerBrasa
                    : MobileImages.home.referralBonusBanner
                }
                alt="ReferralBonus"
                onClick={goToReferralBonus}
              />
              <img
                className={styles.banner_img}
                width={561}
                height={121}
                src={
                  intl.locale == 'pt-BR'
                    ? MobileImages.home.cpfBonusBannerBrasa
                    : MobileImages.home.cpfBonusBanner
                }
                alt="CPFBonus"
                onClick={goToCPFBonus}
              />
            </div>

            {Object.values(SIDEBARENUM).map((item, i) => {
              return (
                <Accordion
                  className={styles.accordion}
                  key={i}
                  onClick={
                    item.mainRedirect
                      ? () => {
                          navigate(item.path);
                        }
                      : undefined
                  }
                >
                  <AccordionSummary
                    expandIcon={item.subList ? <ExpandMoreIcon sx={{ color: '#BCBFC7' }} /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={styles.iconContainer}>
                      <img
                        className={styles.iconImg}
                        src={item.icon}
                        width={item.width}
                        height={item.height}
                        alt={item.name}
                      />
                    </div>
                    <Typography className={styles.typotitle}>
                      {intl.formatMessage({ id: `sidebar.${item.name}` })}
                    </Typography>
                  </AccordionSummary>
                  {item.subList != null &&
                    item.subList.map((items, i) => {
                      return (
                        <AccordionDetails
                          key={i}
                          onClick={() =>
                            items.gameCatRedirect
                              ? handleGameCatRedirect(items)
                              : gotoPage(item, items)
                          }
                        >
                          <div className={styles.subItem}>
                            <div className={styles.subIconList}>
                              <img
                                className={styles.subIconImg}
                                src={items.icon}
                                alt={items.name}
                              />
                            </div>
                            <div className={styles.typo}>
                              <span> {intl.formatMessage({ id: `sidebar.${items.name}` })}</span>
                            </div>
                          </div>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
            {Object.values(SIDEBARGAMEENUM).map((item, key) => {
              return (
                <div
                  className={classes.sidebarItem}
                  key={key}
                  onClick={() => handleGameCatRedirect(item)}
                >
                  <div className={styles.itemImg}>
                    <img
                      src={item.icon}
                      className={styles.subIcon}
                      width={item.width}
                      height={item.height}
                      alt={item.name}
                    />
                  </div>
                  <div className={styles.itemTxt}>
                    <span className={styles.gameItemName}>
                      {intl.formatMessage({ id: `sidebar.${item.name}` })}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
        <div className={styles.sidebarBottomContainer}>
          <langContext.changeLangComponent />
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;
