const IconTransactions = () => {
  return (
    <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_541)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.26193 3H14.7488C15.8871 3 16.8092 3.93554 16.8092 5.07753V17.5897C16.8092 18.7317 15.8871 19.6568 14.7488 19.6568H5.26193C4.12362 19.6568 3.18848 18.7317 3.18848 17.5897V5.07753C3.18848 3.93554 4.12362 3 5.26193 3Z"
          fill="#414952"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.50847 11.8824H11.1774L11.4978 10.9468C11.74 10.2412 12.0995 9.59839 12.8419 9.59839H18.6663C19.3957 9.59839 19.9974 10.202 19.9974 10.9468V23.2134C19.9974 24.5853 18.8825 25.7169 17.502 25.7169H2.50847C1.12529 25.7169 0 24.5879 0 23.2134V14.3989C0 13.0139 1.1279 11.885 2.50847 11.885V11.8824Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 23.2134C20 24.5853 18.8851 25.7169 17.5046 25.7169H2.50847C1.12529 25.7169 0 24.5879 0 23.2134H20Z"
          fill="#414952"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.08124 5.34603H7.681C7.86977 5.34603 8.01075 5.46787 8.01075 5.61637V7.73917C8.01075 7.88958 7.86977 8 7.681 8H7.08124C6.89247 8 6.75149 7.88767 6.75149 7.73917V5.61637C6.75149 5.46597 6.89247 5.34603 7.08124 5.34603ZM5.32975 6.68253H5.92951C6.11828 6.68253 6.27121 6.80438 6.27121 6.94526V7.73917C6.27121 7.88958 6.11828 8 5.92951 8H5.32975C5.15293 8 5 7.88767 5 7.73917V6.94526C5 6.80438 5.15293 6.68253 5.32975 6.68253ZM14.0585 4H14.6583C14.8471 4 15 4.12185 15 4.27035V7.73917C15 7.88958 14.8471 8 14.6583 8H14.0585C13.8817 8 13.7288 7.88767 13.7288 7.73917V4.27035C13.7288 4.11994 13.8817 4 14.0585 4ZM12.319 5.32699H12.9188C13.0956 5.32699 13.2485 5.43931 13.2485 5.58972V7.73917C13.2485 7.88958 13.0956 8 12.9188 8H12.319C12.1302 8 11.9773 7.88767 11.9773 7.73917V5.58972C11.9773 5.43931 12.1302 5.32699 12.319 5.32699ZM10.5699 4.68158H11.1697C11.3584 4.68158 11.4994 4.80343 11.4994 4.94241V7.73727C11.4994 7.88767 11.3584 7.9981 11.1697 7.9981H10.5699C10.3811 7.9981 10.2401 7.88577 10.2401 7.73727V4.94241C10.2401 4.80152 10.3811 4.68158 10.5699 4.68158ZM8.8184 6.31699H9.43011C9.60693 6.31699 9.75986 6.43884 9.75986 6.57782V7.73727C9.75986 7.88767 9.60693 7.9981 9.43011 7.9981H8.8184C8.64158 7.9981 8.48865 7.88577 8.48865 7.73727V6.57782C8.48865 6.43693 8.64158 6.31699 8.8184 6.31699Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.81387 9.34233H11.3312C11.4719 9.34233 11.5865 9.44425 11.5865 9.58536C11.5865 9.72648 11.4719 9.84146 11.3312 9.84146H4.81387C4.67321 9.84146 4.55859 9.72648 4.55859 9.58536C4.55859 9.44425 4.67321 9.34233 4.81387 9.34233ZM4.81387 8.53223H15.1968C15.3374 8.53223 15.4521 8.64721 15.4521 8.78832C15.4521 8.92944 15.3374 9.04442 15.1968 9.04442H4.81387C4.67321 9.04442 4.55859 8.92944 4.55859 8.78832C4.55859 8.64721 4.67321 8.53223 4.81387 8.53223ZM4.81387 10.1368H10.9847C11.1254 10.1368 11.24 10.2517 11.24 10.3798C11.24 10.5209 11.1254 10.6359 10.9847 10.6359H4.81387C4.67321 10.6359 4.55859 10.5209 4.55859 10.3798C4.55859 10.2517 4.67321 10.1368 4.81387 10.1368ZM4.81387 10.9338H10.6383C10.779 10.9338 10.8936 11.0488 10.8936 11.1899C10.8936 11.3179 10.779 11.4329 10.6383 11.4329H4.81387C4.67321 11.4329 4.55859 11.3179 4.55859 11.1899C4.55859 11.0488 4.67321 10.9338 4.81387 10.9338Z"
          fill="#8C9099"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_541">
          <rect width="20" height="25.7143" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconTransactions;
