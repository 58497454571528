import { ACTIONS } from '../constants/actions';

/**
 * Snack Bar action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setAffiliateSnackBar = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.AFFILIATESNACKBAR,
  });

/**
 * Welcome Snack Bar action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setWelcomeSnackBar = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.WELCOMESNACKBAR,
  });

/**
 * Welcome Snack Bar action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowFirstVisitPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COMMON.FIRSTVISITPOPUP,
  });

/**
 *SideBar Drawer Open action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setSideBarDrawerOpen = (dispatch, payload) =>
dispatch({
  payload,
  type: ACTIONS.COMMON.SIDEBARDRAWEROPEN,
});
