const IconBonus = ({ isActive, color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_30_84)">
        <path
          d="M14.5333 8.83333C14.6417 9.13333 14.55 9.475 14.3083 9.68333L12.5 11.1583L13.25 13.4333C13.35 13.7417 13.25 14.0833 12.9917 14.275C12.7333 14.475 12.3833 14.4833 12.1083 14.3083L10.0083 12.9417L7.94167 14.325C7.81667 14.4083 7.66667 14.45 7.51667 14.45C7.35833 14.45 7.19167 14.4 7.05833 14.3C6.8 14.1083 6.69167 13.7667 6.79167 13.4583L7.50833 11.1583L5.69167 9.68333C5.45 9.475 5.35833 9.14167 5.46667 8.84167C5.575 8.54167 5.86667 8.34167 6.18333 8.34167H8.475L9.28333 6.16667C9.39167 5.86667 9.68333 5.66667 10 5.66667C10.3167 5.66667 10.6083 5.86667 10.7167 6.16667L11.525 8.34167H13.8167C14.1333 8.34167 14.425 8.54167 14.5333 8.84167V8.83333ZM20 10C20 15.5167 15.5167 20 10 20C4.48333 20 0 15.5167 0 10C0 4.48333 4.48333 0 10 0C15.5167 0 20 4.48333 20 10ZM17.5 10C17.5 5.86667 14.1333 2.5 10 2.5C5.86667 2.5 2.5 5.86667 2.5 10C2.5 14.1333 5.86667 17.5 10 17.5C14.1333 17.5 17.5 14.1333 17.5 10Z"
          fill={color || '#8C9099'}
        />
      </g>
      <defs>
        <clipPath id="clip0_30_84">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconBonus;
