import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import styles from './SubmitButton.module.css';

const useStyle = makeStyles((theme) => ({
  root: {
    '&.MuiButton-containedPrimary': {
      color: theme.palette.text.txtSecondaryWhite,
      backgroundColor: theme.palette.btn.submitBtn,
      textTransform: 'none',
    },
    '&.MuiButton-containedPrimary:hover': {
      backgroundColor: theme.palette.btn.submitBtn,
      filter: 'brightness(50%)',
    },
    '&.MuiButton-outlinedPrimary': {
      backgroundColor: 'rgba(44, 74, 121, 1)',
      borderColor: theme.palette.btn.submitBtn,
      textTransform: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: `${theme.palette.bg.darkBgColor}`,
      color: '#575B67',
    },
  },
}));

const SubmitButton = ({
  value,
  externalClassName = '',
  type = 'submit',
  handleClick = null,
  btnDisabled = false,
  children,
  childrenWrapperStyle,
  ...rest
}) => {
  const classes = useStyle();
  return (
    <Button
      disabled={btnDisabled}
      variant="contained"
      type={type}
      color="primary"
      onClick={type === 'submit' ? null : handleClick}
      {...rest}
      className={`${styles.button} ${classes.root} ${externalClassName}`}
    >
      <div style={childrenWrapperStyle}>{children}</div>
      {value}
    </Button>
  );
};

export default memo(SubmitButton);
