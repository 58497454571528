const IconWithdraw = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_671)">
        <path d="M20 10.5645H0V19.6415H20V10.5645Z" fill="#414952" />
        <path
          d="M18.0096 11.5792H11.426C12.7863 12.1584 13.7294 13.5114 13.7294 15.1053C13.7294 16.6993 12.7817 18.0615 11.426 18.6315H18.0096C18.0096 18.0615 18.4766 17.5843 19.0343 17.5843V12.6264C18.4766 12.6264 18.0096 12.1584 18.0096 11.5792Z"
          fill="#8C9099"
        />
        <path
          d="M8.5694 11.5792H1.98586C1.98586 12.1584 1.52791 12.6264 0.970215 12.6264V17.5843C1.52791 17.5843 1.98586 18.0615 1.98586 18.6315H8.5694C7.21823 18.0615 6.26607 16.6993 6.26607 15.1053C6.26607 13.5114 7.21823 12.1584 8.5694 11.5792Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99775 12.0148C11.6708 12.0148 13.0175 13.3956 13.0175 15.1007C13.0175 16.8058 11.6663 18.1866 9.99775 18.1866C8.32919 18.1866 6.97803 16.8058 6.97803 15.1007C6.97803 13.3956 8.32919 12.0148 9.99775 12.0148ZM11.1494 15.995C11.1494 14.7949 9.6849 14.7532 9.6849 14.1647C9.6849 13.9933 9.79825 13.8821 9.99775 13.8821C10.2109 13.8821 10.3242 14.0072 10.3242 14.2574H11.095C11.095 13.6736 10.8139 13.289 10.3151 13.1732V12.7608H9.68036V13.1732C9.19068 13.289 8.91863 13.655 8.91863 14.1416C8.91863 15.2953 10.3695 15.3648 10.3695 15.9996C10.3695 16.1849 10.2471 16.3286 10.0023 16.3286C9.75744 16.3286 9.61235 16.2035 9.61235 15.8977H8.84155C8.84155 16.5371 9.16347 16.9448 9.68036 17.0514V17.4592H10.3151V17.0468C10.8502 16.9309 11.1449 16.5232 11.1449 15.9996L11.1494 15.995Z"
          fill="#8C9099"
        />
        <path d="M20 19.6415H0V21.9953H20V19.6415Z" fill="#414952" />
        <path d="M20 21.3188H0V21.6525H20V21.3188Z" fill="#8C9099" />
        <path d="M20 19.9844H0V20.3273H20V19.9844Z" fill="#8C9099" />
        <path d="M0 20.9899H20V20.6562H0V20.9899Z" fill="#8C9099" />
        <path d="M4.89229 10.5645H3.38696V21.9954H4.89229V10.5645Z" fill="#0F1923" />
        <path d="M4.89229 19.6415H3.38696V21.9953H4.89229V19.6415Z" fill="black" />
        <path d="M16.6131 10.5645H15.1033V21.9954H16.6131V10.5645Z" fill="#0F1923" />
        <path d="M16.6131 19.6415H15.1033V21.9953H16.6131V19.6415Z" fill="black" />
        <path
          d="M4.41162 5.02743H5.29577V10.8564H7.13663V5.02743H8.02078L6.22073 2.24731L4.41162 5.02743Z"
          fill="#8C9099"
        />
        <path
          d="M13.7793 4.84204L11.9792 7.62679H12.8589V10.8564H14.6952V7.62679H15.5839L13.7793 4.84204Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.0818 10.8564H10.9181V2.78475H11.7977L9.99769 0L8.19312 2.78475H9.0818V10.8564Z"
          fill="#8C9099"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_671">
          <rect width="20" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconWithdraw;
