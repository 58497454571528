import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useNavigate } from 'react-router-dom';
import Topbar from './Topbar/Topbar';
import TopbarSecondary from './Topbar/TopbarSecondary';
import Footer from './Footer/Footer';
import styles from './Layout.module.css';

import { useGetRaffleData } from '../../../../services/bonusService';
import { ROUTES } from 'common/constants/routing';
import { getLocalStorage, setLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';
import { setSessionStorage, getSessionStorage } from 'common/helpers/sessionStorage';

import WelcomeBonus from './Topbar/snackbar/WelcomeBonus';
import AffiliateInvite from './Topbar/snackbar/AffiliateInvite';
import { useDispatch } from 'store/StateProvider';
import {
  setAffiliateSnackBar,
  setShowFirstVisitPopUp,
  setWelcomeSnackBar,
  setSideBarDrawerOpen,
} from 'common/reducer-actions/commonActions';
import { useStore } from 'store/StateProvider';
import FirstVisitPopUp from 'component/mobile/home/FirstVisitPopUp';
import Sidebar from './Topbar/sidebar/Sidebar';

const Layout = ({
  isPrimary = false,
  showCloseButton,
  handleCloseGame = null,
  dynamicScreen,
  hasLeftBack = false,
  event = false,
  isSports = false,
  snackBar = true,
  logoLeft = false,
}) => {
  const intl = useIntl();
  const [store] = useStore();
  const navigate = useNavigate();
  const [{ data: spinWheelData }, execute] = useGetRaffleData(true, false);
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();
  const isWelcomeSnackBar = store.common?.isWelcomeSnackBar;
  const isAffiliateSnackBar = store.common?.isAffiliateSnackBar;
  const showFirstVisitPopUp = store.common?.showFirstVisitPopUp;
  const sideBarDrawerOpen = store.common?.sideBarDrawerOpen;

  const toggleDrawer = (open) => (event) => {
    setSideBarDrawerOpen(dispatch, open);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      const welcomeSnack = getLocalStorage('welcomeSnackbar');
      setWelcomeSnackBar(dispatch, welcomeSnack);
    } else {
      const affiliateSnackbar = getLocalStorage('affiliateSnackbar');
      setAffiliateSnackBar(dispatch, affiliateSnackbar);
    }
  }, []);

  useEffect(() => {
    const spin = getLocalStorage('spin');
    if (spin) {
      execute();
    } else {
      setLocalStorage('spin', false);
    }
  }, [execute]);

  // useEffect(() => {
  //   if (spinWheelData) {
  //     if (spinWheelData.spinChances !== 0) {
  //       navigate(ROUTES.USER.LUCKY_WHEEL.ROOT, { state: { isManualTrigger: false } });
  //       setLocalStorage('spin', false);
  //     } else {
  //       setLocalStorage('spin', false);
  //     }
  //   }
  // }, [spinWheelData, navigate]);

  useEffect(() => {
    const hasVisited = getSessionStorage('hasVisited');

    if (!hasVisited && !showFirstVisitPopUp) {
      setShowFirstVisitPopUp(dispatch, true);
      setSessionStorage('hasVisited', true);
    }
  }, [showFirstVisitPopUp]);

  //clsoe the drawer if navigate to other pages
  useEffect(() => {
    setSideBarDrawerOpen(dispatch, false);
  }, [dispatch, navigate]);

  return (
    <div lang={intl.locale} className={styles.container}>
      <div className={styles.contentContainer}>
        {!handleCloseGame && !event && <WelcomeBonus />}
        {!handleCloseGame && !event && <AffiliateInvite />}
        {showFirstVisitPopUp && <FirstVisitPopUp />}

        {isPrimary ? (
          <Topbar
            isWelcomeSnackBar={isWelcomeSnackBar}
            isAffiliateSnackBar={isAffiliateSnackBar}
            showCloseButton={showCloseButton}
            handleCloseGame={handleCloseGame}
            hasLeftBack={hasLeftBack}
            openDrawer={sideBarDrawerOpen}
            toggleDrawer={toggleDrawer}
          />
        ) : (
          <TopbarSecondary
            isWelcomeSnackBar={isWelcomeSnackBar}
            isAffiliateSnackBar={isAffiliateSnackBar}
            isLoggedIn={isLoggedIn}
            showCloseButton={showCloseButton}
            logoLeft={logoLeft}
            openDrawer={sideBarDrawerOpen}
            toggleDrawer={toggleDrawer}
          />
        )}
        <div
          className={`${isSports ? styles.sportsWrapper : styles.wrapper} ${
            dynamicScreen ? styles.wrapper3 : ''
          }`}
          style={{
            paddingTop: `${
              isSports || !snackBar
                ? '60px'
                : (isWelcomeSnackBar && !isLoggedIn) || (isAffiliateSnackBar && isLoggedIn)
                ? '105px'
                : '60px'
            }`,
          }}
        >
          <Sidebar
            openDrawer={sideBarDrawerOpen}
            toggleDrawer={toggleDrawer}
            snackBar={(isWelcomeSnackBar && !isLoggedIn) || (isAffiliateSnackBar && isLoggedIn)}
            backButtonLayout={snackBar}
            isSports={isSports}
          />
          <Outlet />
        </div>
        <Footer openDrawer={sideBarDrawerOpen} toggleDrawer={toggleDrawer} />
      </div>
    </div>
  );
};

export default Layout;
