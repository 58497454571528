const IconHome = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_551)">
        <path
          d="M15 20H5C2.25 20 0 17.75 0 15V5C0 2.25 2.25 0 5 0H15C17.75 0 20 2.25 20 5V15C20 17.75 17.75 20 15 20Z"
          fill={color || '#8C9099'}
        />
        <path
          d="M6.83333 16.0833C4.5 15.9167 2.83333 13.9167 3 11.5833C3.16667 10.25 3.83333 9.08334 4.91667 8.41667C5.33333 5.58334 7.83333 3.58334 10.6667 4.00001C13 4.33334 14.8333 6.08334 15.0833 8.41667C17 9.66667 17.6667 12.1667 16.4167 14.1667C15.75 15.3333 14.5 16 13.25 16.0833H6.83333ZM10.6667 10.3333V7.75001H9.41667V10.3333H7.5L10.0833 13.5L12.6667 10.3333H10.6667Z"
          fill="#1A242D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_551">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconHome;
