import { ACTIONS } from '../constants/actions';

export default function walletReducer(wallet, { type, payload = null }) {
  switch (type) {
    case ACTIONS.BINDING.BINDINGFILTER:
      return {
        ...wallet,
        ...payload,
      };
    case ACTIONS.BINDING.CPFNUMBER:
      return {
        ...wallet,
        ...payload,
      };
    case ACTIONS.BINDING.PAYMENTPASSWORDSET:
      return {
        ...wallet,
        ...payload,
      };

    default:
      return wallet;
  }
}
