import useAxios from 'axios-hooks';

//common
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';
import { objectToUrlParam } from '../common/helpers/helper';

const { HOST, APIBASE, GAME } = API;

export function useLaunchGame() {
  const [{ data, loading, error }, launchGame] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.LAUNCH_GAME}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, launchGame];
}

export function useLaunchDemoGame(){
   const [{ data, loading, error }, launchDemoGame] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.LAUNCH_DEMO_GAME}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, launchDemoGame];
}
export function useGetLimitSettings(gameId) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_LIMIT_SETTINGS}/${gameId}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useUpdateLimitSetting() {
  const [{ data, loading, error }, updateLimitSetting] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.UPDATE_LIMIT_SETTING}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  const uData = data;
  const uLoading = loading;
  const uError = error;
  return [{ uData, uLoading, uError }, updateLimitSetting];
}

export function useGetGameList(isManual = false) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GAME_LIST}`,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, execute];
}

export function useGetSubGameList(subGameType = null, isManual = false) {
  var param = '';
  if (subGameType) {
    param += `/${subGameType}`;
  }

  const [{ data, loading, error }, getgame] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.SUBGAME}` + param,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, getgame];
}

export function useGetRecentGamePlayed() {
  const [{ data, loading, error }, getRecentPlay] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_RECENT_GAME_PLAY}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getRecentPlay];
}

export function useSearchByName(name) {
  const param = { name: name };
  const [{ data, loading, error }, executeSearch] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.SEARCH_BY_NAME}` + objectToUrlParam(param),
    },
    { manual: true },
  );

  return [{ data, loading, error }, executeSearch];
}

export function useGetJackpotAmount() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_JACKPOT}`,
    },
    { manual: true },
  );

  const jError = error;
  const jData = data;
  return [{ jData, loading, jError }, execute];
}
export function useGetGameJackpotAmount(gameId = null) {
  var param = '';
  if (gameId) {
    param += `?games=${gameId}`;
  }
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_GAME_JACKPOT}` + param,
    },
    { manual: true },
  );

  const jError = error;
  const jData = data;
  return [{ jData, loading, jError }, execute];
}
export function useGetFeaturedSubGameList(gameId = null, isManual = false) {
  var param = '';
  if (gameId) {
    param += `/${gameId}`;
  }

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.FEATURED_SUBGAME}` + param,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, execute];
}

export function useGetGameCacheVersion() {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GAME_CACHE_VERSION}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetch];
}

export function useGetFavGameList() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_FAV_GAMELIST}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useGetFavGameIdList() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_FAV_GAMEIDLIST}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useToggleFavGameList() {
  const [{ data, loading, error }, toggle] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.TOGGLE_FAV_GAMELIST}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, toggle];
}

export function useGetGameCategory(isManual = false) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_GAME_CATEGORY}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );

  return [{ data, loading, error }, execute];
}

export function useGetMainPageCategoryGame() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_MAIN_PAGE_CATEGORY_GAME}`,
      method: METHODS.GET,
    },
    { manual: false },
  );

  return [{ data, loading, error }, execute];
}

export function useGetWinList(isManual = false) {
  const [{ data, loading, error }, getWinList] = useAxios(
    {
      url: `${HOST}${APIBASE}${GAME.GET_WIN_LIST}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );
  return [{ data, loading, error }, getWinList];
}
