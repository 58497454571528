import { ACTIONS } from '../constants/actions';

export default function commonReducer(common, { type, payload = true }) {
  switch (type) {
    case ACTIONS.COMMON.AFFILIATESNACKBAR:
      return { ...common, isAffiliateSnackBar: payload };
    case ACTIONS.COMMON.WELCOMESNACKBAR:
      return { ...common, isWelcomeSnackBar: payload };
    case ACTIONS.COMMON.FIRSTVISITPOPUP:
      return { ...common, showFirstVisitPopUp: payload };
      case ACTIONS.COMMON.SIDEBARDRAWEROPEN:
        return { ...common, sideBarDrawerOpen: payload };
    default:
      return common;
  }
}
