const IconLogout = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_553)">
        <circle cx="10" cy="10" r="10" fill="#8C9099" />
        <path
          d="M15.2299 9.21318H6.62795L10.386 5.3542C10.6863 5.0458 10.6863 4.5397 10.386 4.2313C10.0857 3.9229 9.60051 3.9229 9.30018 4.2313L4.22525 9.4425C3.92492 9.75091 3.92492 10.2491 4.22525 10.5575L9.30018 15.7687C9.60051 16.0771 10.0857 16.0771 10.386 15.7687C10.6863 15.4603 10.6863 14.9621 10.386 14.6537L6.62795 10.7947H15.2299C15.6535 10.7947 16 10.4389 16 10.004C16 9.56903 15.6535 9.21318 15.2299 9.21318Z"
          fill="#414952"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_553">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconLogout;
