import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import GoBack from 'component/mobile/common/goback/GoBack';
import styles from './TopbarSecondary.module.css';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import { useTheme } from '@mui/material/styles';
import IconSideMenu from 'component/mobile/common/icon/IconSideMenu';

const TopbarSecondary = ({
  showCloseButton = false,
  isWelcomeSnackBar,
  isAffiliateSnackBar,
  isLoggedIn,
  logoLeft,
  openDrawer,
  toggleDrawer,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const prevPath = state?.prevPath || null;
  const closeHandler = () => {
    if (prevPath === ROUTES.USER.LUCKY_WHEEL.ROOT) {
      navigate(ROUTES.HOME);
    } else {
      navigate(-1);
    }
  };
  return (
    <>
      <div
        className={`${styles.header} ${!logoLeft ? styles.justifyCenter : ''} ${
          (isWelcomeSnackBar && !isLoggedIn && showCloseButton) ||
          (isAffiliateSnackBar && isLoggedIn && showCloseButton)
            ? styles.hasTop
            : styles.noneTop
        } header`}
        id="topbar-header"
      >
        {!logoLeft && <GoBack arrowClass={styles.backArrow} />}
        {logoLeft && (
          <div className={styles.leftHeader}>
            <div onClick={toggleDrawer(!openDrawer)} className={styles.navLink}>
              <IconSideMenu
                isActive={openDrawer}
                color={theme.palette.bg.whiteBgColor}
                width={25}
                height={28}
              />
            </div>
            <Link to={ROUTES.HOME}>
              <img
                src={MobileImages.logoPrimary}
                width={95}
                height={30}
                alt="logo"
                className={`${styles.logo} ${
                  (isWelcomeSnackBar && !isLoggedIn && showCloseButton) ||
                  (isAffiliateSnackBar && isLoggedIn && showCloseButton)
                    ? styles.marginHasTop
                    : ''
                }`}
              />
            </Link>{' '}
          </div>
        )}

        {!logoLeft && (
          <Link to={ROUTES.HOME}>
            <img
              src={MobileImages.logoPrimary}
              width={95}
              height={30}
              alt="logo"
              className={styles.logo}
            />
          </Link>
        )}

        {showCloseButton && (
          <div className={styles.closeContainer} onClick={closeHandler}>
            <IconClose2 />
          </div>
        )}
      </div>
    </>
  );
};

export default TopbarSecondary;
