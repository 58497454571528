const IconReferral = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_687)">
        <path
          d="M16 11.4844V10.5H4V11.4844"
          stroke="#414952"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path d="M10 11.4844V9.51562" stroke="#414952" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M10 12.1406V12.7969" stroke="#414952" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M10 13.4531V14.1094" stroke="#414952" strokeWidth="2" strokeMiterlimit="10" />
        <path d="M10 14.7656V15.4219" stroke="#414952" strokeWidth="2" strokeMiterlimit="10" />
        <path
          d="M13.8 6.3558C13.6633 6.13923 13.4767 5.97845 13.2633 5.87345L11.3333 4.92517H8.66667L6.73667 5.87345C6.52333 5.97845 6.33333 6.14251 6.2 6.3558C6.06333 6.57236 6 6.80861 6 7.04486V7.87501L6.24 7.99314C6.80333 8.27205 7.41 8.49189 8.05333 8.63955C8.69333 8.7872 9.37 8.85939 10 8.85939C10.63 8.85939 11.3067 8.79048 11.9467 8.63955C12.59 8.49189 13.1933 8.27205 13.76 7.99314L14 7.87501V7.04486C14 6.80861 13.9367 6.56908 13.8 6.3558Z"
          fill="#414952"
        />
        <path
          d="M11.1967 4.78734C11.1367 4.72828 11.0867 4.65609 11.0533 4.57406C11.02 4.49203 11.0033 4.40672 11.0033 4.32141V3.60938H9.00334V4.32141C9.00334 4.40672 8.98667 4.49203 8.95334 4.57406C8.92001 4.65609 8.87001 4.72828 8.81001 4.78734L8.67334 4.92188C8.67334 5.64703 9.27001 6.23438 10.0067 6.23438C10.7433 6.23438 11.34 5.64703 11.34 4.92188L11.2033 4.78734H11.1967Z"
          fill="#8C9099"
        />
        <path
          d="M11.1799 0.479062C10.8533 0.1575 10.4266 0 9.99992 0C9.57325 0 9.14658 0.160781 8.81992 0.479062C8.49325 0.800625 8.33325 1.22063 8.33325 1.64062V2.29688C8.33325 3.17625 8.88659 4.26562 9.99992 4.26562C11.1133 4.26562 11.6666 3.17625 11.6666 2.29688V1.64062C11.6666 1.22063 11.5033 0.800625 11.1799 0.479062Z"
          fill="#8C9099"
        />
        <path
          d="M7.8 18.4964C7.66333 18.2799 7.47667 18.1191 7.26333 18.0141L5.33333 17.0658H2.66667L0.736667 18.0141C0.523333 18.1191 0.333333 18.2831 0.2 18.4964C0.0633333 18.713 0 18.9492 0 19.1855V20.0156L0.24 20.1338C0.803333 20.4127 1.41 20.6325 2.05333 20.7802C2.69333 20.9278 3.37 21 4 21C4.63 21 5.30667 20.9311 5.94667 20.7802C6.59 20.6325 7.19333 20.4127 7.76 20.1338L8 20.0156V19.1855C8 18.9492 7.93667 18.7097 7.8 18.4964Z"
          fill="#414952"
        />
        <path
          d="M5.19667 16.928C5.13667 16.8689 5.08667 16.7967 5.05334 16.7147C5.02001 16.6327 5.00334 16.5473 5.00334 16.462V15.75H3.00334V16.462C3.00334 16.5441 2.98667 16.6294 2.95334 16.7147C2.92001 16.7967 2.87001 16.8689 2.81001 16.928L2.67334 17.0625C2.67334 17.7877 3.27001 18.375 4.00667 18.375C4.74334 18.375 5.34001 17.7877 5.34001 17.0625L5.20334 16.928H5.19667Z"
          fill="#8C9099"
        />
        <path
          d="M5.17992 12.6197C4.85325 12.2981 4.42659 12.1406 3.99992 12.1406C3.57325 12.1406 3.14659 12.3014 2.81992 12.6197C2.49325 12.9412 2.33325 13.3613 2.33325 13.7812V14.4375C2.33325 15.3169 2.88659 16.4062 3.99992 16.4062C5.11325 16.4062 5.66658 15.3169 5.66658 14.4375V13.7812C5.66658 13.3613 5.50325 12.9412 5.17992 12.6197Z"
          fill="#8C9099"
        />
        <path
          d="M19.8 18.4964C19.6633 18.2799 19.4767 18.1191 19.2633 18.0141L17.3333 17.0658H14.6667L12.7367 18.0141C12.5233 18.1191 12.3333 18.2831 12.2 18.4964C12.0633 18.713 12 18.9492 12 19.1855V20.0156L12.24 20.1338C12.8033 20.4127 13.41 20.6325 14.0533 20.7802C14.6933 20.9278 15.37 21 16 21C16.63 21 17.3067 20.9311 17.9467 20.7802C18.59 20.6325 19.1933 20.4127 19.76 20.1338L20 20.0156V19.1855C20 18.9492 19.9367 18.7097 19.8 18.4964Z"
          fill="#414952"
        />
        <path
          d="M17.1967 16.928C17.1367 16.8689 17.0867 16.7967 17.0533 16.7147C17.02 16.6327 17.0033 16.5473 17.0033 16.462V15.75H15.0033V16.462C15.0033 16.5441 14.9867 16.6294 14.9533 16.7147C14.92 16.7967 14.87 16.8689 14.81 16.928L14.6733 17.0625C14.6733 17.7877 15.27 18.375 16.0067 18.375C16.7433 18.375 17.34 17.7877 17.34 17.0625L17.2033 16.928H17.1967Z"
          fill="#8C9099"
        />
        <path
          d="M17.1799 12.6197C16.8533 12.2981 16.4266 12.1406 15.9999 12.1406C15.5733 12.1406 15.1466 12.3014 14.8199 12.6197C14.4933 12.9412 14.3333 13.3613 14.3333 13.7812V14.4375C14.3333 15.3169 14.8866 16.4062 15.9999 16.4062C17.1133 16.4062 17.6666 15.3169 17.6666 14.4375V13.7812C17.6666 13.3613 17.5033 12.9412 17.1799 12.6197Z"
          fill="#8C9099"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_687">
          <rect width="20" height="21" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconReferral;
