const IconUSDTBank = ({ isActive, color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 17.1817L0 8.44937L3.27273 1.34167H14.7273L18 8.44937L9 17.1817Z" fill="#279B79" />
      <path
        d="M14.3996 8.48645C14.3996 7.88968 12.5829 7.39122 10.1568 7.26937V5.99414H13.2425V3.50183H4.75675V5.99414H7.84247V7.26937C5.41632 7.39122 3.59961 7.88968 3.59961 8.48645C3.59961 9.08322 5.41632 9.58168 7.84247 9.70352V14.3018H10.1568V9.70352C12.5829 9.58168 14.3996 9.08322 14.3996 8.48645ZM8.99961 9.31722C6.44361 9.31722 4.37104 8.89906 4.37104 8.3826C4.37104 7.94783 5.84704 7.58229 7.84247 7.47845V8.8686C8.21275 8.89352 8.59847 8.90183 8.99961 8.90183C9.40075 8.90183 9.78647 8.89352 10.1568 8.8686V7.47845C12.1522 7.58229 13.6282 7.94645 13.6282 8.3826C13.6282 8.89906 11.5556 9.31722 8.99961 9.31722Z"
        fill="#0D1134"
      />
    </svg>
  );
};

export default IconUSDTBank;
