import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import { setLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';

import styles from './Snackbar.module.css';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import { setAffiliateSnackBar } from 'common/reducer-actions/commonActions';
import { useDispatch, useStore } from 'store/StateProvider';

const AffiliateInvite = ({ handleCloseGame }) => {
  const navigate = useNavigate();
  const isLoggedIn = isUserLoggedIn();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [store] = useStore();
  const isAffiliateSnackBar = store.common?.isAffiliateSnackBar;

  const closeAffiliateSnackbar = (isSnackbar) => {
    setLocalStorage('affiliateSnackbar', isSnackbar);
    setAffiliateSnackBar(dispatch, isSnackbar);
  };

  return isLoggedIn && isAffiliateSnackBar ? (
    <>
      <div className={`${styles.header} ${styles.affiliateSnack} header`} id="snackBar">
        <div className={`${styles.leftHeader} ${styles.affiliateTitle}`}>
          <div className={styles.leftHeaderDiv}>
            <span>{intl.formatMessage({ id: 'topbar.affiliateInviteTile' })}</span>{' '}
            <img
              src={MobileImages.home.joinNowIcon}
              className={styles.welcomeBonusIcon}
              alt="register"
              width={20}
              height={17}
            />
          </div>
        </div>
        <div className={styles.rightHeader}>
          {handleCloseGame ? (
            <div
              className={styles.joinBtn}
              onClick={(e) => {
                handleCloseGame(e);
                navigate(ROUTES.AFFILIATE.ROOT);
              }}
            >
              <div className={styles.joinSpan}>
                {intl.formatMessage({ id: 'topbar.inviteBtn' })}
              </div>
            </div>
          ) : (
            <Link to={ROUTES.AFFILIATE.ROOT}>
              <div className={styles.joinBtn}>
                <div className={styles.joinSpan}>
                  {intl.formatMessage({ id: 'topbar.inviteBtn' })}
                </div>
              </div>
            </Link>
          )}
          <div onClick={() => closeAffiliateSnackbar(false)}>
            <IconClose2 color="#fff" width={10} height={10} />{' '}
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default AffiliateInvite;
