import { useEffect, useState } from 'react';
import { Dialog, Zoom } from '@mui/material';
import { useIntl } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IconClose3 from '../common/icon/IconClose3';
import styles from './FirstVisitPopUp.module.css';
import { makeStyles } from '@mui/styles';
import { useGetPopupBanner } from 'services/bannerService';
import { API } from 'common/constants/api';
import { useNavigate } from 'react-router';
import { setShowFirstVisitPopUp } from 'common/reducer-actions/commonActions';
import { useDispatch } from 'store/StateProvider';
import useImagePreloader from 'services/hook/useImagePreloader';
import SubmitButton from 'component/shared/buttons/SubmitButton';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-root.MuiDialog-paper': {
      overflowX: 'hidden !important',
      borderRadius: '15px !important',
      backgroundColor: 'transparent !important',
      backgroundImage: 'none !important',
    },
  },
}));

const FirstVisitPopUp = () => {
  const [{ data }] = useGetPopupBanner(1);
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [bannerList, setBannerList] = useState([]);
  const [currentBanner, setCurrentBanner] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [currentUrl, setCurrentUrl] = useState(null);
  const { loading, cacheImages } = useImagePreloader();

  useEffect(() => {
    (async () => {
      if (data?.length > 0) {
        await cacheImages(data.map((d) => `${API.CDN}${d.image}`));
        setBannerList(data.map((d) => ({ ...d, show: true })));
        setCurrentBanner(data[0].id);
        setCurrentUrl(data[0].url);
      }
    })();
  }, [data]);

  useEffect(() => {
    if (bannerList?.length > 0 && bannerList.every((b) => !b.show)) {
      setShowFirstVisitPopUp(dispatch, false);
    }
  }, [bannerList]);

  const closeCurrent = () => {
    const banners = [...bannerList];
    const idx = banners.findIndex((b) => b.show);
    banners[idx].show = false;
    setBannerList(banners);
    setCurrentBanner(banners?.[idx + 1]?.id || null);
    setCurrentUrl(banners?.[idx + 1]?.url);
    if (bannerList.every((b) => !b.show)) setDialogOpen(false);
  };

  const goToCpfBonus = () => {
    navigate(currentUrl);
    setShowFirstVisitPopUp(dispatch, false);
  };

  return (
    !loading && (
      <Dialog open={dialogOpen} className={classes.root}>
        <Zoom in={true} style={{ transitionDuration: '600ms' }}>
          <div className={styles.container}>
            <span className={styles.iconContainer} onClick={() => closeCurrent()}>
              <IconClose3 width={24} height={25} />
            </span>
            {bannerList?.map((b, idx) => {
              return (
                <div className={`${styles.imageContainer}`} key={idx}>
                  <LazyLoadImage
                    style={{ display: currentBanner === b.id ? 'block' : 'none' }}
                    src={API.CDN + b.image}
                    alt={`img${idx}`}
                    className={`${styles.image}`}
                  />
                </div>
              );
            })}
            {currentUrl && (
              <SubmitButton
                externalClassName={styles.buttonContainer}
                value={intl.formatMessage({ id: 'bonus.more_details' })}
                type="button"
                handleClick={goToCpfBonus}
              />
            )}
          </div>
        </Zoom>
      </Dialog>
    )
  );
};

export default FirstVisitPopUp;
