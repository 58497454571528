import useAxios from 'axios-hooks';

//common
import { useEffect } from 'react';
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';
import { setUserBalance, setSpinChance } from '../common/reducer-actions/userActions';
import { setIsPasswordSet } from '../common/reducer-actions/walletActions';
import { useDispatch } from '../store/StateProvider';

const { HOST, APIBASE, WALLET } = API;

export function useGetBalance(isManual = false) {
  const dispatch = useDispatch();
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.BALANCE}`,
      method: METHODS.GET,
    },
    { manual: isManual },
  );

  useEffect(() => {
    if (data) {
      let payload = { spinChances: data.spinChances };
      setSpinChance(dispatch, payload);
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, refetch];
}

// export function useGetSpinChance(isManual = false) {
//   const dispatch = useDispatch();
//   const [{ data, loading, error }, refetch] = useAxios(
//     {
//       url: `${HOST}${APIBASE}${WALLET.BALANCE}`,
//       method: METHODS.GET,
//     },
//     { manual: isManual },
//   );

//   useEffect(() => {
//     if (data) {
//       let payload = { balance: data.spinChances };
//       setSpinChance(dispatch, payload);
//     }
//   }, [data, dispatch]);

//   return [{ data, loading, error }, refetch];
// }

export function useGetWithdrawalInfo() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.WITHDRAW_INFO}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      let payload = { balance: data.balance };
      setUserBalance(dispatch, payload);
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, refetch];
}

export function useGetDepositChannels() {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.DEPOSIT_CHANNEL}`,
    method: METHODS.GET,
  });

  return [{ data, loading, error }];
}

export function useGetDepositSetting(params) {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.DEPOSIT_CHANNEL_SETTINGS}/${params}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetch];
}

export function useSubmitDeposit() {
  const [{ data, loading, error }, submit] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.DEPOSIT}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  const dData = data;
  const dLoading = loading;
  const dError = error;

  return [{ dData, dLoading, dError }, submit];
}

export function useBindingSetPassword() {
  const dispatch = useDispatch();

  const [{ data, loading, error }, getBindingSetPassword] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_SETPASSWORD}`,
    method: METHODS.GET,
  });
  useEffect(() => {
    if (data) {
      let payload = { isPasswordSet: data.isPasswordSet };
      setIsPasswordSet(dispatch, payload);
    }
  }, [data, dispatch]);
  return [{ data, loading, error }, getBindingSetPassword];
}

export function useBindingFilter() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, getBindingFilter] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_FILTER}`,
    method: METHODS.GET,
  });

  return [{ data, loading, error }, getBindingFilter];
}

export function useSubmitBinding() {
  const [{ data, loading, error }, submit] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_ADD}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  const tData = data;
  const tLoading = loading;
  const tError = error;

  return [{ tData, tLoading, tError }, submit];
}

export function useBindingGet() {
  const [{ data, loading, error }, getBinding] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_GET}`,
    method: METHODS.GET,
  });
  const gData = data;
  const gLoading = loading;
  const gError = error;

  return [{ gData, gLoading, gError }, getBinding];
}

export function useGetBindingList() {
  const [{ data, loading, error }, getBinding] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_LIST}`,
    method: METHODS.GET,
  });

  return [{ data, loading, error }, getBinding];
}

export function useSubmitWithdraw() {
  const [{ data, loading, error }, submit] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.WITHDRAW}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  const sData = data;
  const sLoading = loading;
  const sError = error;

  return [{ sData, sLoading, sError }, submit];
}

export function useSubmitDelete() {
  const [{ data, loading, error }, submitDelete] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_DEL}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  const dData = data;
  const dLoading = loading;
  const dError = error;

  return [{ dData, dLoading, dError }, submitDelete];
}

export function useGetDepositChannelType() {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${WALLET.DEPOSIT_CHANNEL_TYPE}`,
    method: METHODS.GET,
  });

  return [{ data, loading, error }];
}

export function useGetDeposit() {
  const [{ data, loading, error }, getDeposit] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.GET_DEPOSIT}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getDeposit];
}

export function useSubmitVault() {
  const [{ data, loading, error }, submit] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.VAULT}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, submit];
}

export function useGetVaultLog() {
  const [{ data, loading, error }, getLog] = useAxios(
    {
      url: `${HOST}${APIBASE}${WALLET.VAULT_LOG}`,
      method: METHODS.POST,
    },
    { manual: false },
  );

  return [{ data, loading, error }, getLog];
}
