import { ACTIONS } from '../constants/actions';

/**
 * Set user details action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Wallet} payload Action payload
 */
export const setBindingFilter = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.BINDING.BINDINGFILTER,
  });

/**
 * CPF Number action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setCPFnumber = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.BINDING.CPFNUMBER,
  });

/**
 * isPasswordSet action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setIsPasswordSet = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.BINDING.PAYMENTPASSWORDSET,
  });
