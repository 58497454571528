const IconProfile = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_632)">
        <path
          d="M1.43958 0H18.5604C19.3536 0 20 0.638503 20 1.42739V20.5751C20 21.3615 19.356 22.0025 18.5604 22.0025H1.43958C0.646445 22.0025 0 21.364 0 20.5751V1.42739C0 0.638503 0.643958 0 1.43958 0Z"
          fill="#8C9099"
        />
        <path
          d="M19.095 16.8896C19.095 17.6242 18.5082 18.2208 17.7872 18.2208H2.21532C1.4918 18.2208 0.905029 17.6242 0.905029 16.8896V2.07087C0.905029 1.33622 1.4918 0.739624 2.21532 0.739624H17.7872C18.5107 0.739624 19.095 1.33622 19.095 2.07087V16.8896Z"
          fill="#414952"
        />
        <path
          d="M10.7658 20.0229C10.7658 20.6466 10.2561 21.152 9.62702 21.152C8.99798 21.152 8.48828 20.6466 8.48828 20.0229C8.48828 19.3992 8.99798 18.8938 9.62702 18.8938C10.2561 18.8938 10.7658 19.3992 10.7658 20.0229Z"
          fill="#414952"
        />
        <path
          d="M10.2537 20.0229C10.2537 20.368 9.97273 20.6466 9.62465 20.6466C9.27656 20.6466 8.99561 20.368 8.99561 20.0229C8.99561 19.6777 9.27656 19.3992 9.62465 19.3992C9.97273 19.3992 10.2537 19.6777 10.2537 20.0229Z"
          fill="#8C9099"
        />
        <path d="M18.1527 1.63452H1.84741V4.94537H18.1527V1.63452Z" fill="#8C9099" />
        <path
          d="M4.63707 6.33078C4.63707 6.64387 4.38346 6.89533 4.0677 6.89533H2.41678C2.1035 6.89533 1.84741 6.6414 1.84741 6.33078C1.84741 6.02016 2.1035 5.76624 2.41678 5.76624H4.0677C4.38346 5.76624 4.63707 6.02016 4.63707 6.33078Z"
          fill="#8C9099"
        />
        <path
          d="M7.93639 6.33078C7.93639 6.64387 7.68029 6.89533 7.36702 6.89533H5.7161C5.40282 6.89533 5.14673 6.6414 5.14673 6.33078C5.14673 6.02016 5.40282 5.76624 5.7161 5.76624H7.36702C7.68029 5.76624 7.93639 6.02016 7.93639 6.33078Z"
          fill="#8C9099"
        />
        <path
          d="M11.2357 6.33078C11.2357 6.64387 10.9796 6.89533 10.6663 6.89533H9.01541C8.69965 6.89533 8.44604 6.6414 8.44604 6.33078C8.44604 6.02016 8.69965 5.76624 9.01541 5.76624H10.6663C10.9796 5.76624 11.2357 6.02016 11.2357 6.33078Z"
          fill="#8C9099"
        />
        <path
          d="M14.5375 6.33078C14.5375 6.64387 14.2814 6.89533 13.9681 6.89533H12.3172C12.0039 6.89533 11.7478 6.6414 11.7478 6.33078C11.7478 6.02016 12.0039 5.76624 12.3172 5.76624H13.9681C14.2839 5.76624 14.5375 6.02016 14.5375 6.33078Z"
          fill="#8C9099"
        />
        <path
          d="M17.8368 6.33078C17.8368 6.64387 17.5807 6.89533 17.2674 6.89533H15.6165C15.3007 6.89533 15.0471 6.6414 15.0471 6.33078C15.0471 6.02016 15.3007 5.76624 15.6165 5.76624H17.2674C17.5807 5.76624 17.8368 6.02016 17.8368 6.33078Z"
          fill="#8C9099"
        />
        <path d="M17.8368 7.90857H11.7478V9.48141H17.8368V7.90857Z" fill="#8C9099" />
        <path d="M17.8368 10.1298H11.7478V13.7537H17.8368V10.1298Z" fill="#8C9099" />
        <path d="M10.4128 7.71375H2.20044V8.3769H10.4128V7.71375Z" fill="#8C9099" />
        <path d="M10.4128 8.69495H2.20044V9.3581H10.4128V8.69495Z" fill="#8C9099" />
        <path d="M10.4128 9.67615H2.20044V10.3393H10.4128V9.67615Z" fill="#8C9099" />
        <path d="M10.4128 10.6573H2.20044V11.3205H10.4128V10.6573Z" fill="#8C9099" />
        <path d="M10.4128 11.6385H2.20044V12.3017H10.4128V11.6385Z" fill="#8C9099" />
        <path d="M10.4128 13.5171H2.20044V15.1935H10.4128V13.5171Z" fill="#8C9099" />
        <path d="M18.1527 15.8171H2.20044V16.4014H18.1527V15.8171Z" fill="#8C9099" />
      </g>
      <defs>
        <clipPath id="clip0_252_632">
          <rect width="20" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconProfile;
