import React, { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import { useGetBalance } from 'services/walletService';
import NumFormat from 'component/shared/numformat/NumFormat';
import { MemberBalanceContext } from 'component/shared/provider/MemberBalanceProvider';
import styles from './MemberBalance.module.css';

const MemberBalance = ({ handleCloseGame }) => {
  const [{ data, error: balanceError }] = useGetBalance();
  const { memberBalance, setMemberBalance } = useContext(MemberBalanceContext);
  // const [memberBalance, setMemberBalance] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data) {
      setMemberBalance(data.truncatedBalance);
    }
  }, [data, setMemberBalance]);

  useEffect(() => {
    if (balanceError) {
      enqueueSnackbar(balanceError, {
        variant: 'error',
        autoHideDuration: 2500,
      });
    }
  }, [balanceError, enqueueSnackbar]);

  const handleToDeposit = () => {
    if (location.pathname !== ROUTES.WALLET.DEPOSIT) {
      navigate(ROUTES.WALLET.DEPOSIT);
    }
  };

  return (
    memberBalance !== null && (
      <>
        {handleCloseGame ? (
          <div className={styles.memberWallet}>
            <LazyLoadImage
              src={MobileImages.coin}
              alt=""
              width={20}
              height={21}
              className={styles.coin}
            />
            {/* <NumFormat className={styles.memberBalance} value={memberBalance} /> */}
            <span className={styles.memberBalance}>In Play...</span>
            <LazyLoadImage
              onClick={(e) => {
                handleCloseGame(e);
                navigate(ROUTES.WALLET.DEPOSIT);
              }}
              src={MobileImages.wallet}
              alt=""
              width={28}
              height={28}
              className={styles.wallet}
            />
          </div>
        ) : (
          <div onClick={handleToDeposit}>
            <div className={styles.memberWallet}>
              <LazyLoadImage
                src={MobileImages.coin}
                alt=""
                width={20}
                height={21}
                className={styles.coin}
              />
              <NumFormat className={styles.memberBalance} value={memberBalance} />
              <LazyLoadImage
                src={MobileImages.wallet}
                alt=""
                width={28}
                height={28}
                className={styles.wallet}
              />
            </div>
          </div>
        )}
      </>
    )
  );
};

export default MemberBalance;
