import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import IconBonus from 'component/mobile/common/icon/IconBonus';
import IconSlots from 'component/mobile/common/icon/IconSlots';
// import IconCasino from 'component/mobile/common/icon/IconCasino';
import styles from './Footer.module.css';
import IconMoney from 'component/mobile/common/icon/IconMoney';
import IconDownload from 'component/mobile/common/icon/IconDownload';
import IconSports from 'component/mobile/common/icon/IconSports';

import { isIOS } from 'common/helpers/helper';
import { ANDROID_IOS_TAB } from 'common/constants/tabs';
import useFetchReferCode from 'services/hook/useFetchReferCode';
import IconSideMenu from 'component/mobile/common/icon/IconSideMenu';
import { useTheme } from '@mui/material/styles';

const Footer = (props) => {
  const { openDrawer, toggleDrawer } = props;
  const intl = useIntl();
  const selectedTab = isIOS() ? ANDROID_IOS_TAB[1].value : ANDROID_IOS_TAB[0].value;
  const [, , , appView] = useFetchReferCode();
  const theme = useTheme();
  // const [isApp, setIsApp] = useState(null);

  // useEffect(() => {
  //   console.log('appview', appView);
  //   setIsApp(appView);
  // }, [appView]);

  // const goToDeposit = () => {
  //   navigate(ROUTES.WALLET.DEPOSIT);
  // };

  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footer}>
        <div className={`${styles.navLink}`}>
          <div className={styles.sidebarTab}>
            <div onClick={toggleDrawer(!openDrawer)}>
              <IconSideMenu
                isActive={openDrawer}
                color={theme.palette.bg.iconColor}
                width={20}
                height={20}
              />
            </div>
            <div className={styles.menuTxt}>{intl.formatMessage({ id: 'footer.menu' })}</div>
          </div>
        </div>
        <div className={`${styles.navLink}`}>
          <NavLink to={ROUTES.TSBSPORTS.ROOT}>
            {({ isActive }) => (
              <>
                <div className={`${styles.tab}`}>
                  <IconSports isActive={isActive} {...(isActive && { color: '#FFFFFF' })} />
                  <div>{intl.formatMessage({ id: 'footer.sports' })}</div>
                </div>
              </>
            )}
          </NavLink>
        </div>
        <NavLink className={`${styles.navLink}`} to={ROUTES.WALLET.DEPOSIT}>
          {({ isActive }) => (
            <>
              <div className={styles.tab}>
                <IconMoney isActive={true} {...(isActive && { color: '#FFFFFF' })} />
              </div>
            </>
          )}
        </NavLink>
        <div className={`${styles.navLink}`}>
          {appView !== null && appView && (
            <NavLink to={ROUTES.SLOT.HOME}>
              {({ isActive }) => (
                <>
                  <div className={`${styles.tab} ${styles.slots}`}>
                    <IconSlots isActive={isActive} />
                    <div>{intl.formatMessage({ id: 'footer.slots' })}</div>
                  </div>
                </>
              )}
            </NavLink>
          )}
          {appView !== null && !appView && (
            <NavLink to={ROUTES.BONUS.ROOT}>
              {({ isActive }) => (
                <>
                  <div className={styles.tab}>
                    <IconBonus isActive={isActive} {...(isActive && { color: '#FFFFFF' })} />
                    <div>{intl.formatMessage({ id: 'footer.bonus' })}</div>
                  </div>
                </>
              )}
            </NavLink>
          )}
        </div>
        <div className={styles.navLink}>
          {appView !== null && appView && (
            <NavLink to={ROUTES.BONUS.ROOT}>
              {({ isActive }) => (
                <>
                  <div className={styles.tab}>
                    <IconBonus isActive={isActive} {...(isActive && { color: '#FFFFFF' })} />
                    <div>{intl.formatMessage({ id: 'footer.bonus' })}</div>
                  </div>
                </>
              )}
            </NavLink>
          )}
          {appView !== null && !appView && (
            <NavLink to={ROUTES.APP_INSTALATION.ROOT} state={{ selectedTab }}>
              {({ isActive }) => (
                <>
                  <div className={styles.tab}>
                    <IconDownload isActive={isActive} {...(isActive && { color: '#FFFFFF' })} />
                    <div>{intl.formatMessage({ id: 'footer.app' })}</div>
                  </div>
                </>
              )}
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Footer);
