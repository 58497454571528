import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { LOBBYIDLIST } from 'common/constants/keys';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { useGetSubGameList, useLaunchGame } from 'services/gameService';
import { useStore } from 'store/StateProvider';
import styles from './TSBWidget.module.css';
import { MobileImages } from 'common/constants/image-resource';

const scriptContainerId = 'script-container';
const iframeContainerId = 'iframe-container';

const TSBWidget = ({ isHome = false, sportCat = '' }) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const game = state?.game;
  const [store] = useStore();
  const intl = useIntl();
  const loggedIn = isUserLoggedIn();
  const { enqueueSnackbar } = useSnackbar();
  const [subgameData, setSubgameData] = useState(null);
  const [tsbEnabled, setTsbEnabled] = useState(false);
  const [{ data: subgameDataFetch }] = useGetSubGameList(LOBBYIDLIST.Sports);
  const [{ data: launchData, error: launchError }, launchGame] = useLaunchGame();

  useEffect(() => {
    if (subgameDataFetch) {
      setSubgameData(subgameDataFetch); // fetch subgame for sports
    }
  }, [subgameDataFetch]);

  useEffect(() => {
    if (subgameData && subgameData.subGameList) {
      let skipInsertRecent = false;
      let tsbsportsLaunchData = null;
      if (game) {
        tsbsportsLaunchData = subgameData.subGameList.filter(
          (x) => x.gameId === 27 && x.launchGameCode === game,
        );
      } else if (sportCat) {
        tsbsportsLaunchData = subgameData.subGameList.filter(
          (x) => x.gameId === 27 && x.launchGameCode === sportCat,
        );
      }

      if (tsbsportsLaunchData == null || tsbsportsLaunchData.length === 0) {
        skipInsertRecent = true;
        tsbsportsLaunchData = subgameData.subGameList.filter((x) => x.gameId === 27);
      }
      if (tsbsportsLaunchData && tsbsportsLaunchData.length > 0) {
        setTsbEnabled(true);
        if (!isHome) {
          launchGame({
            data: {
              gameId: tsbsportsLaunchData[0].gameId.toString(),
              subGameId: tsbsportsLaunchData[0].id,
              ipAddress: store?.ipAddress?.ipAddress || '',
              skipRecentGameInsertion: skipInsertRecent,
            },
          });
        }
      } else {
        if (!isHome) {
          enqueueSnackbar(intl.formatMessage({ id: 'footer.gameUnderMaintenance' }), {
            variant: 'error',
            autoHideDuration: 2500,
          });
          navigate(ROUTES.HOME);
        }
      }
    }
  }, [
    subgameData,
    isHome,
    loggedIn,
    store?.ipAddress?.ipAddress,
    navigate,
    launchGame,
    enqueueSnackbar,
    sportCat,
  ]);
  useEffect(() => {
    if (tsbEnabled && isHome) {
      runTSBScript();
    }
  }, [tsbEnabled, isHome]);
  useEffect(() => {
    if (launchData) {
      runTSBScript(launchData.additionalData);
    }
    if (launchError) {
      enqueueSnackbar(launchError, {
        variant: 'error',
        autoHideDuration: 2500,
      });
      navigate(ROUTES.HOME);
    }
  }, [launchData, launchError]);

  const runTSBScript = (additionalData = undefined) => {
    const widgetWrapperEl = document.getElementById(scriptContainerId);
    const script = document.createElement('script');
    script.src = window.TSB_JS;
    script.async = true;
    script.defer = false;
    script.onload = () => {
      let data = window.TSB_DATA;
      if (!isHome) data = { ...data, ...additionalData }; //overwrite api data over window.TSB
      data['mainFrameUrl'] =
        isHome && !loggedIn ? `${window.APP_HOST}/account/login` : `${window.APP_HOST}/tsbsports`;

      data['host'] = data.host + (game ? `/${game}` : `/${sportCat}`);
      data['containerId'] = iframeContainerId;
      if (isHome) {
        //data['height'] = '195px'; //'370px';
        window.BetSdk.initSlider(data);
      } else {
        window.BetSdk.init(data);
      }
    };
    widgetWrapperEl.appendChild(script);
  };

  return (
    <>
      {tsbEnabled && (
        <>
          {isHome && (
            <div className={styles.leftTitle}>
              <div className={styles.imgWrapper}>
                <img
                  src={MobileImages.home.sportsColorIcon}
                  alt="Sports"
                  className={styles['default-img-icon']}
                />
              </div>
              <span> {intl.formatMessage({ id: 'footer.sports' })}</span>
            </div>
          )}
          <div id={scriptContainerId}></div>
          <div
            id={iframeContainerId}
            className={isHome ? styles['container-home'] : styles['container-game']}
          >
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={'99%'}
              height={190}
              className={styles.skeleton}
            />
          </div>
        </>
      )}
    </>
  );
};

export default memo(TSBWidget);
