import React from 'react';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import { myTheme } from 'common/assets/styles/muitheme';

function getMuiTheme(webcode) {
  const webTheme = {
    [webcode]: myTheme,
  };
  return webTheme[webcode];
}

const theme = extendTheme(getMuiTheme(window.WEB_CODE));

const CustomThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme} defaultMode="dark">
        {children}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

export default CustomThemeProvider;
