const IconDeposit = () => {
  return (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_252_556)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.3619 0H17.6381C18.9357 0 20 1.0831 20 2.40365V2.92702C20 4.24758 18.9357 5.33067 17.6381 5.33067H2.3619C1.06429 5.3331 0 4.25 0 2.92945V2.40607C0 1.0831 1.06429 0 2.3619 0Z"
          fill="#8C9099"
        />
        <path d="M17.1571 2.19531H2.84277V5.33314H17.1571V2.19531Z" fill="#46535A" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.45959 2.19531H17.5429C17.7977 2.19531 18.0096 2.40612 18.0096 2.67023C18.0096 2.92949 17.7977 3.14514 17.5429 3.14514H2.45959C2.20482 3.14514 1.99292 2.92949 1.99292 2.67023C1.99292 2.40369 2.20482 2.19531 2.45959 2.19531Z"
          fill="#46535A"
        />
        <path d="M17.1571 2.19531H2.84277V3.14514H17.1571V2.19531Z" fill="#414952" />
        <path d="M16.3666 2.19531H3.6333V17H16.3666V2.19531Z" fill="#414952" />
        <path
          d="M3.6333 14.9235V17H5.67378C5.67378 15.8539 4.75949 14.9235 3.6333 14.9235Z"
          fill="#8C9099"
        />
        <path
          d="M14.3286 17H16.3691V14.9235C15.2429 14.9235 14.3286 15.8539 14.3286 17Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.24515 15.1198H12.7547C12.7547 13.9373 13.7023 12.9681 14.869 12.9681V2.19531H5.13086V12.9657C6.29753 12.9657 7.24515 13.9373 7.24515 15.1173V15.1198Z"
          fill="#8C9099"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6 2.19531H8.40005C7.30005 2.77442 6.55957 3.94959 6.55957 5.29195C6.55957 7.22795 8.09767 8.8005 10 8.8005C11.9024 8.8005 13.4405 7.22795 13.4405 5.29195C13.4405 3.94959 12.6977 2.77684 11.6 2.19531Z"
          fill="#414952"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.01199 6.45259C10.3525 6.45259 10.3929 4.76131 11.0453 4.76131C11.2382 4.76131 11.362 4.89458 11.362 5.11749C11.362 5.36949 11.2239 5.49549 10.9501 5.49549V6.38232C11.5953 6.38232 12.0215 6.05279 12.1453 5.48822H12.6048V4.76131H12.1453C12.0215 4.19432 11.6096 3.88175 11.0739 3.88175C9.79532 3.88175 9.71437 5.55122 9.01913 5.55122C8.81199 5.55122 8.6477 5.41068 8.6477 5.12476C8.6477 4.83885 8.79294 4.6765 9.12866 4.6765V3.78967C8.41437 3.78967 7.9739 4.15313 7.85009 4.76131H7.39771V5.48822H7.85723C7.98818 6.10367 8.43342 6.45259 9.01199 6.45259Z"
          fill="#8C9099"
        />
      </g>
      <defs>
        <clipPath id="clip0_252_556">
          <rect width="20" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconDeposit;
