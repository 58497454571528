import React from 'react';
import { API } from 'common/constants/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//for SEO microdata control
const StructuredData = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          {/* <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: window.APP_HOST,
              potentialAction: [
                {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: window.APP_HOST + '/search?q={search_term_string}',
                  },
                  'query-input': 'required name=search_term_string',
                },
              ],
            })}
          </script> */}
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'Organization',
              name: window.DOMAIN_URL,
              url: window.APP_HOST,
              description:
                'Discover the ultimate online casino experience at BRASA, your go-to destination for thrilling games and real money wins in Brazil. Play a variety of slots and classic casino games, enjoy live dealer action, and seize the opportunity to win big with our enticing bonuses and jackpots. Join us now to indulge in top-tier entertainment and exciting betting options tailor-made for the Brazilian audience.',
              email: window.CS_EMAIL,
              logo: `${API.CDN_REACT}/logo-modal.png`,
              image: `${API.CDN_REACT}/logo-modal.png`,
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'User Feedback',
                url: window.APP_HOST,
                email: window.CS_EMAIL,
                //   telephone: '+',
              },
              sameAs: [
                window.APP_HOST,
                // 'https://www.facebook.com/' + window.FACEBOOK_URL,
                // 'http://m.me/' + window.MESSENGER_URL,
                // 'https://www.instagram.com/' + window.INSTAGRAM_URL,
                // 'https://t.me/' + window.TELEGRAM_URL,
                // window.LIVECHAT_URL,
              ],
            })}
          </script>
          <script type="application/ld+json">
            {JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'ItemList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  url: window.APP_HOST,
                  name: 'BRASA | Online Casino Brazil',
                  image: `${API.CDN_REACT}/logo-modal.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 2,
                  url: window.APP_HOST,
                  name: 'casino game',
                  image: `${API.CDN_REACT}/seo/casino_teenpatti.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 3,
                  url: window.APP_HOST,
                  name: 'slot game',
                  image: `${API.CDN_REACT}/seo/slot_jili.png`,
                },
                {
                  '@type': 'ListItem',
                  position: 4,
                  url: window.APP_HOST,
                  name: 'aviator game',
                  image: API.CDN_REACT + '/seo/slot_aviator.png',
                },
                {
                  '@type': 'ListItem',
                  position: 5,
                  url: window.APP_HOST,
                  name: 'rummy game',
                  image: API.CDN_REACT + '/seo/slot_rummy.png',
                },
              ],
            })}
          </script>
        </Helmet>
      </HelmetProvider>
    </>
  );
};

export default StructuredData;
