export const myTheme = {
  colorSchemes: {
    light: {
      // palette for light mode
      palette: {
        bg: {
          commonBgColor: '#0F1923',
          primaryColor: '#F72C4D',
          secondaryColor: '#1A242D',
          stepBgColor: '#8C9099',
          thirdBgColor: '#252F38',
          selectedBgColor: '#4E7CFC',
          fourthBgColor: '#242F39',
          bgColor5: '#1D2730',
          nonSelectedBgColor: '#2B00A5',
          fadeBgColor: '#3E4252',
          bgColor6: '#36424D',
          bgOpacityColor: 'rgba(0, 0, 0, 0.6)',
          darkBgColor: '#939BB1',
          darkGreyColor: '#0F1923',
          whiteBgColor: '#FFFFFF',
          redBgColor: '#DE2B11',
          iconColor: '#8C9099',
          lightRedBgColor: '#e0687e',
          darkRedBgColor: '#ff203b',
          blackBgColor: '#000',
        },
        border: {
          primaryColor: '#F72C4D',
          secondaryColor: '#575A6A',
          thirdBorderColor: '#323B45',
          fourthBorderColor: '#242F39',
          borderColor5: '#ACB1CD',
          selectedBorderColor: '#4E7CFC',
          borderColor6: '#0F1923',
          borderColor7: '#FFFFFF',
          darkBorderColor: '#939BB1',
          blackBorderColor: '#000',
        },
        btn: {
          submitBtn: '#F72C4D',
        },
        text: {
          primaryTxtColor: '#8C9099',
          secondaryTxtColor: '8A90A2',
          thirdTxtColor: '#5F6F86',
          commonTxtColor: '#BCBFC7',
          linkTxtColor: '#F72C4D',
          txtPrimaryWhite: '#FAFAFA',
          txtSecondaryWhite: '#111111',
          titleTxtColor: '#535D76',
          darkTextColor: '#939BB1',
          txtThirdWhite: '#EEEEEE',
          txtLightGreen: '#0EE385',
          txtGrey: '#888888',
          txtYellow: '#FCBC00',
          txtDarkGrey: '#4A4D5F',
          txtGreen: '#149F00',
          txtRed: '#D81A00',
          linkTxtColor2: '#FED500',
          linkTxtColor3: '#CBCED8',
          txtYellowLight: '#FFE500',
        },
        footerBg: 'linear-gradient(#e6f0ff, #ffffff)',
        footerIcon: 'rgba(0, 0, 0, 0.8)',
        commonBg: "url('../../images/mobile/bg.png')",
      },
    },
    dark: {
      // palette for dark mode
      palette: {
        bg: {
          commonBgColor: '#0F1923',
          primaryColor: '#F72C4D',
          secondaryColor: '#1A242D',
          stepBgColor: '#8C9099',
          thirdBgColor: '#252F38',
          selectedBgColor: '#4E7CFC',
          fourthBgColor: '#242F39',
          bgColor5: '#1D2730',
          nonSelectedBgColor: '#2B00A5',
          fadeBgColor: '#3E4252',
          bgColor6: '#36424D',
          bgOpacityColor: 'rgba(0, 0, 0, 0.6)',
          darkBgColor: '#939BB1',
          darkGreyColor: '#0F1923',
          whiteBgColor: '#FFFFFF',
          redBgColor: '#DE2B11',
          iconColor: '#8C9099',
          lightRedBgColor: '#e0687e',
          darkRedBgColor: '#ff203b',
          blackBgColor: '#000',
        },
        border: {
          primaryColor: '#F72C4D',
          secondaryColor: '#575A6A',
          thirdBorderColor: '#323B45',
          fourthBorderColor: '#242F39',
          borderColor5: '#ACB1CD',
          selectedBorderColor: '#4E7CFC',
          borderColor6: '#0F1923',
          borderColor7: '#FFFFFF',
          darkBorderColor: '#939BB1',
          blackBorderColor: '#000',
          borderColor8:'#4E566A',
        },
        btn: {
          submitBtn: '#F72C4D',
        },
        text: {
          primaryTxtColor: '#8C9099',
          secondaryTxtColor: '#8A90A2',
          thirdTxtColor: '#5F6F86',
          commonTxtColor: '#BCBFC7',
          linkTxtColor: '#F72C4D',
          txtPrimaryWhite: '#FAFAFA',
          txtSecondaryWhite: '#FFFFFF',
          titleTxtColor: '#535D76',
          darkTextColor: '#939BB1',
          txtThirdWhite: '#EEEEEE',
          txtLightGreen: '#0EE385',
          txtGrey: '#888888',
          txtYellow: '#FCBC00',
          txtDarkGrey: '#4A4D5F',
          txtGreen: '#149F00',
          txtRed: '#D81A00',
          linkTxtColor2: '#FED500',
          linkTxtColor3: '#CBCED8',
          txtYellowLight: '#FFE500',
        },
        footerBg: '#0A0C3C',
        footerIcon: '#939BB1',
        commonBg: "url('../../images/mobile/bg-dark.png')",
      },
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: 10,
          '& .MuiTextField-root': {
            marginBottom: 0,
          },
        },
      },
    },
    // MuiFormHelperText: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'Montserrat, sans-serif',
    //     },
    //   },
    // },
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'Montserrat, sans-serif',
    //     },
    //   },
    // },
    // MuiTypography: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'Montserrat, sans-serif',
    //     },
    //   },
    // },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 4,
          color: theme.palette.text.commonTxtColor,
          backgroundColor: theme.palette.bg.commonBgColor,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '& .MuiFilledInput-input': {
            fontSize: 14,
            height: 'auto',
            padding: 12,
            // paddingTop: 12,
            // paddingBottom: 12,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16,
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: '#939BB1',
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.bg.commonBgColor,
          },
          '&:hover': {
            backgroundColor: theme.palette.bg.commonBgColor,
          },
          '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
              marginTop: 0,
              paddingLeft: 8,
              paddingRight: 4,
            },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px',
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.bg.commonBgColor,
          },
        }),
        underline: {
          '&:hover:before': {
            borderBottom: '0px',
          },
          '&::before': {
            borderBottom: '0px',
          },
          '&::after': {
            borderBottom: '0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.commonTxtColor,
          '&.MuiInputLabel-root.Mui-focused': {
            color: '#fafafa',
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiDrawer-paper': {
            backgroundImage: 'none',
          },
        }),
      },
    },
  },
};
